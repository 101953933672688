import axios from 'axios';
import cookieUtils from 'src/utils/cookieUtils';

const client = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_API_URL}/api`,
  headers: {
    'Content-Type': 'application/json',
  },
});

const handleAuth = (config, isFormData) => {
  const token = cookieUtils.get('AUTH_TOKEN');
  const tenantHash = cookieUtils.get('AUTH_TENANT_HASH');

  if (token && token !== '' && token !== null && typeof token !== 'undefined') {
    config.headers = {
      'Content-Type': isFormData
        ? 'application/x-www-form-urlencoded'
        : 'application/json;charset=utf-8',
      Authorization: 'Bearer ' + token,
      Tenant: tenantHash,
    };
    // config.credentials = 'include';
  } else if (tenantHash && typeof tenantHash !== 'undefined') {
    config.headers = {
      'Content-Type': isFormData
        ? 'application/x-www-form-urlencoded'
        : 'application/json;charset=utf-8',
      Tenant: tenantHash,
    };
  }
  return config;
};

export default function api(path, payload, method, isFormData, onUploadProgress, options = {}) {
  client.interceptors.request.use(async (config) => {
    return handleAuth(config, isFormData);
  });

  return new Promise((resolve, reject) => {
    client
      .request({
        method: method,
        url: path,
        responseType: 'json',
        data: payload,
        ...(onUploadProgress && { onUploadProgress }),
        ...options,
      })
      .then((response) => {
        return resolve(response.data);
      })
      .catch((error) => {
        const errData = error?.response?.data || {};
        return reject(errData);
      });
  });
}
