import { all, fork, put, call, select, takeLatest } from 'redux-saga/effects';
import { replace } from 'connected-react-router';
import * as contactTypes from '../actions/contactTypes';
import * as contactApis from '../api/contactApis';
import * as contactActions from '../actions/contactActions';
import * as sequenceActions from '../../sequence/actions/sequenceActions';
import {
  enqueueSequencePersonalize,
  enrolAllContacts,
  fetchEnrolmentErrors,
  setFetchErrorLoading,
} from '../../sequence/actions/enrolmentActions';
import { saveTemplate } from 'src/modules/admin/api/adminApi';
import { createTask, updateTask } from 'src/modules/tasks/api/taskApis';
import { toggleContactDrawer } from 'src/modules/app/actions/appActions';
import { enrollContactsToSequence } from 'src/modules/sequence/api/sequenceApi';
import { parseContactFilterData } from '../utils/contactUtils';
import toast from 'src/utils/toast';
import { isEmpty } from 'lodash';
import moment from 'moment';
import { convertObjectToQuerystring } from 'src/utils/helper';
import { getRelativeDateRange } from 'src/utils/dateUtils';
import { fetchContactList, fetchSequencesLookup } from '../api/contactApis';
import { FETCH_CONTACTS_LISTS } from '../actions/contactTypes';
import { fetchICP } from 'src/modules/auth/api/authApis';

export const getContact = (state) => state.contacts.contact;
export const getContacts = (state) => state.contacts.contacts;
export const getContactNote = (state) => state.contacts.contactNote;
export const getContactNotes = (state) => state.contacts.contactNotes;
export const getSequenceEnrollments = (state) => state.contacts.sequenceEnrollments;
export const getShowContactDrawer = (state) => state.app.showContactDrawer;
export const getActiveEnrolmentId = (state) => state.enrolment.activeEnrolment;
export const getEnrolments = (state) => state.enrolment.enrolments;
export const getGlobalContacts = (state) => state.contacts;
export const getSequencesContacts = (state) => state.sequence.sequencesContacts;

function* fetchContactTabs() {
  try {
    const data = yield call(contactApis.getContactTabs);
    yield put(contactActions.setContactTabs(data));
  } catch (error) {
    yield put(contactActions.setContactTabs([]));
  }
}

function* fetchContacts({ paging, filters, sort }) {
  try {
    let filter = {
      ...filters,
      _from: paging.pageNo * paging.perPage,
      _size: paging.perPage,
      ...(filters?.enrolledBy && filters.enrolledBy?.id
        ? { 'createdBy.id': filters.enrolledBy?.id }
        : {}),
      ...(filters?.status_eq && !isEmpty(filters?.status_eq)
        ? {
            status_eq: filters?.status_eq.value,
          }
        : {}),
    };

    if (filters?.createdAtRange && !isEmpty(filters?.createdAtRange)) {
      const dateRange = getRelativeDateRange(filters?.createdAtRange);
      filter = {
        ...filter,
        createdAt_gte: dateRange.start,
        createdAt_lte: dateRange.end,
      };
    }

    if (filters?.lastActivityDate && !isEmpty(filters?.lastActivityDate)) {
      const lastActivityDate = getRelativeDateRange(filters?.lastActivityDate);
      filter = {
        ...filter,
        updatedAt_gte: lastActivityDate.start,
        updatedAt_lte: lastActivityDate.end,
      };
    }

    if (filters?.enrolledBy?.id) {
      filter = {
        ...filter,
        enrolledby: [filters.enrolledBy.id],
      };
    }

    delete filter?.lastActivityDate;
    delete filter?.createdAtRange;
    delete filter?.enrolledBy;

    if (sort && !isEmpty(sort)) {
      let sortKey = sort.name;
      filter._sort = `${sortKey}:${sort.direction}`;
    }
    let updatedFilter = {};
    Object.keys(filter).forEach((item) => {
      if (!item?.includes('SelectedList')) {
        updatedFilter[item] = filter[item];
      }
    });
    const response = yield call(contactApis.fetchContacts, updatedFilter);
    yield put(contactActions.setContacts({ ...response }));
  } catch (error) {}
}

function* getContactListData({ data, resolve, reject }) {
  try {
    const response = yield call(contactApis.fetchContactList, data);
    resolve(response);
  } catch (error) {
    if (error?.error) {
      toast.error(error?.error?.message, 'tc');
    }
    reject(error);
  }
}

function* putEnrollment({ id, enrollment }) {
  try {
    const res = yield call(contactApis.putEnrollment, id, enrollment);
    const contactsData = yield select(getContacts);
    if (res.error?.message) {
      toast.error(res.error?.message, 'tc');
      yield put(contactActions.setContact({}));
      return;
    }
    if (res.enrolments) {
      yield put(
        contactActions.fetchContacts(contactsData.paging, contactsData.filters, contactsData.sort),
      );
      yield put(contactActions.fetchSequenceEnrollment(res?.enrolments[0]?.contact));
      toast.success('Sequence status is updated!', 'tc');
    } else {
      yield put(contactActions.setContact({}));
    }
  } catch (error) {
    yield put(contactActions.setContact({}));
  }
}

function* deleteContact({ contactId, resolve, reject, notRedirect }) {
  try {
    const response = yield call(contactApis.deleteContact, contactId);
    if (response?.error && response?.error !== '') {
      toast.error(response.error?.message ? response.error.message : response.error);
      resolve(response);
    } else {
      const drawerOpen = yield select(getShowContactDrawer);
      toast.success('Contact Deleted!');
      if (!notRedirect) {
        if (drawerOpen) {
          yield put(toggleContactDrawer());
          const contactsData = yield select(getContacts);
          yield put(
            contactActions.fetchContacts(
              contactsData.paging,
              contactsData.filters,
              contactsData.sort,
            ),
          );
        } else {
          yield put(replace('/contacts'));
        }
      }
    }

    resolve(response);
  } catch (error) {
    reject(error);
  }
}

function* fetchContactOptionsFilters({ tab }) {
  try {
    const contactOptions = yield call(contactApis.fetchContactOptionsFilters);
    const filterData = yield call(parseContactFilterData, contactOptions, tab);
    yield put(contactActions.setContactOptionsFilters(contactOptions, filterData));
  } catch (error) {}
}

function* fetchContactDetails({ contactId }) {
  try {
    const res = yield call(contactApis.fetchContactDetails, contactId);
    if (res.contact) {
      yield put(contactActions.setContact(res.contact));
    } else {
      yield put(contactActions.setContact({}));
    }
  } catch (error) {
    yield put(contactActions.setContact({}));
  }
}
function* fetchContactsTask({ contactId }) {
  try {
    const res = yield call(contactApis.fetchContactsTask, contactId);
    if (res.tasks) {
      yield put(contactActions.setContactTask(res.tasks));
    } else {
      yield put(contactActions.setContactTask({}));
    }
  } catch (error) {
    yield put(contactActions.setContactTask({}));
  }
}
function* fetchContactTasks({ contactId, filters = '', paging = { pageNo: 0, perPage: 10 } }) {
  let filter = {
    _from: paging.pageNo * paging.perPage,
    _size: paging.perPage,
    ...filters,
  };
  try {
    const filtersQuery = convertObjectToQuerystring(filter);
    const res = yield call(contactApis.fetchContactsTask, contactId, filtersQuery);
    yield put(contactActions.setContactTasks(res.tasks ? res : {}));
  } catch (error) {
    yield put(contactActions.setContactTasks({}));
  }
}
function* fetchContactsNote({ contactId, filters = '' }) {
  try {
    const filtersQuery = convertObjectToQuerystring(filters);
    const res = yield call(contactApis.fetchContactsNote, contactId, filtersQuery);
    if (res.notes) {
      yield put(contactActions.setContactNote(res.notes));
    } else {
      yield put(contactActions.setContactNote({}));
    }
  } catch (error) {
    yield put(contactActions.setContactNote({}));
  }
}
function* fetchContactsNotes({ contactId, filters = '', paging = { pageNo: 0, perPage: 10 } }) {
  const contactNoteObj = yield select(getContactNotes);
  let filter = {
    _from: paging.pageNo * paging.perPage,
    _size: paging.perPage,
    ...contactNoteObj.filters,
    ...filters,
  };
  if (filter?.users_eq?.length) filter.users_eq = filter?.users_eq?.map((k) => k.id);

  if (filter?.type_eq?.length) filter.type_eq = filter?.type_eq?.map((k) => k.value);
  if (!filter?.type_eq || !filter?.type_eq?.length) delete filter.type_eq;
  if (!filter?.users_eq || !filter?.users_eq?.length) delete filter.users_eq;
  const teams = filter?.teams || [];
  delete filter.teams;

  if (teams && teams.length) {
    const uids = teams.reduce((acc, team) => {
      return [...acc, ...team.users];
    }, []);
    filter.users_eq = uids;
  }

  try {
    const filtersQuery = convertObjectToQuerystring(filter);
    const res = yield call(contactApis.fetchContactsNote, contactId, filtersQuery);
    yield put(contactActions.setContactNotes(res.notes ? res : {}));
  } catch (error) {
    yield put(contactActions.setContactNotes({}));
  }
}
function* syncPostContactsNote({ note }) {
  try {
    const res = yield call(contactApis.postContactNote, note);
    if (res.note) {
      toast.success('Note has been saved on the prospect profile');
      const notes = yield select(getContactNote);
      yield put(contactActions.setContactNote([res.note, ...notes]));
      yield put(contactActions.fetchContactNotes(note.contact));
    }
  } catch (error) {
    yield put(contactActions.setContactNote({}));
  }
}
function* fetchSequenceEnroll({ contactId }) {
  try {
    const res = yield call(contactApis.fetchSequenceEnroll, contactId);
    if (res.enrolments) {
      yield put(contactActions.setSequenceEnroll(res.enrolments));
    } else {
      yield put(contactActions.setSequenceEnroll({}));
    }
  } catch (error) {
    yield put(contactActions.setSequenceEnroll({}));
  }
}

function* fetchSequenceEnrollments({
  contactId,
  filters = '',
  paging = { pageNo: 0, perPage: 10 },
}) {
  const sequenctObj = yield select(getSequenceEnrollments);
  let filter = {
    _from: paging.pageNo * paging.perPage,
    _size: paging.perPage,
    ...sequenctObj.filters,
    ...filters,
  };

  try {
    const filtersQuery = convertObjectToQuerystring(filter);
    const res = yield call(contactApis.fetchSequenceEnroll, contactId, filtersQuery);
    if (res.enrolments) {
      yield put(contactActions.setSequenceEnrollments(res));
    } else {
      yield put(contactActions.setSequenceEnrollments({}));
    }
  } catch (error) {
    yield put(contactActions.setSequenceEnrollments({}));
  }
}

function* addContacts({ contacts, resolve, reject }) {
  try {
    const res = yield call(contactApis.addContacts, contacts);
    if (res.error?.message) {
      toast.error(res.error?.message, 'tc');
      yield put(contactActions.setContact({}));
      return;
    }
    if (res.contact) {
      yield put(contactActions.setContact(res.contact));
      toast.success('Contact added!', 'tc');
    } else {
      yield put(contactActions.setContact({}));
    }
    if (resolve instanceof Function) resolve(true);
  } catch (error) {
    yield put(contactActions.setContact({}));
    if (reject instanceof Function) reject(error);
  }
}

function* handleExistingPhones(contact, type, phone) {
  if (phone && phone !== '' && type !== '') {
    let existing = [];
    if (contact.phones && contact.phones.length) {
      existing = contact.phones.filter((item) => item.type === type && item.isPrimary === true);
    }
    if (existing && existing.length) {
      existing = existing[0];
      yield call(contactApis.editContactPhone, contact.id, existing.id, {
        phone,
        type,
      });
    } else {
      yield call(contactApis.addContactPhone, contact.id, {
        phone,
        type,
      });
    }
    return true;
  }
  return false;
}

function* putContacts({ contactId, contact, resolve, reject }) {
  const isSequencesPage = window.location.pathname.includes('sequence');
  const contactsData = yield select(getContacts);
  const sequencesContactsData = yield select(getSequencesContacts);

  if (!isSequencesPage) {
    const isDrawerOpen = yield select(getShowContactDrawer);
    const newContact = yield select(getContact);

    const mobile = contact.data.mobile;
    const homePhone = contact.data.homePhone;
    const workPhone = contact.data.workPhone;

    delete contact.data.mobile;
    delete contact.data.homePhone;
    delete contact.data.workPhone;

    if (mobile && mobile !== '') {
      yield call(handleExistingPhones, newContact, 'mobile', mobile);
    }
    if (homePhone && homePhone !== '') {
      yield call(handleExistingPhones, newContact, 'home', homePhone);
    }
    if (workPhone && workPhone !== '') {
      yield call(handleExistingPhones, newContact, 'office', workPhone);
    }

    delete contact.data.phoneContact;
  }

  try {
    const contact_res = yield call(contactApis.updateContacts, contactId, contact);
    if (isSequencesPage) {
      const seqId = window.location.pathname.split('/')[2];
      yield put(
        sequenceActions.fetchSequenceContacts(
          sequencesContactsData?.paging,
          sequencesContactsData?.filters,
          seqId,
          true,
          sequencesContactsData?.sort,
        ),
      );
    } else {
      yield put(contactActions.updateContact(contact_res, contactId));
      // if (isDrawerOpen)
      yield put(contactActions.setContact(contact_res.contact));
      yield put(
        contactActions.fetchContacts(contactsData.paging, contactsData.filters, contactsData.sort),
      );
    }
    toast.success('Contact details updated!', 'tc');
    if (resolve instanceof Function) resolve(contact);
  } catch (error) {
    toast.error(error?.message ? error?.message : 'Error ocurred please try again!');
    if (reject instanceof Function) reject(error);
  }
}

function* updateContactSocialMedia({ contactId, payload, resolve, reject }) {
  try {
    let data = [];
    Object.keys(payload).map((key) => {
      data.push({
        link: payload[key],
        type: key,
      });
    });
    const res = yield call(contactApis.updateContactSocialMedia, contactId, data);
    yield put(contactActions.setContact(res.contact));
    toast.success('Contact Social Media details updated');
    resolve(true);
  } catch (error) {
    toast.error(error?.message ? error?.message : 'Error ocurred please try again!');
    reject(error);
  }
}

function* checkEnrolmentAndFetchErrors() {
  try {
    const enrolments = yield select(getEnrolments);
    const activeEnrolment = yield select(getActiveEnrolmentId);
    const enrolment = enrolments[activeEnrolment];
    if (activeEnrolment && activeEnrolment !== '' && enrolments && Object.keys(enrolments).length) {
      const sequenceId = enrolment?.seq?.id || enrolment?.sequence?.id;
      const contactId = enrolment.contact.id;
      yield put(fetchEnrolmentErrors(contactId, sequenceId, activeEnrolment));
    }
  } catch (error) {}
}

function* postContactDetails({ contactId, formType, data, resolve, reject }) {
  try {
    const contact = yield select(getContact);
    let response;

    if (formType === 'email' || formType === 'personal_email') {
      response = yield call(contactApis.addContactEmail, contactId, data);
      if (data?.isPrimary && data?.isPrimary === true) {
        const emailId = response.email.id;
        yield call(contactApis.setPrimaryEmail, {
          contactId,
          emailId,
          type: data.type,
        });
        contact.emails = contact.emails.map((item) => {
          if (item.type === data.type) {
            return {
              ...item,
              isPrimary: false,
            };
          }
          return item;
        });
      }
      contact?.emails?.push(response.email);
      toast.success('Email address added!', 'tc');
    } else {
      response = yield call(contactApis.addContactPhone, contactId, data);
      if (data?.isPrimary && data?.isPrimary === true) {
        const phoneId = response.phone.id;
        yield call(contactApis.setPrimaryPhone, {
          contactId,
          phoneId,
          type: data.type,
        });
        contact.phones = contact.phones.map((item) => {
          if (item.type === data.type) {
            return {
              ...item,
              isPrimary: false,
            };
          }
          return item;
        });
      }
      contact?.phones?.push(response.phone);
      toast.success('Contact number added!', 'tc');
    }
    yield put(contactActions.setContact(contact));
    yield call(checkEnrolmentAndFetchErrors);
    resolve(contact);
  } catch (error) {
    toast.error(error?.message ? error?.message : 'Error ocurred please try again!');
    reject(error);
  }
}

function* putContactDetails({ contactId, formType, contactDetailId, data, resolve, reject }) {
  try {
    let contact = yield select(getContact);
    contact = JSON.parse(JSON.stringify(contact));
    let response;
    if (formType === 'email') {
      response = yield call(contactApis.editContactEmail, contactId, contactDetailId, data);
      contact.emails = contact.emails.map((email) => {
        if (response.email[0]?.isPrimary && email?.type === response.email[0]?.type)
          email = { ...email, isPrimary: false };
        return email.id === contactDetailId ? response.email[0] : email;
      });
      toast.success('Email address updated!', 'tc');
    } else {
      response = yield call(contactApis.editContactPhone, contactId, contactDetailId, data);
      contact.phones = contact.phones.map((phone) => {
        return phone.id === contactDetailId ? response.phone[0] : phone;
      });
      toast.success('Contact number updated!', 'tc');
    }
    yield put(contactActions.setContact(contact));
    resolve(true);
  } catch (error) {
    toast.error(error?.message ? error?.message : 'Error ocurred please try again!');
    reject(error);
  }
}

function* deleteContactDetails({ contactId, formType, contactDetailId, resolve, reject }) {
  try {
    const contact = yield select(getContact);
    let type = formType === 'email' ? 'emails' : 'phones';
    const res = yield call(contactApis.deleteContactDetailItem, type, contactDetailId);
    if (type == 'emails') {
      contact.emails = contact.emails.filter((email) => email.id !== contactDetailId);
    } else {
      contact.phones = contact.phones.filter((phone) => phone.id !== contactDetailId);
    }
    yield put(contactActions.setContact(contact));
    resolve(true);
  } catch (error) {
    reject(error);
  }
}

function* contactBulkAction({ action, contacts, payload, resolve, reject }) {
  try {
    const contactIds = contacts.map((item) => item.id);
    let response;
    switch (action) {
      case 'delete':
        response = yield call(contactApis.bulkDeleteContacts, contactIds);
        if (response?.contacts && response?.contacts[0]?.error) {
          toast.error("Can't delete contact already enrolled in sequence!");
        } else toast.success('Contact(s) deleted successfully!');
        break;

      case 'removeFromSequence':
        response = yield call(contactApis.bulkDeleteContactsEnrollments, contactIds);
        toast.success('Contact(s) removed from sequence!');
        break;

      case 'removeFromDraft':
        response = yield call(contactApis.bulkDeleteContactsEnrollments, contactIds);
        toast.success('Contact(s) removed from draft!');
        break;

      case 'addToSequence':
        const sequenceId = payload.sequence.id;
        const data = contacts.map((item) => {
          return { id: item.id };
        });
        response = yield call(enrollContactsToSequence, sequenceId, data);

        const enrolments = Object.assign(
          {},
          ...response.enrollments.map((enrollment) => ({ [enrollment.id]: enrollment })),
        );
        yield put(enqueueSequencePersonalize(enrolments, 'enroll'));
        break;

      case 'enrollAll':
        response = yield call(
          enrollContactsToSequence,
          payload.sequence.id,
          contacts.map((item) => {
            return { id: item.id };
          }),
        );

        const enrolmentsData = Object.assign(
          {},
          ...response.enrollments.map((enrollment) => ({ [enrollment.id]: enrollment })),
        );
        const dataToUpdate = Object.keys(enrolmentsData)?.reduce((acc, enrolmentId) => {
          acc.push({ id: enrolmentId, status: 'inProgress' });
          return acc;
        }, []);
        yield put(enrolAllContacts(dataToUpdate));
        break;

      default:
        break;
    }
    const isSequencesPage = window.location.pathname.includes('sequence');

    if (isSequencesPage) {
      const sequencesContactsData = yield select(getSequencesContacts);
      const seqId = window.location.pathname.split('/')[2];
      yield put(
        sequenceActions.fetchSequenceContacts(
          sequencesContactsData?.paging,
          sequencesContactsData?.filters,
          seqId,
          true,
          sequencesContactsData?.sort,
        ),
      );
    } else {
      const contactsData = yield select(getContacts);
      if (!['removeFromSequence', 'addToSequence']?.includes(action))
        yield put(
          contactActions.fetchContacts(
            contactsData.paging,
            contactsData.filters,
            contactsData.sort,
          ),
        );
    }
    resolve(true);
  } catch (error) {
    reject(error);
  }
}

function* addContactToSequence({ sequenceId, contactId, resolve, reject }) {
  try {
    const payload = [{ id: contactId }];
    let response1 = yield call(enrollContactsToSequence, sequenceId, payload);
    const checkAlreadyCreated = response1?.enrollments?.length ? response1.enrollments[0] : null;
    if (checkAlreadyCreated?.alreadyCreated && checkAlreadyCreated?.message) {
      toast.error(checkAlreadyCreated.message);
    } else {
      const enrolments = Object.assign(
        {},
        ...response1.enrollments.map((enrollment) => ({ [enrollment.id]: enrollment })),
      );
      yield put(enqueueSequencePersonalize(enrolments));

      yield put(contactActions.fetchSequenceEnrollment(contactId));
      yield put(contactActions.fetchContactNote(contactId));
      yield put(contactActions.fetchContactTask(contactId));
    }

    resolve(true);
  } catch (error) {
    toast.error('Error occurred while enrolling contact to sequence!');
    reject(error);
  }
}

function* sendContactEmail({ contactId, payload, resolve, reject }) {
  try {
    const replyReminder = {
      isReplyReminderEnabled: payload?.isReplyReminderEnabled,
      replyReminder: payload?.replyReminder,
    };
    delete payload?.isReplyReminderEnabled;
    delete payload?.replyReminder;
    const scheduledOn = payload?.scheduledOn ? payload.scheduledOn : null;
    if (scheduledOn) payload.scheduledOn = scheduledOn;
    let template = yield call(saveTemplate, payload);
    template = template.template;
    const date = moment();
    const data = {
      ...replyReminder,
      contact: contactId,
      selectedEmail: payload.email,
      dueDate: date,
      dueOn: date,
      dueTime: date,
      ...(scheduledOn ? { scheduledOn } : {}),
      taskType: 'email',
      template: template.id,
      allowForceSend: true,
    };
    const response = yield call(createTask, data);
    resolve(true);
  } catch (error) {
    toast.error('Error occurred please try again!');
    reject(error);
  }
}

function* putContactEmail({ contactId, taskId, payload, resolve, reject }) {
  try {
    const replyReminder = {
      isReplyReminderEnabled: payload?.isReplyReminderEnabled,
      replyReminder: payload?.replyReminder,
    };
    delete payload?.isReplyReminderEnabled;
    delete payload?.replyReminder;
    let template = yield call(saveTemplate, payload);
    template = template.template;
    const date = moment();
    const data = {
      ...replyReminder,
      contact: contactId,
      dueDate: date,
      dueTime: date,
      taskType: 'email',
      template: template.id,
      description: payload.description,
      // sender: payload?.sender ? payload.sender : null,
    };
    const response = yield call(updateTask, taskId, data);

    resolve(true);
  } catch (error) {
    toast.error('Error occurred please try again!');
    reject(error);
  }
}

function* fetchBullhornMatches({
  contact,
  matchType,
  enrolmentId = '',
  sequenceId = '',
  resolve = () => {},
  reject = () => {},
}) {
  try {
    yield put(contactActions.setFetchContactCrmLoading(true));
    const payload = {
      id: contact?.id,
      firstName: contact?.fname || '',
      lastName: contact?.lname || '',
      companyName: contact?.employer || '',
      occupation: contact?.jobTitle || '',
      ...(matchType ? { matchType } : {}),
    };
    const matches = yield call(contactApis.getBullhornMatches, payload);
    let data = [];
    if (matches?.error?.message) {
      toast.error(matches?.error?.message);
      reject(matches?.error);
      return yield put(contactActions.setBullhornMatches(data, {}));
    }
    if (
      matches?.contacts?.match &&
      !isEmpty(matches?.contacts?.match) &&
      matches?.contacts?.match?.id &&
      matches?.contacts?.match?.id != ''
    ) {
      const payloadConnect = {
        bullhornId: matches?.contacts?.match?.id,
        bullhornType: matches?.contacts?.match?.type,
        id: contact?.id,
      };
      const response = yield call(contactApis.bullhornMatch, payloadConnect);
      if (response?.updatedContact?.id)
        if (Object.keys(response?.updatedContact || {})?.length) {
          yield put(contactActions.setContact(response?.updatedContact));
          toast.success('Contact synced with bullhorn direct match!');
        }

      yield put(contactActions.setBullhornMatches([], matches?.contacts?.match));
    } else {
      Object.keys(matches.contacts).map((matchType) => {
        for (let i = 0; i < matches.contacts[matchType].length; i++) {
          const contact = matches.contacts[matchType][i];
          data.push({
            ...contact,
            matchType,
          });
        }
      });
      yield put(contactActions.setBullhornMatches(data, {}));
    }
    if (enrolmentId && sequenceId)
      yield put(fetchEnrolmentErrors(contact?.id, sequenceId, enrolmentId));
    yield put(contactActions.setFetchContactCrmLoading(false));
    resolve(true);
  } catch (error) {
    toast.error(error?.error?.message);
    reject(error?.error);
    yield put(fetchEnrolmentErrors(contact?.id, sequenceId, enrolmentId));
    yield put(contactActions.setFetchContactCrmLoading(false));
    return yield put(contactActions.setBullhornMatches([], {}));
  }
}

function* syncBullhornContact({ contactId, match, resolve, reject }) {
  try {
    const payload = {
      bullhornId: match.id,
      bullhornType: match.matchType,
      id: contactId,
    };
    yield put(contactActions.setContactCrmLoading(true));
    const response = yield call(contactApis.bullhornMatch, payload);
    yield put(contactActions.setContactCrmLoading(false));
    toast.success('Contact synced with bullhorn!');
    if (Object.keys(response.updatedContact || {}).length)
      yield put(contactActions.setContact(response.updatedContact));
    resolve(response);
  } catch (error) {
    yield put(contactActions.setContactCrmLoading(false));
    reject(error);
  }
}

function* syncBullhornNewContact({ contactId, bullhornType, resolve, reject }) {
  try {
    yield put(contactActions.setContactCrmLoading(true));
    const response = yield call(contactApis.addContactToBullhorn, contactId, bullhornType);
    yield put(contactActions.setContactCrmLoading(false));
    toast.success('Contact added to bullhorn!');
    yield put(contactActions.setContact(response.contact));
    resolve(response);
  } catch (error) {
    if (error?.error) {
      toast.error(error?.error?.message, 'tc');
    }
    yield put(contactActions.setContactCrmLoading(false));
    reject(error);
  }
}

function* fetchVincereMatches({
  contact,
  matchType,
  enrolmentId = '',
  sequenceId = '',
  resolve = () => {},
  reject = () => {},
}) {
  try {
    yield put(contactActions.setFetchContactCrmLoading(true));
    const payload = {
      id: contact?.id,
      firstName: contact?.fname || '',
      lastName: contact?.lname || '',
      companyName: contact?.employer || '',
      occupation: contact?.jobTitle || '',
      ...(matchType ? { matchType } : {}),
    };
    const matches = yield call(contactApis.getVincereMatches, payload);
    let data = [];
    if (matches?.error?.message) {
      toast.error(matches?.error?.message);
      reject(matches?.error);
      return yield put(contactActions.setVincereMatches(data, {}));
    }
    if (
      matches?.match &&
      !isEmpty(matches?.match) &&
      matches?.match?.id &&
      matches?.match?.id != ''
    ) {
      const payloadConnect = {
        vincereId: matches?.match?.id,
        vincereType: matches?.match?.type,
        id: contact?.id,
      };
      const response = yield call(contactApis.vincereMatch, payloadConnect);
      if (Object.keys(response?.updatedContact || {})?.length) {
        yield put(contactActions.setContact(response.updatedContact));
        toast.success('Contact synced with vincere direct match!');
      }

      yield put(contactActions.setVincereMatches([], matches?.match));
    } else {
      Object.keys(matches).map((matchType) => {
        for (let i = 0; i < matches[matchType].length; i++) {
          const contact = matches[matchType][i];
          data.push({
            ...contact,
            matchType,
          });
        }
      });
      yield put(contactActions.setVincereMatches(data, {}));
    }
    if (enrolmentId && sequenceId)
      yield put(fetchEnrolmentErrors(contact?.id, sequenceId, enrolmentId));
    yield put(contactActions.setFetchContactCrmLoading(false));
    resolve(true);
  } catch (error) {
    toast.error(error?.error?.message);
    reject(error?.error);
    yield put(contactActions.setFetchContactCrmLoading(false));
    yield put(setFetchErrorLoading(false));
    return yield put(contactActions.setVincereMatches(data, {}));
  }
}

function* syncVincereContact({ contactId, match, resolve, reject }) {
  try {
    yield put(contactActions.setContactCrmLoading(true));
    const payload = {
      vincereId: match.id,
      vincereType: match.type,
      id: contactId,
    };
    const response = yield call(contactApis.vincereMatch, payload);
    yield put(contactActions.setContactCrmLoading(false));
    toast.success('Contact synced with vincere!');
    if (Object.keys(response.updatedContact || {}).length)
      yield put(contactActions.setContact(response.updatedContact));
    resolve(response);
  } catch (error) {
    yield put(contactActions.setContactCrmLoading(false));
    reject(error);
  }
}

function* syncVincereNewContact({ contactId, vincereType, resolve, reject }) {
  try {
    yield put(contactActions.setContactCrmLoading(true));
    const response = yield call(contactApis.addContactToVincere, contactId, vincereType);
    yield put(contactActions.setContactCrmLoading(false));
    if (response?.crmContact?.error) {
      toast.error(response?.crmContact?.error?.message, 'tc');
      resolve(response?.crmContact?.error);
      return;
    }
    toast.success('Contact added to vincere!');
    if (response?.updatedContact?.id)
      yield put(contactActions.setContact(response?.updatedContact));
    resolve(response);
  } catch (error) {
    if (error?.error) {
      toast.error(error?.error?.message, 'tc');
    }
    yield put(contactActions.setContactCrmLoading(false));
    reject(error);
  }
}

function* fetchJobAdderMatches({
  contact,
  matchType,
  enrolmentId = '',
  sequenceId = '',
  resolve = () => {},
  reject = () => {},
}) {
  try {
    yield put(contactActions.setFetchContactCrmLoading(true));
    const payload = {
      id: contact?.id,
      firstName: contact?.fname || '',
      lastName: contact?.lname || '',
      companyName: contact?.employer || '',
      occupation: contact?.jobTitle || '',
      ...(matchType ? { matchType } : {}),
    };
    const matches = yield call(contactApis.getJobAdderMatches, payload);
    let data = [];
    if (matches?.error?.message) {
      toast.error(matches?.error?.message);
      reject(matches?.error);
      return yield put(contactActions.setJobAdderMatches(data, {}));
    }
    if (
      matches?.match && !isEmpty(matches?.match) && matches?.match?.type === 'Candidate'
        ? matches?.match?.candidateId
        : matches?.match?.contactId
    ) {
      const payloadConnect = {
        jobadderId:
          matches?.match?.type === 'Candidate'
            ? matches?.match?.candidateId
            : matches?.match?.contactId,
        jobadderType: matches?.match?.type,
        id: contact?.id,
      };
      const response = yield call(contactApis.jobAdderMatch, payloadConnect);
      if (Object.keys(response?.updatedContact || {})?.length) {
        yield put(contactActions.setContact(response.updatedContact));
        toast.success('Contact synced with jobAdder direct match!');
      }

      yield put(contactActions.setJobAdderMatches([], matches?.match));
    } else {
      Object.keys(matches || {}).map((matchType) => {
        for (let i = 0; i < matches[matchType].length; i++) {
          const contact = matches[matchType][i];
          data.push({
            ...contact,
            matchType,
          });
        }
      });
      yield put(contactActions.setJobAdderMatches(data, {}));
    }
    if (enrolmentId && sequenceId)
      yield put(fetchEnrolmentErrors(contact?.id, sequenceId, enrolmentId));
    yield put(contactActions.setFetchContactCrmLoading(false));
    resolve(true);
  } catch (error) {
    toast.error(error?.error?.message);
    reject(error?.error);
    yield put(contactActions.setFetchContactCrmLoading(false));
    yield put(setFetchErrorLoading(false));
    return yield put(contactActions.setJobAdderMatches([], {}));
  }
}

function* syncJobAdderContact({ contactId, match, resolve, reject }) {
  try {
    yield put(contactActions.setContactCrmLoading(true));
    const payload = {
      jobadderId: match.type === 'Candidate' ? match.candidateId : match.contactId,
      jobadderType: match.type,
      id: contactId,
    };
    const response = yield call(contactApis.jobAdderMatch, payload);
    yield put(contactActions.setContactCrmLoading(false));
    toast.success('Contact synced with jobAdder!');
    if (Object.keys(response.updatedContact || {}).length)
      yield put(contactActions.setContact(response.updatedContact));
    resolve(response);
  } catch (error) {
    yield put(contactActions.setContactCrmLoading(false));
    reject(error);
  }
}

function* syncJobAdderNewContact({ contactId, jobAdderType, resolve, reject }) {
  try {
    yield put(contactActions.setContactCrmLoading(true));
    const response = yield call(contactApis.addContactToJobAdder, contactId, jobAdderType);
    yield put(contactActions.setContactCrmLoading(false));
    if (response?.crmContact?.error) {
      toast.error(response?.crmContact?.error?.message, 'tc');
      resolve(response?.crmContact?.error);
      return;
    }
    toast.success('Contact added to jobAdder!');
    if (response?.updatedContact?.id)
      yield put(contactActions.setContact(response?.updatedContact));
    resolve(response);
  } catch (error) {
    yield put(contactActions.setContactCrmLoading(false));
    reject(error);
  }
}

function* fetchSalesforceMatches({
  contact,
  matchType,
  enrolmentId = '',
  sequenceId = '',
  resolve = () => {},
  reject = () => {},
}) {
  try {
    yield put(contactActions.setFetchContactCrmLoading(true));
    const payload = {
      id: contact?.id,
      firstName: contact?.fname || '',
      lastName: contact?.lname || '',
      companyName: contact?.employer || '',
      occupation: contact?.jobTitle || '',
      ...(matchType ? { matchType } : {}),
    };
    const matches = yield call(contactApis.getSalesforceMatches, payload);
    let data = [];
    if (matches?.error?.message) {
      toast.error(matches?.error?.message);
      reject(matches?.error);
      return yield put(contactActions.setSalesforceMatches(data, {}));
    }
    if (
      matches?.match &&
      !isEmpty(matches?.match) &&
      matches?.match?.Id &&
      matches?.match?.Id != ''
    ) {
      const payloadConnect = {
        salesforceId: matches?.match?.Id,
        salesforceType: matches?.match?.type,
        id: contact?.id,
      };
      const response = yield call(contactApis.salesforceMatch, payloadConnect);
      if (Object.keys(response?.updatedContact || {})?.length) {
        yield put(contactActions.setContact(response.updatedContact));
        toast.success('Contact synced with salesforce direct match!');
      }

      yield put(contactActions.setSalesforceMatches([], matches?.match));
    } else {
      Object.keys(matches || {}).map((matchType) => {
        for (let i = 0; i < matches[matchType].length; i++) {
          const contact = matches[matchType][i];
          data.push({
            ...contact,
            matchType,
          });
        }
      });
      yield put(contactActions.setSalesforceMatches(data, {}));
    }
    if (enrolmentId && sequenceId)
      yield put(fetchEnrolmentErrors(contact?.id, sequenceId, enrolmentId));
    yield put(contactActions.setFetchContactCrmLoading(false));
    resolve(true);
  } catch (error) {
    toast.error(error?.error?.message);
    reject(error?.error);
    yield put(contactActions.setFetchContactCrmLoading(false));
    yield put(setFetchErrorLoading(false));
    return yield put(contactActions.setSalesforceMatches(data, {}));
  }
}

function* syncSalesforceContact({ contactId, match, resolve, reject }) {
  try {
    yield put(contactActions.setContactCrmLoading(true));
    const payload = {
      salesforceId: match.Id,
      salesforceType: match.type,
      id: contactId,
    };
    const response = yield call(contactApis.salesforceMatch, payload);
    yield put(contactActions.setContactCrmLoading(false));
    toast.success('Contact synced with salesforce!');
    if (Object.keys(response.updatedContact || {}).length)
      yield put(contactActions.setContact(response.updatedContact));
    resolve(response);
  } catch (error) {
    yield put(contactActions.setContactCrmLoading(false));
    reject(error);
  }
}

function* syncSalesforceNewContact({ contactId, salesforceType, resolve, reject }) {
  try {
    yield put(contactActions.setContactCrmLoading(true));
    const response = yield call(contactApis.addContactToSalesforce, contactId, salesforceType);
    yield put(contactActions.setContactCrmLoading(false));
    if (response?.crmContact?.error) {
      toast.error(response?.crmContact?.error?.message, 'tc');
      resolve(response?.crmContact?.error);
      return;
    }
    toast.success('Contact added to salesforce!');
    if (response?.updatedContact?.id)
      yield put(contactActions.setContact(response?.updatedContact));
    resolve(response);
  } catch (error) {
    if (error?.error) {
      toast.error(error?.error?.message, 'tc');
    }
    yield put(contactActions.setContactCrmLoading(false));
    reject(error);
  }
}

function* fetchHubspotMatches({
  contact,
  matchType,
  enrolmentId = '',
  sequenceId = '',
  resolve = () => {},
  reject = () => {},
}) {
  try {
    yield put(contactActions.setFetchContactCrmLoading(true));
    const payload = {
      id: contact?.id,
      firstName: contact?.fname || '',
      lastName: contact?.lname || '',
      companyName: contact?.employer || '',
      occupation: contact?.jobTitle || '',
      ...(matchType ? { matchType } : {}),
    };
    const matches = yield call(contactApis.getHubspotMatches, payload);
    let data = [];
    if (matches?.error?.message) {
      toast.error(matches?.error?.message);
      reject(matches?.error);
      return yield put(contactActions.setHubspotMatches(data, {}));
    }
    if (
      matches?.match &&
      !isEmpty(matches?.match) &&
      matches?.match?.vid &&
      matches?.match?.vid != ''
    ) {
      const payloadConnect = {
        hubspotId: matches?.match?.vid,
        hubspotType: matches?.match?.type,
        id: contact?.id,
      };
      const response = yield call(contactApis.hubspotMatch, payloadConnect);
      if (Object.keys(response?.updatedContact || {})?.length) {
        yield put(contactActions.setContact(response.updatedContact));
        toast.success('Contact synced with hubspot direct match!');
      }

      yield put(contactActions.setHubspotMatches([], matches?.match));
    } else {
      Object.keys(matches || {}).map((matchType) => {
        for (let i = 0; i < matches[matchType].length; i++) {
          const contact = matches[matchType][i];
          data.push({
            ...contact,
            matchType,
          });
        }
      });
      yield put(contactActions.setHubspotMatches(data, {}));
    }
    if (enrolmentId && sequenceId)
      yield put(fetchEnrolmentErrors(contact?.id, sequenceId, enrolmentId));
    yield put(contactActions.setFetchContactCrmLoading(false));
    resolve(true);
  } catch (error) {
    toast.error(error?.error?.message);
    reject(error?.error);
    yield put(contactActions.setFetchContactCrmLoading(false));
    yield put(setFetchErrorLoading(false));
    return yield put(contactActions.setHubspotMatches(data, {}));
  }
}

function* syncHubspotContact({ contactId, match, resolve, reject }) {
  try {
    yield put(contactActions.setContactCrmLoading(true));
    const payload = {
      hubspotId: match.vid,
      hubspotType: match.type,
      id: contactId,
    };
    const response = yield call(contactApis.hubspotMatch, payload);
    yield put(contactActions.setContactCrmLoading(false));
    toast.success('Contact synced with hubspot!');
    if (Object.keys(response.updatedContact || {}).length)
      yield put(contactActions.setContact(response.updatedContact));
    resolve(response);
  } catch (error) {
    yield put(contactActions.setContactCrmLoading(false));
    reject(error);
  }
}

function* syncHubspotNewContact({ contactId, hubspotType, resolve, reject }) {
  try {
    yield put(contactActions.setContactCrmLoading(true));
    const response = yield call(contactApis.addContactToHubspot, contactId, hubspotType);
    yield put(contactActions.setContactCrmLoading(false));
    if (response?.crmContact?.error) {
      toast.error(response?.crmContact?.error?.message, 'tc');
      resolve(response?.crmContact?.error);
      return;
    }
    toast.success('Contact added to hubspot!');
    if (response?.updatedContact?.id)
      yield put(contactActions.setContact(response?.updatedContact));
    resolve(response);
  } catch (error) {
    if (error?.error) {
      toast.error(error?.error?.message, 'tc');
    }
    yield put(contactActions.setContactCrmLoading(false));
    reject(error);
  }
}

function* getFilterJobOptions({ data, reqType, resolve, reject }) {
  try {
    const response = yield call(contactApis.fetchFilterJobTitleOption, data, reqType);
    resolve(response);
  } catch (error) {
    if (error?.error) {
      toast.error(error?.error?.message, 'tc');
    }
    reject(error);
  }
}

function* getFilterICPOptions({ data, resolve, reject }) {
  try {
    const response = yield call(fetchICP, data);
    resolve(response);
  } catch (error) {
    if (error?.error) {
      toast.error(error?.error?.message, 'tc');
    }
    reject(error);
  }
}

function* getFilterEmpOption({ data, resolve, reject }) {
  try {
    const response = yield call(contactApis.fetchFilterEmployerOption, data);
    resolve(response);
  } catch (error) {
    if (error?.error) {
      toast.error(error?.error?.message, 'tc');
    }
    reject(error);
  }
}

function* getFilterLocationOptions({ data, resolve, reject }) {
  try {
    const response = yield call(contactApis.fetchFilterAddressOption, data);
    resolve(response);
  } catch (error) {
    if (error?.error) {
      toast.error(error?.error?.message, 'tc');
    }
    reject(error);
  }
}

function* getFilterSequenceOptions({ data, resolve, reject }) {
  try {
    const response = yield call(contactApis.fetchSequencesLookup, data);
    resolve(response);
  } catch (error) {
    if (error?.error) {
      toast.error(error?.error?.message, 'tc');
    }
    reject(error);
  }
}
export function* watchSagas() {
  yield takeLatest(contactTypes.FETCH_CONTACT_TABS, fetchContactTabs);
  yield takeLatest(contactTypes.FETCH_FILTER_JOB_OPTIONS, getFilterJobOptions);
  yield takeLatest(contactTypes.FETCH_FILTER_ICP_OPTIONS, getFilterICPOptions);
  yield takeLatest(contactTypes.FETCH_FILTER_EMP_OPTIONS, getFilterEmpOption);
  yield takeLatest(contactTypes.FETCH_SEQUENCE_OPTIONS, getFilterSequenceOptions);
  yield takeLatest(contactTypes.FETCH_FILTER_LOCATION_OPTIONS, getFilterLocationOptions);
  yield takeLatest(contactTypes.FETCH_CONTACTS, fetchContacts);
  yield takeLatest(contactTypes.FETCH_CONTACTS_LISTS, getContactListData);
  yield takeLatest(contactTypes.UPDATE_ENROLLMENT_STATUS, putEnrollment);
  yield takeLatest(contactTypes.FETCH_CONTACT_TASK, fetchContactsTask);
  yield takeLatest(contactTypes.FETCH_CONTACT_NOTE, fetchContactsNote);
  yield takeLatest(contactTypes.FETCH_CONTACT_NOTES, fetchContactsNotes);
  yield takeLatest(contactTypes.FETCH_CONTACT_TASKS, fetchContactTasks);
  yield takeLatest(contactTypes.POST_CONTACT_NOTE, syncPostContactsNote);
  yield takeLatest(contactTypes.FETCH_SEQUENCE_ENROLL, fetchSequenceEnroll);
  yield takeLatest(contactTypes.FETCH_SEQUENCE_ENROLLMENTS, fetchSequenceEnrollments);
  yield takeLatest(contactTypes.FETCH_CONTACT_OPTIONS_FILTERS, fetchContactOptionsFilters);
  yield takeLatest(contactTypes.DELETE_CONTACT, deleteContact);
  yield takeLatest(contactTypes.FETCH_CONTACT, fetchContactDetails);
  yield takeLatest(contactTypes.UPDATE_CONTACT_SOCIAL_MEDIA, updateContactSocialMedia);
  yield takeLatest(contactTypes.POST_CONTACT_DETAIL, postContactDetails);
  yield takeLatest(contactTypes.PUT_CONTACT_DETAIL, putContactDetails);
  yield takeLatest(contactTypes.DELETE_CONTACT_DETAIL, deleteContactDetails);
  yield takeLatest(contactTypes.ADD_CONTACTS, addContacts);
  yield takeLatest(contactTypes.PUT_CONTACT, putContacts);
  yield takeLatest(contactTypes.CONTACT_BULK_ACTION, contactBulkAction);
  yield takeLatest(contactTypes.ADD_CONTACT_TO_SEQUENCE, addContactToSequence);
  yield takeLatest(contactTypes.SEND_CONTACT_EMAIL, sendContactEmail);
  yield takeLatest(contactTypes.PUT_CONTACT_EMAIL, putContactEmail);
  yield takeLatest(contactTypes.FETCH_BULLHORN_MATCHES, fetchBullhornMatches);
  yield takeLatest(contactTypes.SYNC_BULLHORN_CONTACT, syncBullhornContact);
  yield takeLatest(contactTypes.SYNC_BULLHORN_NEW_CONTACT, syncBullhornNewContact);
  yield takeLatest(contactTypes.FETCH_VINCERE_MATCHES, fetchVincereMatches);
  yield takeLatest(contactTypes.SYNC_VINCERE_CONTACT, syncVincereContact);
  yield takeLatest(contactTypes.SYNC_VINCERE_NEW_CONTACT, syncVincereNewContact);
  yield takeLatest(contactTypes.FETCH_JOBADDER_MATCHES, fetchJobAdderMatches);
  yield takeLatest(contactTypes.SYNC_JOBADDER_CONTACT, syncJobAdderContact);
  yield takeLatest(contactTypes.SYNC_JOBADDER_NEW_CONTACT, syncJobAdderNewContact);
  yield takeLatest(contactTypes.FETCH_HUBSPOT_MATCHES, fetchHubspotMatches);
  yield takeLatest(contactTypes.SYNC_HUBSPOT_CONTACT, syncHubspotContact);
  yield takeLatest(contactTypes.SYNC_HUBSPOT_NEW_CONTACT, syncHubspotNewContact);
  yield takeLatest(contactTypes.FETCH_SALESFORCE_MATCHES, fetchSalesforceMatches);
  yield takeLatest(contactTypes.SYNC_SALESFORCE_CONTACT, syncSalesforceContact);
  yield takeLatest(contactTypes.SYNC_SALESFORCE_NEW_CONTACT, syncSalesforceNewContact);
  yield takeLatest(contactTypes.CHECK_ENROLLMENT_AND_FETCH_ERROR, checkEnrolmentAndFetchErrors);
}
export default function* runSagas() {
  yield all([fork(watchSagas)]);
}
