import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { Typography, Grid, Box, Tooltip } from '@mui/material';
import { Button, Form, Spacer } from 'src/components/shared';
import { fetchParentTeamsLookup } from 'src/modules/admin/api/adminApi';
import { fetchUsersLookup } from 'src/modules/users/api/usersApi';
import InfoIcon from '@mui/icons-material/Info';

function TeamForm({
  teamLead,
  drawerToggle,
  editUser,
  formData,
  loading,
  total,
  userTotal,
  ...props
}) {
  const [defaultCandidate, setDefaultCandidate] = useState({});
  const [teamsStatus] = useState(
    props.globals?.teamStatusList?.length
      ? props.globals.teamStatusList.map((i, key) => ({
          id: i.value,
          value: i.label.charAt(0).toUpperCase() + i.label.slice(1),
        }))
      : [],
  );

  useEffect(() => {
    if (editUser && editUser?.id) {
      setDefaultCandidate(editUser);
    }
  }, [editUser]);

  const handleSubmit = async (values, form) => {
    const data = { ...values };
    data.teamLead = values.teamLead.id;
    data.parentTeam = values.parentTeam.id;
    data.status = values.status ? 'active' : 'inactive';
    if (editUser && editUser?.id) {
      await props.putTeam(editUser?.id, data);
      drawerToggle({ edit: null, open: false });
    } else {
      await props.saveTeams(data);
      drawerToggle({ edit: null, open: false });
    }
  };

  const TitleBar = ({ title, tooltip }) => {
    return (
      <Box display="flex" alignItems="center">
        <Typography variant="h4">{title}</Typography>
        {tooltip && tooltip !== '' ? (
          <>
            <Spacer y={1} x={1} />

            <Tooltip title={tooltip}>
              <InfoIcon style={{ fill: 'rgba(0, 0, 0, 0.26)', width: 22 }} />
            </Tooltip>
          </>
        ) : null}
      </Box>
    );
  };

  return (
    <Form
      initialValues={{
        teamLead: defaultCandidate?.teamLead?.id
          ? {
              id: defaultCandidate && defaultCandidate?.teamLead?.id,
              name: `${defaultCandidate?.teamLead?.fname} ${defaultCandidate?.teamLead?.lname}`,
            }
          : {},
        parentTeam: defaultCandidate?.parentTeam?.id
          ? {
              id: defaultCandidate && defaultCandidate?.parentTeam?.id,
              value: defaultCandidate?.parentTeam?.name,
            }
          : {},
        name: defaultCandidate.name || '',
        status: defaultCandidate.status === 'active' ? true : false,
        description: defaultCandidate.description || '',
        isParent: defaultCandidate.isParent || false,
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string().required('Please enter team name.'),
      })}
      enableReinitialize={true}
      onSubmit={handleSubmit}
      validateOnBlur={true}
      validateOnChange={false}
    >
      {(props) => {
        return (
          <form
            onSubmit={(e) => {
              e.preventDefault();
              props.submitForm();
              return false;
            }}
            style={{ height: '100%' }}
            noValidate
          >
            <Box
              display="flex"
              flex={1}
              flexDirection="column"
              justifyContent="space-between"
              // alignItems="flex-end"
              height="100%"
            >
              <Box container spacing={2}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Form.Field.Input
                      fullWidth
                      rows={4}
                      variant="outlined"
                      name="name"
                      label="Team name"
                    />
                  </Grid>
                </Grid>

                <Form.Field.Input
                  fullWidth
                  rows={4}
                  variant="outlined"
                  name="description"
                  label="Description"
                />

                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Form.Field.AutoComplete
                      options={[]}
                      fullWidth
                      name="teamLead"
                      label="Team Lead"
                      placeholder="Team Lead"
                      variant="outlined"
                      remoteMethod={(val) => {
                        return fetchUsersLookup(val, false, false, {
                          permission_eq: 'teamLead',
                          _size: 100,
                        });
                      }}
                      optLabel="name"
                      optValue="id"
                      // multiple={false}
                    />
                  </Grid>
                  {/* <Grid item xs={6}>
                    <Form.Field.AutoComplete
                      fullWidth
                      options={[]}
                      name="parentTeam"
                      label="Parent Team"
                      placeholder="Parent Team"
                      variant="outlined"
                      optLabel="value"
                      remoteMethod={(val) => {
                        return fetchParentTeamsLookup(val);
                      }}
                      optValue="id"
                      multiple={false}
                    />
                  </Grid> */}
                  <Grid item xs={12} ml={2}>
                    <Form.Field.Switch
                      rows={4}
                      variant="outlined"
                      name="status"
                      label={
                        <Box display="flex" alignItems="center">
                          Status
                          <TitleBar tooltip="Active/Inactive" />
                        </Box>
                      }
                      sx={{ marginTop: -1 }}
                    />

                    {/* <Form.Field.Switch
                      rows={4}
                      variant="outlined"
                      name="isParent"
                      label="Mark as Parent Team"
                    /> */}
                  </Grid>
                </Grid>
              </Box>

              <Box display="flex" justifyContent="flex-end">
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={() => drawerToggle({ edit: null, open: false })}
                  loading={loading.teams}
                  disabled={loading.teams}
                >
                  Cancel
                </Button>
                <Spacer x={2} y={2} />
                <Button
                  variant="contained"
                  color="secondary"
                  type="submit"
                  loading={loading.teams}
                  disabled={loading.teams}
                  style={{
                    width: '80px',
                  }}
                >
                  {!defaultCandidate.id ? 'Save' : 'Update'}
                </Button>
              </Box>
            </Box>
          </form>
        );
      }}
    </Form>
  );
}
export default TeamForm;