import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { Box, Button } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { Link } from 'react-router-dom';
import UsersGrid from '../../components/Users/UsersGrid';
import useRequest from 'src/components/hooks/useRequest';
import { getTeam, getTeamUsers } from 'src/modules/auth/api/authApis';
import { Drawer } from 'src/components/shared';
import { putTeam, userStatusUpdate } from '../../actions/adminActions';
import { connect, useDispatch } from 'react-redux';
import CompanyTeamForm from './CompanyTeamForm';
import TeamForm from './TeamForm';
import { palette } from 'src/config/theme';

const Team = (props) => {
  const teamId = props.match.params.id;
  const dispatch = useDispatch();
  const [paging, setPaging] = useState({ pageNo: 0, perPage: 10, count: 0 });
  const [filters, setFilters] = useState({});
  const [isTeamNameEdit, setIsTeamNameEdit] = useState(false);
  const [isLoader, setIsLoader] = useState(true);
  const [drawerOpen, setDrawerOpen] = useState({ edit: null, open: false });
  const [teamData, setTeamData] = useState({
    teamName: '',
    teamLead: null,
    status: false,
  });
  const payload = {
    teamId,
    _from: paging.pageNo * paging.perPage,
    _size: paging.perPage,
    ...(filters.status?.value && { status: filters.status.value }),
    ...(filters._search && { _search: filters._search }),
  };
  const [teamDetails, { loading: teamLoading, error: teamError, refetch: teamRefetch }] =
    useRequest(getTeam, teamId);
  const [teamUsers, { loading: teamUsersLoading, error: teamUsersError, refetch: usersRefetch }] =
    useRequest(getTeamUsers, payload);

  const existingTeamData = teamDetails?.team || {};
  const usersData = teamUsers?.users || [];

  useEffect(() => {
    if (existingTeamData?.name) {
      setIsTeamNameEdit(true);
      setIsLoader(false);
      setTeamData({
        teamName: existingTeamData?.name || '',
        teamLead: existingTeamData?.teamLead
          ? {
              name:
                `${existingTeamData.teamLead.fname || ''} ${
                  existingTeamData.teamLead.lname || ''
                }`.trim() || 'N/A',
              id: existingTeamData.teamLead.id || null,
            }
          : null,
        status: existingTeamData?.status === 'active',
      });
    }
  }, [existingTeamData]);

  const toggleTeamNameEdit = useCallback(() => {
    setIsTeamNameEdit((prev) => !prev);
  }, []);

  let data = teamUsers?.users || [];
  const users = {
    data: data,
    paging: {
      page: 1,
      pageSize: 10,
      total: 0,
    },
    filters: filters,
    sort: {
      field: 'name',
      order: 'asc',
    },
  };

  const handleFormSubmit = (values) => {
    const data = {
      name: values.teamName,
      status: values.status ? 'active' : 'inactive',
      ...(values.teamLead?.id ? { teamLead: values.teamLead.id } : {}),
    };

    dispatch(putTeam(teamId, data));
  };

  const handleAddUsers = async (users) => {
    const newUserIds = users.teamMembers.map((user) => user?.id);
    const selectedUsers = [...newUserIds];
    const data = {
      teamMembers: selectedUsers.filter((item) => item),
    };
    dispatch(putTeam(teamId, data));
    setDrawerOpen({ edit: null, open: false });
    await teamRefetch();
    await usersRefetch();
  };

  const searchUser = (paging, filters, sort) => {
    setFilters({
      ...filters,
    });
    setPaging((prev) => ({
      ...prev,
      pageNo: paging.page,
      perPage: paging.pageSize,
      count: paging.total,
    }));
  };

  const handleUserStatus = async (rowData) => {
    await props.updateUserStatus(rowData);
    await usersRefetch();
  };

  return (
    <Box>
      <Box display="flex" alignItems="center" gap={2} mb={2}>
        <Link to="/admin/manage-company/teams" style={{ textDecoration: 'none' }}>
          <Button
            startIcon={<ChevronLeftIcon />}
            variant="text"
            sx={{
              textTransform: 'none',
              fontSize: '14px',
              fontWeight: 500,
              color: palette.text.grey13,
            }}
          >
            Back
          </Button>
        </Link>
      </Box>
      <TeamForm
        handleFormSubmit={handleFormSubmit}
        teamData={teamData}
        setTeamData={setTeamData}
        toggleTeamNameEdit={toggleTeamNameEdit}
        usersData={usersData}
        isTeamNameEdit={isTeamNameEdit}
        teamId={teamId}
        isLoader={isLoader}
      />
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Drawer
          title={drawerOpen.edit ? 'Edit Team' : 'Add user'}
          open={drawerOpen.open}
          onClose={() => {
            setDrawerOpen({ edit: null, open: false });
          }}
        >
          {drawerOpen.open && (
            <CompanyTeamForm
              plansData={[]}
              editUser={drawerOpen.edit}
              drawerToggle={setDrawerOpen}
              addUsers={handleAddUsers}
              existingUsers={existingTeamData?.teamMembers}
              loading={{ users: teamUsersLoading }}
              isTeamView={false}
              {...props}
            />
          )}
        </Drawer>
      </Box>
      <UsersGrid
        users={users}
        isTeamView={true}
        loading={{ users: teamUsersLoading }}
        drawerToggle={setDrawerOpen}
        fetchUsers={searchUser}
        updateUserStatus={handleUserStatus}
      />
    </Box>
  );
};

const mapStateToProps = (state) => {
  return {
    // loading: state.admin.admin.loading,
  };
};

const mapDispatchToProps = (dispatch) => ({
  updateUserStatus: (rowData) => {
    return new Promise((resolve, reject) => {
      dispatch(userStatusUpdate(rowData, resolve, reject));
    });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Team);
