import React, { useEffect, useMemo, useState } from 'react';
import { Stack, Typography } from '@mui/material';
import UsersGrid from '../../components/Users/UsersGrid';
import { Button, Drawer } from 'src/components/shared';
import UserForm from '../../components/Users/UserForm';
import ManageUserLicense from '../../components/ManageUserLicense';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { UserConfirmModal } from '../../components/Users/UserConfirmModal';
import { fetchSubscriptions } from '../../api/billingApi';

export const getSubscriptionTitle = (key) =>
  key === 'lms' ? 'Learning' : key === 'outreach' ? 'Outreach' : '';

export const planSortOrder = ['outreach', 'lms'];
export const subscriptionTypeOrder = ['monthly', 'yearly'];
export const subscriptionPeriod = {
  outreachMonthly: 'outreachMonthly',
  outreachYearly: 'outreachAnnual',
  lmsMonthly: 'lmsmonthly',
  lmsYearly: 'lmsannual',
};

const subcriptionListObj = {
  'LMS-Annual-upfront-GBP-Yearly': ['lmsannual'],
  'LMS-12-monthly-payments-GBP-Monthly': ['lmsmonthly'],
  'Outreach-GBP-Monthly': ['outreachMonthly'],
  'OutreachAnnual-GBP-Yearly': ['outreachAnnual'],
  'One-Membership-GBP-Yearly': ['lmsannual', 'outreachAnnual'],
  'One-Membership-Monthly-GBP-Monthly': ['outreachMonthly', 'lmsmonthly'],
  'One-Membership-Trial-Plan-GBP-7-days': ['outreachMonthly', 'lmsmonthly'],
  'One-Membership-Freemium-Monthly-GBP-Monthly': ['outreachMonthly', 'lmsmonthly'],
};

function UsersView({ usersTabs, users, total, userBulkUpdate, ...props }) {
  const history = useHistory();
  const tenant = useSelector((state) => state.app.tenant);
  const user = useSelector((state) => state.auth.user);
  const chargeBeeId = tenant?.chargeBeeId || '';
  const globalConfig = useSelector((state) => state.app.globals);
  const [drawerOpen, setDrawerOpen] = useState({ edit: null, open: false });
  const [isConfirmModal, setIsConfirmModal] = useState(false);
  const [licencedValues, setLicencedValues] = useState({});
  const [subscriptions, setSubscriptions] = useState({});
  const [subscriptionLoading, setSubscriptionLoading] = useState(false);

  const getSubscriptions = async (chargeBeeId) => {
    try {
      setSubscriptionLoading(true);
      const res = await fetchSubscriptions(chargeBeeId);
      setSubscriptionLoading(false);
      setSubscriptions(res);
      return res;
    } catch (error) {
      setSubscriptionLoading(false);
    }
  };

  useEffect(() => {
    const subscriptionDetailObj = user?.tenants[0]?.subscription;
    let outreachType,
      lmsType = '';
    Object.keys(subscriptionDetailObj).forEach((key) => {
      if (
        subscriptionDetailObj[key]?.status === 'active' ||
        subscriptionDetailObj[key]?.status === 'in_trial'
      ) {
        if (subcriptionListObj.hasOwnProperty(key)) {
          subcriptionListObj[key].forEach((ele) => {
            if (ele === 'outreachMonthly' || ele === 'outreachAnnual') {
              outreachType = ele;
            } else if (ele === 'lmsmonthly' || ele === 'lmsannual') {
              lmsType = ele;
            }
          });
        }
      }
    });
    getSubscriptions(chargeBeeId);
    setLicencedValues((prevState) => ({
      ...prevState,
      outreachType,
      lmsType,
    }));
  }, [user, users]);

  function getMaxAvailableCount(licencedValues, secondObj) {
    if (!secondObj || typeof secondObj !== 'object') {
      return null;
    }

    let maxValue = null; // Initialize to track the maximum available count

    // Iterate over each key in secondObj
    Object.keys(secondObj).forEach((key) => {
      if (key.endsWith('_consumed')) {
        // Find the base key (e.g., "lmsMonthly" from "lmsMonthly_consumed")
        const baseKey = key.replace('_consumed', '');

        // Check if secondObj has both the base key and its consumed counterpart
        if (secondObj.hasOwnProperty(baseKey)) {
          const available = secondObj[baseKey] - secondObj[key];
          if (maxValue === null || available >= maxValue) {
            maxValue = available; // Update maxValue if a higher available count is found
          }
        }
      }
    });

    return maxValue;
  }

  const relatedValues = useMemo(
    () => getMaxAvailableCount(licencedValues, subscriptions.seats),
    [licencedValues, subscriptions],
  );

  const showUpgradePlan = relatedValues > 0;

  const plansData = [
    'lmsAnnual',
    'lmsAnnual_consumed',
    'lmsMonthly',
    'lmsMonthly_consumed',
    'outreachAnnual',
    'outreachAnnual_consumed',
    'outreachMonthly',
    'outreachMonthly_consumed',
  ]?.reduce((acc, key) => {
    const planKey = [
      'lmsAnnual',
      'lmsAnnual_consumed',
      'lmsMonthly',
      'lmsMonthly_consumed',
    ]?.includes(key)
      ? 'lms'
      : [
          'outreachAnnual',
          'outreachAnnual_consumed',
          'outreachMonthly',
          'outreachMonthly_consumed',
        ]?.includes(key)
      ? 'outreach'
      : '';
    const isConsumed = !!key?.split?.('_')?.[1];
    const used = isConsumed ? tenant[key] : 0;
    const quantity = !isConsumed ? tenant[key] : 0;
    const period = [
      'outreachAnnual',
      'outreachAnnual_consumed',
      'lmsAnnual',
      'lmsAnnual_consumed',
    ]?.includes(key)
      ? 'yearly'
      : [
          'lmsMonthly',
          'lmsMonthly_consumed',
          'outreachMonthly',
          'outreachMonthly_consumed',
        ]?.includes(key)
      ? 'monthly'
      : '';
    if (planKey && (quantity || used))
      acc[planKey] = {
        title: getSubscriptionTitle(planKey),
        plans: {
          ...(acc?.[planKey]?.plans ?? {}),
          [period]: {
            quantity: (acc?.[planKey]?.plans?.[period]?.quantity ?? 0) + quantity,
            used: (acc?.[planKey]?.plans?.[period]?.used ?? 0) + used,
          },
        },
        ...(planKey === 'lms' ? { categories: globalConfig?.lmsCategories } : {}),
      };
    return acc;
  }, {});

  return (
    <Stack gap={0.5}>
      {!user?.isAdmin && (
        <Stack direction="row" justifyContent={'space-between'} alignItems={'flex-start'}>
          <Stack gap={1.4}>
            <Typography>Licences remaining</Typography>
            <Stack direction="row" gap={2}>
              {Object.keys(plansData || {})
                ?.sort((a, b) => planSortOrder.indexOf(a) - planSortOrder.indexOf(b))
                ?.map((subscriptionType) => {
                  const subscriptionPlan = plansData[subscriptionType];
                  let available = 0;
                  let total = 0;
                  let used = 0;
                  Object.values(subscriptionPlan?.plans)?.forEach((plan) => {
                    total = total + (plan.quantity || 0);
                    used = used + (plan.used || 0);
                  });
                  available += total - used;
                  return (
                    <Stack
                      padding="12px 60px"
                      border={'1px solid #00000042'}
                      borderRadius={'4px'}
                      direction={'row'}
                      alignItems={'center'}
                      gap={'7px'}
                    >
                      <Typography>{subscriptionPlan?.title}</Typography>
                      <span>|</span>
                      <Typography fontWeight={500}>
                        {available} of {total}
                      </Typography>
                    </Stack>
                  );
                })}
            </Stack>
          </Stack>
          <Button color="secondary" onClick={() => history.push('/admin/billing/subscriptions')}>
            Add more licences &nbsp; <OpenInNewIcon />
          </Button>
        </Stack>
      )}

      <UsersGrid
        drawerToggle={setDrawerOpen}
        users={users}
        plansData={plansData}
        userBulkUpdate={userBulkUpdate}
        userBulkDelete={props.userBulkDelete}
        setIsConfirmModal={setIsConfirmModal}
        showUpgradePlan={showUpgradePlan}
        subscriptionLoading={subscriptionLoading}
        {...props}
      />

      <UserConfirmModal open={isConfirmModal} onClose={() => setIsConfirmModal(false)} />

      <Drawer
        title={
          <Typography fontSize={'18px'} fontWeight={600}>
            {drawerOpen.edit ? 'Edit user' : 'Create user'}
          </Typography>
        }
        open={drawerOpen.open}
        sx={{}}
        onClose={() => {
          setDrawerOpen({ edit: null, open: false });
        }}
      >
        {drawerOpen.open && (
          <UserForm
            plansData={plansData}
            loading={props.loading}
            editUser={drawerOpen.edit}
            openSubscriptionPage={drawerOpen.subscription ? 1 : 0}
            total={total}
            drawerToggle={setDrawerOpen}
            {...props}
          />
        )}
      </Drawer>
      <Drawer title={'Manage Users'} open={false} onClose={() => {}}>
        <ManageUserLicense />
      </Drawer>
    </Stack>
  );
}

export default UsersView;
