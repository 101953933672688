import React, { useState, useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Typography, Box, Stack } from '@mui/material';
import { Menu, Button, Avatar } from 'src/components/shared';
import { getSequenceStatus } from '../../utils/contactUtils';
import { ContactStatus } from './styles';
import { contactColumns } from './contactsColumnsData';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import IntegrationStatus from '../Contact/IntegrationStatus';
import Icon from 'src/components/lms/Icon';
import { useTheme } from '@mui/styles';

export function useContactsColumns(contacts, fields, edit, drawerToggle, props, onDelete) {
  const theme = useTheme();
  const [columns, setColumns] = useState([]);
  // const enabledColumns = useMemo(() => {
  //   return fields.filter(
  //     (field) => field?.fieldOptions?.showOnGrid && field?.fieldOptions?.showOnGrid === true,
  //   );
  // }, [fields]);
  const enabledColumns = contactColumns;

  const findJobTitle = (contact) => {
    if (!contact?.jobTitle) {
      return contact?.workHistory?.length > 0 ? contact?.workHistory[0]?.jobTitle : '';
    }
    return contact?.jobTitle || '';
  };

  const findEmployer = (contact) => {
    if (!contact?.employer) {
      return contact?.workHistory?.length > 0 ? contact?.workHistory[0]?.employer : '';
    }
    return contact?.employer || '';
  };

  useEffect(() => {
    let data = enabledColumns.map((item) => {
      return {
        name:
          item.fieldName === 'name'
            ? 'fname'
            : item.fieldName === 'account'
            ? 'employer'
            : item.fieldName === 'sequences'
            ? 'stats.sequences'
            : item.fieldName === 'opens'
            ? 'stats.open'
            : item.fieldName === 'replies'
            ? 'stats.reply'
            : item.fieldName,
        label: item.fieldName === 'imageUrl' ? ' ' : item.label,
        options: {
          ...(item.gridParams &&
            item.gridParams?.contacts && {
              ...item.gridParams.contacts,
            }),
          ...(item.fieldName === 'status' && {
            sort: false,
          }),
          ...(item.type === 'custom' && {
            customBodyRenderLite: (index) => {
              const contact = contacts[index];
              if (!contact?.id) return '';
              switch (item.fieldType) {
                case 'user':
                case 'contact':
                  const user = contact[item.fieldName];
                  if (user && user?.id) {
                    return (
                      <Typography variant="body2">
                        {user.fname} {user.lname}
                      </Typography>
                    );
                  }
                  return '-';
                  break;

                default:
                  return <Typography variant="body2">{contact[item.fieldName] || '-'}</Typography>;
                  break;
              }
            },
          }),
          ...(item.type === 'default' && {
            customBodyRenderLite: (index) => {
              const contact = contacts[index];
              if (!contact?.id) return '';
              const contactName = `${contact.fname} ${contact.lname}`;
              const status = getSequenceStatus(contact?.status);
              switch (item.fieldName) {
                case 'name':
                  return (
                    <Box display="flex">
                      <Link
                        className="text-truncate td-anchor-content-ellipsis"
                        to={`/contact/${contact.id}`}
                      >
                        <Typography variant="body2" color="secondary">
                          {`${contact?.fname} ${contact.lname || ''}`}
                        </Typography>
                      </Link>
                    </Box>
                  );
                  break;

                  jobTitle;

                case 'jobTitle':
                  return (
                    <Typography variant="body2" color="textPrimary">
                      {findJobTitle(contact)}
                    </Typography>
                  );
                  break;

                case 'status':
                  return <ContactStatus status={contact?.status}>{status}</ContactStatus>;
                  break;

                case 'account':
                  return (
                    <Typography variant="body2" color="textPrimary">
                      {findEmployer(contact)}
                    </Typography>
                  );
                  break;

                case 'imageUrl':
                  return <Avatar name={contactName} src={contact.imageUrl} size={32} />;
                  break;

                case 'opens':
                  return contact.stats?.open || 0;
                  break;

                case 'country':
                  return contact?.address?.[0].country || '';
                  break;

                case 'city':
                  return contact?.address?.[0].town || '';
                  break;

                case 'sequences':
                  return contact.stats?.sequences || 0;
                  break;

                case 'replies':
                  return contact.stats?.reply || 0;
                  break;

                case 'sent':
                  return contact.stats?.sent || 0;
                  break;

                case 'bounces':
                  return contact.stats?.bounce || 0;
                  break;

                case 'failed':
                  return contact.stats?.failed || 0;
                  break;

                case 'clicked':
                  return contact.stats?.click || 0;
                  break;

                case 'address':
                  const address = contact[item.fieldName]?.length
                    ? contact[item.fieldName][0]?.address
                    : contact[item.fieldName];

                  return typeof address !== 'undefined' && address ? address : '';

                case 'icp':
                  if (contact[item.fieldName]) {
                    return (
                      <Stack
                        direction="row"
                        alignItems="center"
                        gap={0.5}
                        p={1}
                        borderRadius="999px"
                        bgcolor={theme.palette.background.skyBlue}
                      >
                        <Icon name="tagFilled" />
                        <Typography variant="subtitle2" color={theme.palette.text.green}>
                          ICP
                        </Typography>
                      </Stack>
                    );
                  }

                default:
                  return typeof contact[item.fieldName] !== 'undefined' && contact[item.fieldName]
                    ? contact[item.fieldName]
                    : '';
                  break;
              }
            },
          }),
        },
      };
    });

    data = [
      {
        name: 'view',
        label: ' ',
        options: {
          sort: false,
          customBodyRenderLite: (dataIndex) => {
            const contact = contacts[dataIndex];
            const isUnsubscribed = contact?.status === 'unsubscribed';
            return (
              <Box justifyContent="center" display="flex">
                <Button
                  iconButton={true}
                  disabled={isUnsubscribed}
                  onClick={() => {
                    props.toggleContactDrawer(contact.id);
                    // drawerToggle({ edit: contact, open: true });
                  }}
                >
                  <VisibilityIcon fontSize={'small'} style={{ color: 'rgba(0, 0, 0, 0.54)' }} />
                </Button>
              </Box>
            );
          },
        },
      },
      {
        name: 'integration',
        label: ' ',
        options: {
          sort: false,
          customBodyRenderLite: (dataIndex) => {
            const contact = contacts[dataIndex];
            return <IntegrationStatus contact={contact} />;
          },
        },
      },
      ...data,
      {
        name: 'id',
        label: ' ',
        options: {
          filter: false,
          sort: false,
          draggable: false,
          allowToggle: false,
          viewColumns: false,
          setCellHeaderProps: (value) => ({
            style: { minWidth: 35, width: 35, maxWidth: 35, padding: 0 },
          }),
          setCellProps: () => ({
            style: { minWidth: 35, width: 35, maxWidth: 35, padding: 0 },
          }),
          customBodyRenderLite: (index) => {
            const contact = contacts[index];
            const isUnsubscribed = contact?.status === 'unsubscribed';

            return (
              <Menu
                disabled={isUnsubscribed}
                options={[
                  {
                    label: 'Edit',
                    icon: <EditIcon />,
                    onClick: () => {
                      props.toggleAddContactForm(contact);
                    },
                  },
                  {
                    label: 'Delete',
                    icon: <DeleteIcon />,
                    confirm: true,
                    confirmMessage: 'Are you sure you want to delete this contact?',
                    onClick: () => {
                      onDelete(contact);
                    },
                    popUpTitle: 'Delete Contact',
                  },
                ]}
              />
            );
          },
        },
      },
    ];

    // data.push();
    setColumns(data);
  }, [enabledColumns, contacts]);

  return columns;
}
