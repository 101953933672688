import * as types from './adminTypes';

/* Users Actions */

export const fetchUserTabs = () => ({ type: types.FETCH_ADMIN_USERS_TABS });
export const setUserTabs = (tabs) => ({ type: types.SET_ADMIN_USERS_TABS, tabs });

export const fetchUsers = (paging, filters, sort) => ({
  type: types.FETCH_ADMIN_USERS,
  paging,
  filters,
  sort,
});

export const setUsers = (data) => ({
  type: types.SET_ADMIN_USERS,
  data,
});

export const saveUser = (data, resolve, reject) => ({
  type: types.SAVE_ADMIN_USER,
  data,
  resolve,
  reject,
});

export const updateUserStatus = (rowData) => ({
  type: types.UPDATE_ADMIN_USER_STATUS,
  rowData,
});

export const userStatusUpdate = (rowData, resolve, reject) => ({
  type: types.UPDATE_USER_STATUS,
  rowData,
  resolve,
  reject,
});

export const setUserStatus = (rowData) => ({
  type: types.SET_ADMIN_USER_STATUS,
  rowData,
});

export const setUser = (data) => ({
  type: types.SET_ADMIN_USER,
  data,
});

export const putUser = (rowData) => ({
  type: types.UPDATE_ADMIN_USER,
  rowData,
});

export const putUserAdmin = (rowData) => ({
  type: types.UPDATE_USER,
  rowData,
});

export const userBulkUpdate = (users, payload, resolve, reject) => ({
  type: types.USER_BULK_UPDATE,
  users,
  payload,
  resolve,
  reject,
});
export const userBulkDelete = (action, users, resolve, reject) => ({
  type: types.USER_BULK_DELETE,
  users,
  action,
  resolve,
  reject,
});

/*  Teams Actions */

export const fetchTeams = (paging, filters, sort) => ({
  type: types.FETCH_ADMIN_TEAMS,
  paging,
  filters,
  sort,
});

export const setTeams = (data) => ({
  type: types.SET_ADMIN_TEAMS,
  data,
});
export const saveTeams = (data) => ({
  type: types.SAVE_ADMIN_TEAMS,
  data,
});
export const setTeam = (data) => ({
  type: types.SET_ADMIN_TEAM,
  data,
});

export const teamBulkUpdate = (teams, payload, resolve, reject) => ({
  type: types.TEAM_BULK_UPDATE,
  teams,
  payload,
  resolve,
  reject,
});

export const templateBulkUpdate = (template, payload, loadType, resolve, reject) => ({
  type: types.TEMPLATE_BULK_UPDATE,
  template,
  payload,
  loadType,
  resolve,
  reject,
});

export const officeBulkUpdate = (offices, payload, resolve, reject) => ({
  type: types.OFFICE_BULK_UPDATE,
  offices,
  payload,
  resolve,
  reject,
});

export const putTeam = (id, rowData) => ({
  type: types.UPDATE_ADMIN_TEAM,
  id,
  rowData,
});

export const putTeamAdmin = (rowData) => ({
  type: types.UPDATE_TEAM_ADMIN,
  rowData,
});

export const updateTeamStatus = (rowData) => ({
  type: types.UPDATE_TEAM_STATUS,
  rowData,
});

export const setTeamStatus = (rowData) => ({
  type: types.SET_TEAM_STATUS,
  rowData,
});

/*  Offices Actions */

export const fetchOffices = (paging, filters, sort) => ({
  type: types.FETCH_ADMIN_OFFICES,
  paging,
  filters,
  sort,
});

export const setOffices = (data) => ({
  type: types.SET_ADMIN_OFFICES,
  data,
});

export const saveOffice = (data) => ({
  type: types.SAVE_ADMIN_OFFICE,
  data,
});

export const setOffice = (data) => ({
  type: types.SET_ADMIN_OFFICE,
  data,
});

export const fetchOfficesData = (start, pagesize) => ({
  type: types.FETCH_OFFICES_DATA,
  start,
  pagesize,
});

export const updateOfficeStatus = (rowData) => ({
  type: types.UPDATE_OFFICE_STATUS,
  rowData,
});
export const putOffice = (id, rowData) => ({
  type: types.UPDATE_ADMIN_OFFICE,
  id,
  rowData,
});

export const putOfficeAdmin = (rowData) => ({
  type: types.UPDATE_OFFICE_ADMIN,
  rowData,
});

export const setOfficeStatus = (rowData) => ({
  type: types.SET_OFFICE_STATUS,
  rowData,
});

/* Templates Actions */

export const fetchTemplates = (paging, filters, queryType, sort) => ({
  type: types.FETCH_ADMIN_TEMPLATES,
  paging,
  filters,
  queryType,
  sort,
});

export const setTemplates = (data) => ({
  type: types.SET_ADMIN_TEMPLATES,
  data,
});

export const updateTemplateStatus = (rowData) => ({
  type: types.UPDATE_TEMPLATE_STATUS,
  rowData,
});

export const setTemplateStatus = (data) => ({
  type: types.SET_TEMPLATE_STATUS,
  data,
});

export const saveTemplates = (data, accessType, resolve, reject) => ({
  type: types.SAVE_ADMIN_TEMPLATE,
  data,
  accessType,
  resolve,
  reject,
});

export const setTemplate = (data) => ({
  type: types.SET_ADMIN_TEMPLATE,
  data,
});

export const setUpdateTemplate = (data) => ({
  type: types.UPDATE_ADMIN_TEMPLATE,
  data,
});

export const putTemplates = (id, rowData) => ({
  type: types.PUT_ADMIN_TEMPLATE,
  id,
  rowData,
});

/******* Integration Action ********/

export const fetchIntegrations = () => ({
  type: types.FETCH_ADMIN_INTEGRATIONS,
});

export const saveIntegrations = (data) => ({
  type: types.SAVE_ADMIN_INTEGRATIONS,
  data,
});

export const saveRescheduleEmail = (data) => ({
  type: types.SAVE_ADMIN_RESCHEDULE_EMAIL,
  data,
});
export const disconnectIntegration = (id, status) => ({
  type: types.DISCONNECT_ADMIN_INTEGRATION,
  id,
  status,
});

export const setIntegrations = (data) => ({
  type: types.SET_ADMIN_INTEGRATIONS,
  data,
});

export const setIntegration = (data) => ({
  type: types.SET_ADMIN_INTEGRATION,
  data,
});
export const changeIntegration = (data) => ({
  type: types.UPDATE_INTEGRATION_ADMIN,
  data,
});

/********   Settings  *********/

export const settingNames = (status) => ({
  type: types.SETTING_NAME_ADMIN,
  status,
});

export const fetchTenantSettings = () => ({
  type: types.FETCH_SETTING_ADMIN,
});

export const setTenantSettings = (data) => ({
  type: types.SET_SETTING_ADMIN,
  data,
});

export const updateSettings = (data, id) => ({
  type: types.UPDATE_SETTING_ADMIN,
  data,
  id,
});

export const putTenantSettings = (data) => ({
  type: types.PUT_SETTING_ADMIN,
  data,
});

// Central Lookup //

export const fetchCentralLookups = () => ({
  type: types.FETCH_CENTRAL_LOOKUPS,
});

export const setCentralLookups = (data) => ({
  type: types.SET_CENTRAL_LOOKUPS,
  data,
});

export const postCentralLookup = (data, resolve, reject) => ({
  type: types.POST_CENTRAL_LOOKUP,
  data,
  resolve,
  reject,
});

export const putCentralLookup = (lookupId, data, resolve, reject) => ({
  type: types.PUT_CENTRAL_LOOKUP,
  lookupId,
  data,
  resolve,
  reject,
});

export const deleteCentralLookup = (lookupId) => ({
  type: types.DELETE_CENTRAL_LOOKUP,
  lookupId,
});

// Lookup List //

export const fetchLookupList = (lookupId) => ({
  type: types.FETCH_LOOKUP_LIST,
  lookupId,
});

export const setLookupList = (data) => ({
  type: types.SET_LOOKUP_LIST,
  data,
});

export const postLookupList = (optionId, data, resolve, reject, parentId = undefined) => ({
  type: types.POST_LOOKUP_LIST,
  optionId,
  data,
  resolve,
  reject,
  parentId,
});

export const putLookupList = (itemId, data, resolve, reject) => ({
  type: types.PUT_LOOKUP_LIST,
  itemId,
  data,
  resolve,
  reject,
});

export const deleteLookupOption = (optionId, resolve, reject) => ({
  type: types.DELETE_LOOKUP_LIST,
  optionId,
  resolve,
  reject,
});

export const syncLookupWithCrm = (lookupId, resolve, reject) => ({
  type: types.SYNC_LOOKUP_WITH_CRM,
  lookupId,
  resolve,
  reject,
});
