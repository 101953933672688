import React, { useState, useCallback, useEffect } from 'react';
import moment from 'moment';
import images from 'src/config/images';
import ReactMarkdown from 'react-markdown';
import { useParams } from 'react-router-dom';
import { fetchContactResearchOutput } from '../../api/contactApis';
import { Card, CardContent, Typography, Box, Link, Stack } from '@mui/material';
import useInfiniteScroll from 'src/components/hooks/useInfiniteScroll';
import ContactResearchAndOutputsSkeleton from './ContactResearchAndOutputsSkeleton';
import styles from './styles.module.scss';
import { Empty } from 'src/components/App';

const PAGE_SIZE = 2;

const ResearchAndOutput = () => {
  const { id } = useParams();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);

  const loadMoreData = useCallback(async () => {
    if (!hasMore || loading) return;

    setLoading(true);
    try {
      const response = await fetchContactResearchOutput(id, data.length, PAGE_SIZE);
      if (response.fluids.length < PAGE_SIZE && response.total.value === data.length) {
        setHasMore(false);
      }
      setData((prevData) => [...prevData, ...response.fluids]);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  }, [id, hasMore, loading, data.length]);

  useEffect(() => {
    loadMoreData();
  }, []);

  const observerRef = useInfiniteScroll(loadMoreData, loading, hasMore);

  return (
    <Box className={styles.container}>
      {loading && data.length === 0 ? (
        <ContactResearchAndOutputsSkeleton />
      ) : (
        <Card className={styles.card}>
          <CardContent className={styles.cardContent}>
            {data.length > 0 ? (
              data.map((post) => (
                <Box key={post.id} className={styles.postContainer}>
                  <Box className={styles.postHeader}>
                    <Box className={styles.postContent}>
                      <img
                        src={
                          post.dataType === 'research'
                            ? images.icons.researchIcon
                            : images.icons.hashIcon
                        }
                        alt="avatar"
                        className={styles.imageSize}
                      />
                      <Box>
                        <Stack
                          direction="row"
                          alignItems="center"
                          justifyContent="space-between"
                          gap={2}
                        >
                          <Stack>
                            <Typography className={styles.dataTypeText}>
                              {post.dataType
                                .split('-')
                                .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                                .join(' ')}
                            </Typography>
                            <Typography variant="body1">
                              {post.title && (
                                <>
                                  {post.title}
                                  {post.title?.includes('...') && (
                                    <Link href="#" style={{ color: '#1976D2' }}>
                                      Show all
                                    </Link>
                                  )}{' '}
                                </>
                              )}
                            </Typography>
                            {/* <Typography variant="body1">
                              {post.title?.includes('...') ? (
                                <>
                                  {post.title}{' '}
                                    <Link href="#" style={{ color: '#1976D2' }}>
                                      Show all
                                    </Link>
                                </>
                              ) : (
                                <>
                                  Here's your suggestions on the post{' '}
                                  <Link href="#" style={{ color: '#1976D2' }}>
                                    provided
                                  </Link>
                                  .
                                </>
                              )}
                            </Typography> */}
                          </Stack>
                          <Typography className={styles.timestamp}>
                            <Typography className={styles.dateText}>
                              {moment(post.createdAt).clone().local().calendar().replace('at', '|')}
                            </Typography>
                            <Typography className={styles.authorText}>
                              {`By ${post.createdBy.fname} ${post.createdBy.lname}`}
                            </Typography>
                          </Typography>
                        </Stack>
                        <Box className={styles.postInfo}>
                          {post.type === 'markdown' ? (
                            <ReactMarkdown>{post.markdown}</ReactMarkdown>
                          ) : (
                            <Typography
                              variant="body2"
                              color="textPrimary"
                              sx={{ marginTop: 1, lineHeight: 1.6 }}
                            >
                              {post.markdown}
                            </Typography>
                          )}
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              ))
            ) : (
              <Empty title="No Research and Outputs Found!" />
            )}
            {hasMore && (
              <Box ref={observerRef}>{loading && <ContactResearchAndOutputsSkeleton />}</Box>
            )}
          </CardContent>
        </Card>
      )}
    </Box>
  );
};

export default ResearchAndOutput;
