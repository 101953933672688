import React from 'react';
import ErrorIcon from '@mui/icons-material/Error';
import { Box, Grid, Typography, Stack, Tooltip } from '@mui/material';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import images from '../../../../config/images';
import { useStyles } from './style';

export const emptyICPFieldList = [
  {
    label: 'Job title',
    icon: 'jobTitle',
  },
  {
    label: 'London',
    icon: 'emptyLocation',
  },
  {
    label: 'Industry',
    icon: 'industry',
  },
  {
    label: 'Tech used',
    icon: 'techUsed',
  },
  {
    label: 'Headcount',
    icon: 'companyHeadcount',
  },
  {
    label: 'Job function',
    icon: 'jobFunction',
  },
  {
    label: 'Gender',
    icon: 'gender',
  },
  {
    label: 'Seniority level',
    icon: 'seniorityLevel',
  },
  {
    label: 'Company revenue',
    icon: 'companyRevenue',
  },
  {
    label: 'Company type',
    icon: 'companyType',
  },
  {
    label: 'Pain points',
    icon: 'painPoints',
  },
  {
    label: 'Funding',
    icon: 'funding',
  },
  {
    label: 'Other info...',
    icon: 'otherInfo',
  },
];

export const iconList = [
  {
    icon: <img src={images.icp.candidateIcon} alt="" width={'24px'} height={'24px'} />,
    value: 'person',
    label: 'Candidate',
  },
  {
    icon: <img src={images.icp.customerIcon} alt="" width={'24px'} height={'24px'} />,
    value: 'organisation',
    label: 'Customer',
  },
];

export const formFields = [
  {
    fieldName: 'name',
    label: 'ICP Name',
    fieldType: 'text',
    isRequired: true,
  },
  {
    fieldName: 'profileType',
    label: 'Type',
    fieldType: 'lookup',
    lookupType: 'single',
    fieldOptions: iconList,
    isRequired: true,
  },

  {
    fieldName: 'jobTitle',
    label: 'Job Title',
    fieldType: 'lookup',
    lookupType: 'multiple',
    prompt: 'Please provide any additional instructions that will help create the email.',
    isRequired: true,
    icon: 'jobTitle',
  },
  {
    fieldName: 'industry',
    label: 'Industry',
    fieldType: 'lookup',
    lookupType: 'multiple',
    fieldOptions: null,
    prompt: 'Please provide any additional instructions that will help create the email.',
    isRequired: true,
    icon: 'industry',
  },
  {
    fieldName: 'contactLocation',
    label: 'Location',
    fieldType: 'lookup',
    lookupType: 'multiple',
    fieldOptions: null,
    prompt: 'Please provide any additional instructions that will help create the email.',
    isRequired: true,
    icon: 'location',
    placeholder: 'Enter your location to search',
  },
];

export const fieldList = [
  {
    key: 'jobTitle',
    label: 'Job Title',
    icon: 'jobTitle',
  },
  {
    key: 'contactLocation',
    label: 'London',
    icon: 'emptyLocation',
  },
  {
    key: 'industry',
    label: 'Industry',
    icon: 'industry',
  },
  {
    key: 'techUsed',
    label: 'Tech used',
    icon: 'techUsed',
  },
  {
    key: 'jobFunction',
    label: 'Job function',
    icon: 'jobFunction',
  },
  {
    key: 'gender',
    label: 'Gender',
    icon: 'gender',
  },
  {
    label: 'Seniority level',
    icon: 'seniorityLevel',
    key: 'seniorityLevel',
  },
  {
    label: 'Technology Used',
    icon: 'technologyUsed',
    key: 'technologyUsed',
  },
  {
    label: 'Company revenue',
    icon: 'companyRevenue',
    key: 'companyRevenue',
  },
  {
    label: 'Company Headcount',
    icon: 'companyHeadcount',
    key: 'companyHeadcount',
  },
  {
    label: 'Company type',
    icon: 'companyType',
    key: 'companyType',
  },
  {
    label: 'Pain Points',
    icon: 'painPoints',
    key: 'painPoints',
  },
  {
    label: 'Funding',
    icon: 'funding',
    key: 'funding',
  },
  {
    label: 'Other Info...',
    icon: 'otherInfo',
    key: 'otherInfo',
  },
];

export const cardLabelList = ['jobTitle', 'contactLocation', 'industry'];

export const getBackGroundColor = (type) => {
  return type === 'Candidate'
    ? 'linear-gradient(106deg, #F494F6 1.11%, #A87FFE 99.93%) !important'
    : 'linear-gradient(106deg, #EED17D 1.11%, #F64D33 99.93%) !important';
};

export const FieldError = ({ error }) => (
  <Grid item xs={12} mt="8px" container alignItems="center">
    <ErrorIcon sx={{ color: '#D32F2F !important', fontSize: '24px' }} />
    <Typography sx={{ fontSize: '14px', fontWeight: '400', color: '#D32F2F', marginLeft: '5px' }}>
      {error}
    </Typography>
  </Grid>
);

export const FieldIconLabel = ({ item, errors }) => {
  const classes = useStyles();

  return (
    <Box display={'flex'} gap="8px">
      {item?.icon && <img src={images.icp?.[item?.icon]} width="24px" height="24px" alt="" />}
      <Typography
        className={classes.label}
        sx={{
          color: !!errors[item.fieldName] ? '#D32F2F !important' : '#000000DE !important',
          fontSize: '16px !important',
          fontWeight: '500 !important',
          lineHeight: '26px !important',
        }}
      >
        {item.label} {item?.isRequired ? '*' : ''}
      </Typography>
    </Box>
  );
};

export const removeNullValues = (obj) => {
  const keysToRemove = [
    'isDeleted',
    'name',
    'tenant',
    'updatedAt',
    'user',
    'createdAt',
    'createdBy',
    'updatedBy',
    'status',
    'permission',
    'creatorType',
    'id',
  ];

  return Object.fromEntries(
    Object.entries(obj).filter(([key, value]) => value && !keysToRemove.includes(key)),
  );
};

export const ModalTitle = ({ getModalTitle }) => {
  return (
    <Box display={'flex'} gap={'8px'} alignItems="center">
      <Box display={'flex'} gap={'16px'} alignItems="center">
        <img src={images.icp.icpLogo} alt="" width={32} height={32} />
        <div>{getModalTitle}</div>
      </Box>
      <Tooltip
        title={
          <Stack padding={'14px'} maxWidth={'372px'}>
            <Typography fontSize={'12px'} fontWeight={500}>
              An ICP defines your ideal customer to boost conversions and personalise outreach.
            </Typography>
          </Stack>
        }
        arrow
        placement={'bottom'}
        componentsProps={{
          tooltip: {
            sx: {
              bgcolor: '#082037',
              '& .MuiTooltip-arrow': {
                color: '#082037',
              },
            },
          },
        }}
      >
        <ErrorOutlineOutlinedIcon sx={{ fontSize: '24px', color: '#0000008a !important' }} />
      </Tooltip>{' '}
    </Box>
  );
};

export const getFieldValue = (fieldValue) => {
  if (Array.isArray(fieldValue)) {
    const firstValue = fieldValue[0];
    if (typeof firstValue === 'object') {
      return firstValue?.value || firstValue?.label;
    }
    return typeof firstValue === 'string' ? firstValue : '';
  }

  if (Array.isArray(fieldValue?.value)) {
    const firstValue = fieldValue.value[0];
    if (typeof firstValue === 'object') {
      return firstValue?.value || firstValue?.label;
    }
    return typeof firstValue === 'string' ? firstValue : '';
  }

  if (typeof fieldValue === 'object') {
    if (fieldValue?.value || fieldValue?.label) return fieldValue?.value || fieldValue?.label;
    else return '';
  }

  if (Array.isArray(fieldValue)) {
    const firstValue = fieldValue[0];
    if (typeof firstValue === 'object') {
      return firstValue?.value || firstValue?.label;
    }
    return typeof firstValue === 'string' ? firstValue : '';
  }

  return typeof fieldValue === 'string' ? fieldValue : '';
};

export const getFieldValueForMoreDetail = (fieldValue) => {
  let value;
  if (Array.isArray(fieldValue)) {
    const firstValue = fieldValue[0];
    if (firstValue && typeof firstValue === 'object') {
      value = fieldValue?.map((item) => item?.value || item?.label);
    } else {
      value = fieldValue;
    }
  }

  if (Array.isArray(fieldValue?.value)) {
    const firstValue = fieldValue.value[0];
    if (firstValue && typeof firstValue === 'object') {
      value = fieldValue?.map((item) => item?.value || item?.label);
    } else {
      value = fieldValue;
    }
  }

  if (typeof fieldValue === 'object' && (fieldValue?.value || fieldValue?.label)) {
    value = fieldValue?.value || fieldValue?.label;
  }

  if (Array.isArray(fieldValue)) {
    const firstValue = fieldValue[0];
    if (firstValue && typeof firstValue === 'object') {
      value = fieldValue?.map((item) => item?.value || item?.label);
    } else {
      value = fieldValue;
    }
  }

  if (!value) {
    value = fieldValue;
  }
  return value;
};
