import React, { useState, useMemo } from 'react';
import { Alert, Box, Grid, Paper } from '@mui/material';
import { Tabs } from 'src/components/App';
import { Spacer } from 'src/components/shared';
import { ContactSidebar, ContactMainView } from './styles';

import Contact from '../../components/Contact';
import ContactTasks from '../../components/ContactTasks';
import ContactActivities from '../../components/ContactActivities';
import Sequence from '../../components/Sequiences';
import ResearchAndOutput from '../../components/ResearchAndOutputs';
import useFeatureAccess from 'src/components/hooks/useFeatureAccess';
import { FeatureCaseKeys } from 'src/config/featureCase';

function ContactDetailsView({
  contact,
  sequenceEnrollment,
  contactTask,
  contactNote,
  isLoading,
  ...props
}) {
  const [tabIndex, setTabIndex] = useState(0);
  const [tab, setTab] = useState({ id: 'activity' });
  const scratchpadEnabled = useFeatureAccess(FeatureCaseKeys.SCRATCHPAD_ENABLED);

  const tabs = useMemo(
    () => [
      {
        name: 'Activity',
        id: 'activity',
      },
      {
        name: 'Sequences',
        id: 'sequences',
      },
      ...(scratchpadEnabled
        ? [
            {
              id: 'research&outputs',
              name: 'Research & Outputs',
            },
          ]
        : []),
    ],
    [scratchpadEnabled],
  );

  const onTabChange = (tab, index) => {
    setTab(tab);
    setTabIndex(index);
  };
  const isUnsubscribed = contact.status === 'unsubscribed';

  return (
    <Grid container>
      <ContactSidebar>
        <Contact
          contact={contact}
          sequenceEnrollment={sequenceEnrollment}
          isLoading={isLoading}
          showBack={true}
          {...props}
        />
      </ContactSidebar>
      <ContactMainView>
        <Box>
          {isUnsubscribed && (
            <Alert severity="error">
              This contact no longer want to be contacted
              {props?.tenant?.name ? ' by ' + props.tenant.name : ''}. They have expressed this by
              unsubscribing from your emails. No further contact can be made with them
            </Alert>
          )}
          <Tabs tabs={tabs} onChange={onTabChange} value={tab.id} />
          <Spacer y={0.5} />
          {tab.id === 'sequences' ? (
            <>
              <Sequence sequenceEnrollment={sequenceEnrollment} {...props} />
            </>
          ) : tab.id === 'activity' ? (
            <>
              <ContactTasks contactTask={contactTask} {...props} />
              <Spacer y={1} />
              <ContactActivities contactNote={contactNote} contact={contact} {...props} />
              <Spacer y={3} />
            </>
          ) : tab.id === 'research&outputs' ? (
            <ResearchAndOutput />
          ) : null}
        </Box>
      </ContactMainView>
    </Grid>
  );
}

export default ContactDetailsView;
