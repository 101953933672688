import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import Form from 'src/components/shared/Form';

const charLimitForSubject = {
  linkedinMail: 200,
};

const AIContentView = ({
  formType,
  options,
  withUpload,
  editorProps = {},
  hideSubject,
  setOpenAIWriter,
  showAiWriter,
  placeHolder,
  height,
  formProps,
  linkedinType,
}) => {
  const [subjectValue, setSubjectValue] = useState('');

  useEffect(() => {
    setSubjectValue(formProps?.values?.subject || '');
  }, [formProps?.values?.subject]);

  const setPromptEmailContent = async (content) => {
    if (content.length > 1) {
      const subject = content[0]?.innerText?.replace('Subject: ', '');
      const body = content[1]?.innerText?.replace('Content: ', '');
      formProps?.setFieldValue('subject', subject ?? '');
      formProps?.setFieldValue('description', body);
      setSubjectValue(subject);
      return body;
    } else {
      const body = content[0]?.innerText;
      formProps?.setFieldValue('description', body);
      return body;
    }
  };

  return (
    <>
      <Box className={'ai-content-view'} sx={{ mt: 2 }}>
        {hideSubject ? null : (
          <Form.Field.Input
            fullWidth
            variant="outlined"
            hideHelperText
            name="subject"
            label="Subject"
            showPicker={true}
            options={options}
            optValue="value"
            optLabel="text"
            isSmall
            emojiPicker={true}
            size={'small'}
            value={subjectValue}
            sx={{
              background: '#F5F4F2',
            }}
            onChange={(value) => {
              if (
                linkedinType &&
                Object(charLimitForSubject).hasOwnProperty(linkedinType) &&
                value.length > charLimitForSubject[linkedinType]
              ) {
                const newValue = value.substring(0, charLimitForSubject[linkedinType]);
                setSubjectValue(newValue);
                formProps?.setFieldValue('subject', newValue);
              } else {
                setSubjectValue(value);
                formProps?.setFieldValue('subject', value);
              }
            }}
          />
        )}

        {withUpload ? (
          <Form.Field.File
            formType={formType}
            fullWidth
            showTextEditor
            variant="outlined"
            name="attachments"
            label="Files"
            hideButton
            showAiWriter={showAiWriter}
            setOpenAIWriter={setOpenAIWriter}
            placeHolder={placeHolder}
            handlePaste={setPromptEmailContent}
            isSetup
          />
        ) : (
          <Form.Field.TextEditor
            formType={formType}
            handleAIWriteOnClick={() => setOpenAIWriter(true)}
            pasteAsText={true}
            placeHolder={placeHolder}
            height={height}
            handlePaste={setPromptEmailContent}
            {...editorProps}
            isSetup
          />
        )}
      </Box>
    </>
  );
};
export default AIContentView;
