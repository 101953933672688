import React, { useEffect, useRef } from 'react';

const useInfiniteScroll = (onLoadMore, isLoading, hasMore) => {
  const observerRef = useRef(null);

  useEffect(() => {
    if (isLoading || !hasMore) return;

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          onLoadMore();
        }
      },
      { rootMargin: '100px' },
    );

    if (observerRef.current) observer.observe(observerRef.current);

    return () => {
      if (observerRef.current) observer.disconnect();
    };
  }, [isLoading, hasMore, onLoadMore]);

  return observerRef;
};

export default useInfiniteScroll;
