import React, { useState } from 'react';
import { Box } from '@mui/material';
import { useStyles } from '../../containers/integrations/styles';
import TeamsGrid from '../../components/Teams/TeamsGrid';
import { Drawer } from 'src/components/shared';
import { useLocation } from 'react-router-dom';
import TeamForm from '../../components/Teams/TeamForm';
import CompanyTeamForm from '../ManageCompany/CompanyTeamForm';

function TeamsView({ teams, users, total, userTotal, ...props }) {
  const location = useLocation();
  const [drawerOpen, setDrawerOpen] = useState({ edit: null, open: false });

  const toggleEditTeam = (value, tableMeta, updateValue, selectedContact = 0) => {
    setEditOptions({
      id: tableMeta.rowData[0],
      drawerTitle: 'Edit Teams',
      status: tableMeta.rowData[6],
      toggleOpen: true,
    });
  };

  React.useEffect(() => {
    const query = new URLSearchParams(location?.search);
    const openCreateTeamModal = query.get('openCreateTeam');
    if (openCreateTeamModal) {
      setDrawerOpen({ open: true });
      query.delete('openCreateTeam');
      const newSearch = query.toString();
      const newUrl = `${location.pathname}${newSearch ? `?${newSearch}` : ''}`;
      window.history.replaceState(null, '', newUrl);
    }
  }, [location?.search]);

  return (
    <>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Drawer
          title={drawerOpen.edit ? 'Edit Team' : 'Create Team'}
          open={drawerOpen.open}
          onClose={() => {
            setDrawerOpen({ edit: null, open: false });
          }}
        >
          {drawerOpen.open &&
            (props?.isTeamView ? (
              <CompanyTeamForm
                teamLead={users}
                editUser={drawerOpen.edit}
                drawerToggle={setDrawerOpen}
                loading={props.loading}
                total={total}
                userTotal={userTotal}
                isTeamView={props.isTeamView}
                {...props}
              />
            ) : (
              <TeamForm
                teamLead={users}
                editUser={drawerOpen.edit}
                drawerToggle={setDrawerOpen}
                loading={props.loading}
                total={total}
                userTotal={userTotal}
                {...props}
              />
            ))}
        </Drawer>
      </Box>
      <TeamsGrid
        drawerToggle={setDrawerOpen}
        teams={teams}
        teamBulkUpdate={props.teamBulkUpdate}
        updateTeamStatus={props.updateTeamStatus}
        fetchTeams={props.fetchTeams}
        globals={props.globals}
        loading={props.loading}
      />
    </>
  );
}

export default TeamsView;
