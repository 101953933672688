import React, { useEffect, useState } from 'react'

import { useTheme } from '@mui/styles';
import GatedModal from 'src/modules/app/components/OutreachOnBoarding/GatedModal';
import { Stack, Typography } from '@mui/material';
import { Button } from 'src/components/shared';
import Icon from 'src/components/lms/Icon';
import images from 'src/config/images';
import { CustomGatedFooterContent, CustomGatedMainContent } from 'src/modules/app/components/OutreachOnBoarding/styles';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentUser } from 'src/modules/auth/actions/authActions';
import { setOutreachBoarding } from 'src/modules/app/actions/appActions';
import { updateUser } from 'src/modules/auth/api/authApis';
import { useGuideModalStyles } from './styles';

const Onboarding = ({
    onboardinCarousel = 'crmCarousel',
    onboardingModal = 'crmModal',
    onboardingguideSteps = 'crmguideSteps',
    imageStep = 'crmStep',
    guideStepList = []
}) => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const user = useSelector((state) => state.auth.user);
    const guideModalStyle = useGuideModalStyles();
    const [currentStep, setCurrentStep] = useState(0);
    const currentGuide = guideStepList[currentStep];
    const isShowWelcomeCarousel = useSelector((state) => state.app?.outreachBoarding?.[onboardinCarousel]);
    const crmModal = user?.outreachOnboarding?.[onboardingModal] || false;
    const [crmguideSteps, setCrmguideSteps] = useState(user?.outreachOnboarding?.[onboardingguideSteps] || {})


    // const crmguideSteps = useMemo(()=>{

    // },[user?.outreachOnboarding?.[onboardingguideSteps]])


    // useEffect(() => {
    //     setModal()
    // }, [])

    // const setModal = async () => {
    //     let isCrmModal = {
    //         [onboardingModal]: false,
    //         [onboardingguideSteps]: {}
    //     }
    //     const res = await updateUser({
    //         id: user.id,
    //         data: {
    //             outreachOnboarding: {
    //                 ...(user?.outreachOnboarding || {}),
    //                 ...isCrmModal,
    //             },
    //         },
    //     });
    //     dispatch(setCurrentUser({ userData: res }));
    // }

    useEffect(() => {
        setCurrentStep(0)
    }, [isShowWelcomeCarousel]);

    useEffect(() => {
        if (crmModal && !crmguideSteps) {
            dispatch(setOutreachBoarding({ [onboardinCarousel]: true }))
        } else if (!crmModal) {
            dispatch(setOutreachBoarding({ [onboardinCarousel]: true }))
        }
        if (user?.outreachOnboarding?.[onboardingguideSteps]) {
            setCrmguideSteps(user?.outreachOnboarding?.[onboardingguideSteps])
        }
    }, [user?.outreachOnboarding]);

    const handleLmsBoarding = async (isSkip, isClose) => {
        let isCrmModal = {
            [onboardingModal]: false
        };

        if (isClose) {
            isCrmModal = {
                [onboardingModal]: true
            };
        }
        if (!isSkip) {
            try {
                setCrmguideSteps((prev) => ({
                    ...prev,
                    [currentGuide.key]: true,
                }))
                setCurrentStep((prev) => (prev < guideStepList.length - 1 ? prev + 1 : prev));
            } catch (error) {
                console.error(error);
            }
        } else {
            setCurrentStep((prev) => (prev < guideStepList.length - 1 ? prev + 1 : prev));
        }
    };


    const handleClose = async (e, isNext = false, skip = false) => {
        const isSkip =
            crmguideSteps &&
            (crmguideSteps[currentGuide.key] || Object.keys(crmguideSteps).length === guideStepList.length)
        if (!isNext) {
            let isCrmModal = {
                [onboardingModal]: true
            }
            dispatch(setOutreachBoarding({ isLoading: true }));
            dispatch(setOutreachBoarding({ [onboardinCarousel]: false }));
            const res = await updateUser({
                id: user.id,
                data: {
                    outreachOnboarding: {
                        ...(user?.outreachOnboarding || {}),
                        ...isCrmModal,
                        [onboardingguideSteps]: {
                            ...crmguideSteps,
                            [currentGuide.key]: true,
                        }
                    },
                },
            });
            dispatch(setOutreachBoarding({ isLoading: false }))
            dispatch(setCurrentUser({ userData: res }));

        }
        handleLmsBoarding(isSkip, !isNext);
    }

    const handleNextStep = async () => {
        await handleClose(null, currentStep + 1 < guideStepList.length);
    }
    return (
        <GatedModal
            open={isShowWelcomeCarousel}
            onCloseModal={() => handleClose("", "", true)}
            className={guideModalStyle.root}
            renderHeader={
                currentGuide.heading && (
                    <Stack flexDirection="row" alignItems="center" gap={2}>
                        <Icon name={currentGuide.icon} />
                        <Typography variant="h2" fontWeight={600} color={theme.palette.text.black1}>
                            {currentGuide.heading}
                        </Typography>
                    </Stack>
                )
            }
            renderFooter={
                <CustomGatedFooterContent>
                    <Stack flexDirection="row" alignItems="center" justifyContent="space-between" flex={1}>
                        <Typography variant="subtitle2" color={theme.palette.text.grey}>
                            {currentStep + 1} of {guideStepList.length}
                        </Typography>

                        <Stack flexDirection="row" gap={2}>
                            <Button variant="text" color="primary" onClick={() => handleClose("", "", true)}>
                                <Typography fontSize="inherit" color={theme.palette.text.black1}>
                                    Skip
                                </Typography>
                            </Button>

                            <Button variant="contained" color="primary" onClick={() => handleNextStep()}>
                                {currentStep < guideStepList.length - 1 ? 'Next' : 'Done'}
                            </Button>
                        </Stack>
                    </Stack>
                </CustomGatedFooterContent>
            }
            image={images.onboarding[`${imageStep}${currentStep}`]}
        >
            <CustomGatedMainContent>
                <Stack gap={2}>
                    <Typography className="title" color={theme.palette.text.black1}>
                        {currentGuide.title}
                    </Typography>
                    <Typography color={theme.palette.text.black1}>{currentGuide.description}</Typography>
                </Stack>
            </CustomGatedMainContent>
        </GatedModal>
    )
}

export default Onboarding