import React, { useEffect } from 'react';
import * as Yup from 'yup';
import { Box, Grid, Typography } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import { Form, Button } from 'src/components/shared';
import { useTheme } from '@mui/styles';
import useRequest from 'src/components/hooks/useRequest';
import { fetchGlobals } from 'src/modules/app/api/appApis';
import { useSelector } from 'react-redux';
import { getCompanyWebsite } from '../../api/aiServicesApi';

const DomainDetailsForm = ({ onSubmit, loading }) => {
  const domainFormRef = React.useRef(null);
  const theme = useTheme();
  const journey = useSelector((state) => state.app.outreachBoarding?.journey);

  const [globalConfig] = useRequest(fetchGlobals);
  const aiServiceToken = globalConfig?.data?.[0].aiServiceToken || '';

  const [response, { loading: websiteLoading }] = useRequest(
    getCompanyWebsite,
    {
      name: journey.companyName ?? '',
      token: aiServiceToken,
    },
    {
      skip: !journey.companyName || !aiServiceToken,
    },
  );

  const company = response?.data ?? {};

  const companyProfile = company.profile ?? {};

  useEffect(() => {
    if (companyProfile.website && domainFormRef.current) {
      domainFormRef.current.setValues({
        domain: companyProfile.website ?? '',
        companyLinkedInProfile: company.url ?? '',
        companyOverview: companyProfile.description ?? '',
        companyType: companyProfile.company_type ?? '',
        companySpecialities: companyProfile.specialities?.join(', ') ?? '',
        followers: companyProfile.follower_count?.toString() ?? '',
        industry: companyProfile.industry ?? '',
      });
    }
  }, [companyProfile.website]);

  return (
    <Form
      innerRef={domainFormRef}
      initialValues={{
        domain: '',
        companyLinkedInProfile: '',
        companyOverview: '',
        companyType: '',
        companySpecialities: '',
        followers: '',
      }}
      validationSchema={Yup.object().shape({
        domain: Yup.string()
          .required('Please enter your domain.')
          .matches(
            /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
            'Enter correct domain',
          ),
      })}
      onSubmit={onSubmit}
      enableReinitialize
      validateOnChange={false}
    >
      {(props) => {
        return (
          <form
            onSubmit={(e) => {
              e.preventDefault();
              props.submitForm();
              return false;
            }}
            noValidate
          >
            <Grid container xs={12} spacing={2}>
              <Grid xs={12} item>
                <Typography color={theme.palette.text.black1} mb={2}>
                  Is this your company website?
                </Typography>
                <Form.Field.Input
                  fullWidth
                  page="auth"
                  variant="outlined"
                  name="domain"
                  sx={{ marginTop: 0 }}
                  errorBorder
                  placeholder={websiteLoading ? 'Loading...' : 'Enter domain'}
                  disabled={websiteLoading}
                  hideAsterisk={websiteLoading}
                />
              </Grid>
              <Grid xs={12} item>
                <Typography color={theme.palette.text.grey}>
                  If we’re got it wrong, please edit and confirm! We use this information to
                  personalise your SuperReach experience.
                </Typography>
                <Box mt={2}>
                  <Button
                    variant="contained"
                    color="secondary"
                    type="submit"
                    loading={loading}
                    disabled={!props?.isValid || !props?.dirty}
                    fullWidth
                    size="large"
                    blackButton
                    endIcon={<ArrowForwardIcon />}
                  >
                    Confirm
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </form>
        );
      }}
    </Form>
  );
};

export default DomainDetailsForm;
