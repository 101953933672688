import React from 'react';
import styled from 'styled-components';
import Skeleton from '@mui/material/Skeleton';
import { Accordion, AccordionSummary, AccordionDetails, Typography, Box } from '@mui/material';

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
`;

export const SummaryWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 200px;
`;

function ContactResearchAndOutputsSkeleton() {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      <Accordion expanded={true}>
        <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
          <Typography>
            <SummaryWrapper>
              <Skeleton animation="wave" height={28} width={'40%'} />
            </SummaryWrapper>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <ContentWrapper>
            {[1, 2, 3].map((_, index) => (
              <Box key={index} sx={{ width: '100%', mb: 2 }}>
                <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                  <Skeleton variant="circular" width={32} height={32} />
                  <Box sx={{ flex: 1 }}>
                    <Skeleton width="30%" height={20} />
                    <Skeleton width="80%" height={24} sx={{ mt: 1 }} />
                    <Skeleton width="100%" height={100} sx={{ mt: 1 }} />
                  </Box>
                </Box>
              </Box>
            ))}
          </ContentWrapper>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}

ContactResearchAndOutputsSkeleton.propTypes = {};

export default ContactResearchAndOutputsSkeleton;
