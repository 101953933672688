export const billingTabs = [
  {
    id: 'details',
    name: 'Details',
  },
  {
    id: 'subscriptions',
    name: 'Subscriptions and Services',
  },
  {
    id: 'invoices',
    name: 'Invoices',
  },
];

export const manageCompanyTabs = [
  {
    id: 'users',
    name: 'Users',
  },
  {
    id: 'teams',
    name: 'Teams',
  },
  {
    id: 'company-profile',
    name: 'Company Profile',
  },
];

export const configTabsTypes = {
  workEmailFlow: 'Work email',
  personalEmailFlow: 'Personal email',
  mobileFlow: 'Phone number',
};

export const waterFallTabs = [
  {
    id: 'workEmail',
    name: 'Work email waterfall',
  },
  {
    id: 'personalEmail',
    name: 'Personal email waterfall',
  },
  {
    id: 'phone',
    name: 'Phone number waterfall',
  },
];

export const resellerTabs = [
  {
    id: 'dashboard',
    name: 'Resellers',
  },
  {
    id: 'automation',
    name: 'Automate',
  },
];

export const dataManagementTabs = [
  {
    id: 'summary',
    name: 'Data summary',
  },
  // {
  //   id: 'management',
  //   name: 'Data management',
  // },
  {
    id: 'waterfall',
    name: 'Data waterfall',
  },
];

export const templateTabs = [
  {
    id: 'email',
    name: 'Email templates',
  },
  {
    id: 'linkedin',
    name: 'LinkedIn templates',
  },
];

export const fieldScopes = [
  // {
  //   label: 'All',
  //   value: 'all',
  // },
  {
    label: 'Contacts',
    value: 'contacts',
  },
  {
    label: 'Sequences',
    value: 'sequences',
  },
  {
    label: 'Tasks',
    value: 'tasks',
  },
];
export const fieldTypes = [
  {
    label: 'Text',
    value: 'text',
  },
  {
    label: 'HTML Text',
    value: 'textEditor',
  },
  {
    label: 'Single Select',
    value: 'lookup',
  },
  {
    label: 'Multiple Select',
    value: 'lookup-multiple',
  },
  {
    label: 'Numerical',
    value: 'numeric',
  },
  {
    label: 'Currency',
    value: 'currency',
  },
  {
    label: 'Date',
    value: 'date',
  },
  {
    label: 'Date Time',
    value: 'datetime',
  },
  {
    label: 'Time',
    value: 'time',
  },
  // {
  //   label: 'Formula',
  //   value: 'formula',
  // },
  // {
  //   label: 'Multi Select',
  //   value: 'multiSelect',
  // },
  // {f
  //   label: 'Dropdown',
  //   value: 'dropdown',
  // },

  {
    label: 'User (single)',
    value: 'user',
  },
  {
    label: 'User (multiple)',
    value: 'user-multiple',
  },
  {
    label: 'Contact (single)',
    value: 'contact',
  },
  {
    label: 'Contact (multiple)',
    value: 'contact-multiple',
  },
  {
    label: 'Media',
    value: 'upload',
  },
];

export const customFieldsTabs = [
  {
    id: 'contacts',
    name: 'Contacts',
  },
  {
    id: 'tasks',
    name: 'Tasks',
  },
  {
    id: 'sequences',
    name: 'Sequences',
  },
];

const fields = [
  {
    id: 1,
    type: 'default',
    label: 'Skills',
    fieldName: 'skills',
    fieldType: 'text',
  },
  {
    id: 2,
    type: 'default',
    label: 'First Name',
    fieldName: 'firstName',
    fieldType: 'text',
  },
  {
    id: 3,
    type: 'default',
    label: 'Last Name',
    fieldName: 'lastName',
    fieldType: 'text',
  },
  {
    id: 4,
    type: 'default',
    label: 'Email',
    fieldName: 'email',
    fieldType: 'text',
  },
];

export const disabledFields = ['fname', 'lname', 'jobTitle', 'account'];
