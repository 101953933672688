import React, { useMemo, useState } from 'react';
import * as Yup from 'yup';
import { useSelector } from 'react-redux';
import { Box, Link, Typography } from '@mui/material';
import { Button, Form, Modal, Spacer } from 'src/components/shared';
import { isEmpty } from 'lodash';
import { Stack } from '@mui/material';
import { formatCurrency } from '../../../../utils/helper';

function CreditRecharge({
  open,
  creditRechargeType,
  subscriptions,
  onClose,
  initiatePayment,
  loading,
  tenant,
}) {
  const [costPerUnit, setCostPerUnit] = useState('');
  const plans = useSelector((state) => state.app.globals?.charges ?? []);
  const data = subscriptions?.creditsPurchased?.[creditRechargeType] ?? {};

  const dataPlans = useMemo(() => {
    if (plans && !isEmpty(plans)) {
      return plans.data.plans.map((item) => {
        return {
          ...item,
          name: `${item.credits} credits for $${item.price}`,
        };
      });
    }
  }, [plans]);

  const roleplayPlans = useMemo(() => {
    if (plans && !isEmpty(plans)) {
      return plans.roleplay.plans.map((item) => {
        return {
          ...item,
          name: `${item.credits} ai coach minutes for $${item.price}`,
        };
      });
    }
  }, [plans]);

  const handleSubmit = (values) => {
    initiatePayment(values.plan, undefined, true);
  };

  const onChangePlan = (selectedPlanId) => {
    const currentPlans = plans?.[creditRechargeType]?.plans ?? [];
    const selectedPlan = currentPlans?.find(({ planId }) => planId === selectedPlanId) ?? {};
    setCostPerUnit(selectedPlan?.costPerUnit ?? '');
  };

  return (
    <Form
      initialValues={{
        plan: '',
      }}
      validationSchema={Yup.object().shape({
        plan: Yup.string().required('Please choose a plan!'),
      })}
      onSubmit={handleSubmit}
    >
      {(formProps) => {
        return (
          <Modal
            open={open}
            title={
              <Stack gap={1}>
                <Typography
                  fontSize="18px"
                  fontWeight="500"
                  sx={{ fontFamily: 'Roboto !important' }}
                >
                  {creditRechargeType === 'roleplay' ? 'Buy AI Coach minutes' : 'Add Funds'}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {creditRechargeType === 'roleplay'
                    ? 'Add AI Coach minutes to your company account to keep using Ai role-plays. '
                    : 'Add funds to use your AI services across the SuperReach platform.'}
                </Typography>
              </Stack>
            }
            onClose={() => {
              onClose();
              setCostPerUnit('');
            }}
          >
            <Box display={'flex'} flexDirection={'column'}>
              <Typography
                variant="body1"
                color="textSecondary"
                sx={{ color: 'rgba(0, 0, 0, 0.87)' }}
              >
                You currently have{' '}
                <span style={{ fontWeight: '700' }}>
                  {creditRechargeType === 'data'
                    ? `$${formatCurrency(tenant?.credits)}`
                    : tenant?.roleplayMinutes > 0
                    ? Math.round(tenant?.roleplayMinutes)
                    : 0}{' '}
                  {creditRechargeType !== 'data' && 'minutes'}
                </span>{' '}
                remaining
              </Typography>

              {creditRechargeType === 'roleplay' && (
                <Form.Field.Select
                  options={roleplayPlans}
                  onChange={onChangePlan}
                  optValue="planId"
                  optLabel="name"
                  variant="outlined"
                  label="Add minutes"
                  placeholder="Choose minute batch..."
                  name="plan"
                  fullWidth
                />
              )}

              {creditRechargeType === 'data' && (
                <Box mt={4}>
                  <Typography sx={{ marginBottom: -1 }}>Add funds</Typography>
                  <Form.Field.Select
                    options={dataPlans}
                    onChange={onChangePlan}
                    optValue="planId"
                    optLabel="name"
                    variant="outlined"
                    placeholder="Choose amount..."
                    name="plan"
                    fullWidth
                    size="small"
                  />
                </Box>
              )}
              <Box display="flex" justifyContent="flex-end" alignItems="flex-end" mt={2} mb={-2}>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={formProps.submitForm}
                  loading={loading}
                >
                  Confirm
                </Button>
              </Box>
            </Box>
          </Modal>
        );
      }}
    </Form>
  );
}

export default CreditRecharge;
