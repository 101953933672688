import React, { useMemo } from 'react';
import images from 'src/config/images';
import { Dialog, DialogContent, IconButton, Stack, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';

const SuccessModal = ({ open, planId }) => {
  const allPlans = useSelector((state) => state.app.globals?.charges ?? []);

  const dataPlans = useMemo(() => {
    if (allPlans && !isEmpty(allPlans)) {
      return allPlans.data.plans.map((item) => {
        return {
          ...item,
          name: `${item.credits} credits for $${item.price}`,
        };
      });
    }
  }, [allPlans]);

  const plan = useMemo(() => {
    if (dataPlans && !isEmpty(dataPlans)) {
      return dataPlans.find((item) => item.planId === planId);
    }
    return {};
  }, [dataPlans, planId]);

  const onClose = () => {
    window.location.reload();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <div style={{ position: 'absolute', top: 8, right: 8 }}>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </div>
      <DialogContent sx={{ padding: '40px 80px' }}>
        <Stack direction="column" alignItems="center" justifyContent="center" spacing={2}>
          <img src={images.billing.success} alt="success" />
          <Typography variant="h2">Success!</Typography>
          <Typography variant="h3">
            ${plan?.credits || 0} has been added to your pay as you go account.
          </Typography>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default SuccessModal;
