import React from 'react';
import { Grid, Box, Typography, Paper, Link, Stack, Chip } from '@mui/material';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import { styled } from '@mui/material/styles';
import { Button, Form, Spacer } from 'src/components/shared';
import CancelIcon from '@mui/icons-material/Cancel';
import { useState } from 'react';
import toast from 'src/utils/toast';

export default function BlockedDomains({ blockedDomains, id, ...props }) {
  const [blockedDomain, setBlockedDomain] = useState('');
  const handleSubmit = async (values, form) => {
    const data = { ...values };
    blockedDomains.push(data.blockedDomains);
    data.id = id || '';
    await props.updateSettings({ blockedDomains: blockedDomains }, id);
  };
  return (
    <>
      <Form
        initialValues={{
          blockedDomains: '',
          updateSettings: props.updateSettings,
        }}
        validationSchema={Yup.object().shape({})}
        enableReinitialize={true}
        onSubmit={handleSubmit}
        validateOnBlur={true}
        validateOnChange={false}
      >
        {(props) => {
          return (
            <form
              onSubmit={(e) => {
                e.preventDefault();
                if (!blockedDomain?.trim()) {
                  toast.error(`Please add valid domain name`);
                  return false;
                }
                props.submitForm();
                return false;
              }}
              style={{ height: '100%' }}
              noValidate
            >
              <Box>
                <Grid container>
                  <Grid item xs={6}>
                    <Form.Field.Input
                      fullWidth
                      size="small"
                      rows={4}
                      variant="outlined"
                      name="blockedDomains"
                      onChange={setBlockedDomain}
                      label="Add domains"
                      tip="Add the domain and hit enter, for example: gmail.com, outlook.com"
                    />
                  </Grid>
                  <Grid item xs={4} p={2}>
                    <Button
                      variant="contained"
                      color="secondary"
                      type="submit"
                      loading={false}
                      disabled={!blockedDomain}
                    >
                      UPDATE
                    </Button>
                  </Grid>
                </Grid>
              </Box>
              <Spacer y={1} />
              <Stack direction="row" spacing={1}>
                {blockedDomains &&
                  blockedDomains.map((btn, key) => (
                    <Stack direction="row" spacing={2}>
                      <Chip
                        label={btn}
                        color="secondary"
                        onDelete={() => 
                          props.values.updateSettings(
                            {
                              blockedDomains: blockedDomains.filter((domain) => {
                                return domain !== btn;
                              }),
                            },
                            id,
                          )
                        }
                      />
                    </Stack>
                  ))}
              </Stack>
            </form>
          );
        }}
      </Form>
    </>
  );
}
