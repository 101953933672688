import React, { useMemo } from 'react';
import { PageTitle, Tabs } from 'src/components/App';
import { Spacer } from 'src/components/shared';
import { manageCompanyTabs } from '../../config';
import UsersContainer from '../users';
import TeamsContainer from '../teams';
import CompanyProfile from './CompanyProfile';
import Onboarding from '../../components/OnboardingCrm';
import { teamGuideStepList } from '../../components/OnboardingCrm/config';

export const CompanyContainer = (props) => {
  const activeTab = useMemo(() => {
    return props?.match?.params?.tab || 'users';
  }, [props]);

  const onTabChange = (tab) => {
    props.history.push(`/admin/manage-company/${tab.id}`);
  };

  return (
    <>
      <PageTitle title={`Manage Company`} />
      <>
        <Tabs tabs={manageCompanyTabs} onChange={onTabChange} value={activeTab} />
        <Spacer y={2} />
        {activeTab === 'users' && <UsersContainer />}
        {activeTab === 'teams' && <TeamsContainer isTeamView={true} />}
        {activeTab === 'company-profile' && <CompanyProfile />}
      </>
      <Onboarding
        onboardinCarousel={"manageCompanyCarousel"}
        onboardingModal={"manageCompanyModal"}
        onboardingguideSteps={"manageCompanyguideSteps"}
        imageStep={"manageCompanyStep"}
        guideStepList={teamGuideStepList}
      />
    </>
  );
};

export default CompanyContainer;
