import { Box, Card, CardContent, Stack, styled, Typography } from '@mui/material';
import { Button, Spacer } from '../../../../components/shared';
import images from '../../../../config/images';
import moment from 'moment';
import { dateFormat } from '../../../../config';
import { formatCurrency } from '../../../../utils/helper';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { basicPlans } from '../../../../config';
import MoveDownIcon from '@mui/icons-material/MoveDown';
import { useSelector } from 'react-redux';

const StyledTitle = styled(Typography)`
  color: rgba(0, 0, 0, 0.6);
  font-size: 20px;
  font-weight: 600;
  line-height: 23.34px;
  font-family: 'Roboto', sans-serif;
`;

const StyledPlanBaseName = styled(Typography)`
  color: #000000de;
  font-size: 20px;
  font-weight: 600;
  line-height: 23.34px;
`;

const StyledLicenceText = styled(Typography)`
  color: #000000de;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.15px;
`;

const StyledSubscriptionEnds = styled(Typography)`
  color: #00000061;
  font-size: 12px;
  font-weight: 400;
  line-height: 19.92px;
  letter-spacing: 0.4px;
`;

const StyledSubscriptionCredits = styled(Typography)`
  color: #000000de;
  font-size: 28px;
  font-weight: 600;
  line-height: 32.676px;
`;

const StyledCreditsValue = styled(Typography)`
  color: #000000de;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.15px;
`;

const StyledCreditUsed = styled(Typography)`
  color: #00000099;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.15px;
  display: flex;
  gap: 8px;
`;

export const SubscriptionCardSub = ({
  title,
  is14DaysTrial,
  handleChangeSubscription,
  summaryData,
  tenant,
  activePlanData,
  hasFreeOneMembershipAccess,
  allSubscriptions,
  toggleRechargeModal,
  loading,
  subscriptions,
  itsPremiumPlan,
  initiatePayment,
}) => {
  const history = useHistory();

  const allPlans = useSelector((state) => state.app.globals?.charges ?? []);

  const getSubscriptionDetails = () => {
    const subscriptionDetails = { monthlyPlan: {}, yearlyPlan: {} };
    const plans = (allSubscriptions || []).flatMap((subscription) => subscription.plans);

    if (activePlanData?.yearPlanKey || activePlanData?.year?.activePlanKey) {
      const activePLanBaseDetail =
        basicPlans[
          activePlanData?.year?.data?.item_price_id || activePlanData?.month?.data?.entity_id
        ];
      subscriptionDetails.yearlyPlan = plans.find(
        (plan) => plan?.planId === activePLanBaseDetail?.year,
      );
      subscriptionDetails.monthlyPlan = plans.find(
        (plan) => plan?.planId === activePLanBaseDetail?.month,
      );
    }

    if (activePlanData?.monthPlanKey || activePlanData?.month?.activePlanKey) {
      const activePLanBaseDetail =
        basicPlans[
          activePlanData?.month?.data?.item_price_id || activePlanData?.month?.data?.entity_id
        ];
      subscriptionDetails.yearlyPlan = plans.find(
        (plan) => plan?.planId === activePLanBaseDetail?.year,
      );
      subscriptionDetails.monthlyPlan = plans.find(
        (plan) => plan?.planId === activePLanBaseDetail?.month,
      );
    }

    return subscriptionDetails;
  };

  const { monthlyPlan, yearlyPlan } = getSubscriptionDetails();

  return (
    <>
      <Card sx={{ borderRadius: '12px', padding: '40px' }}>
        <CardContent>
          <Stack
            justifyContent={'space-between'}
            direction={'row'}
            width={'100%'}
            alignItems={'center'}
          >
            <Typography variant="h3" color="textSecondary" sx={{ mb: 3 }}>
              <b>Subscription - {title == 'LMS' ? 'SuperReach Ai Learning' : title} Plan</b>
            </Typography>
            {(is14DaysTrial || (hasFreeOneMembershipAccess && !itsPremiumPlan)) && (
              <Button variant="text" color="secondary" onClick={() => handleChangeSubscription()}>
                Upgrade
              </Button>
            )}
          </Stack>
          <Stack gap={3} mt={2}>
            <Stack gap={'24px'} direction={'row'} sx={{ width: '100%' }} alignItems={'center'}>
              <img src={images.app.SRArrowImg} alt={''} />

              <Stack gap={'8px'} sx={{ width: '100%' }}>
                <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                  <StyledPlanBaseName>
                    {!itsPremiumPlan && !hasFreeOneMembershipAccess && is14DaysTrial
                      ? '14 days'
                      : 'Basic monthly'}{' '}
                    - ${monthlyPlan?.price || 0} pm
                  </StyledPlanBaseName>
                  <Button
                    variant="contained"
                    color="secondary"
                    loading={loading ? loading === monthlyPlan.planId : false}
                    disabled={loading}
                    onClick={() => {
                      initiatePayment(
                        monthlyPlan?.planId,
                        subscriptions?.subscription?.[monthlyPlan?.planKey],
                        false,
                      );
                    }}
                  >
                    Add licenses
                  </Button>
                </Stack>
                <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                  <StyledLicenceText>
                    {tenant.lmsMonthly_consumed
                      ? tenant.lmsMonthly_consumed + tenant.outreachMonthly_consumed
                      : 0}{' '}
                    / {tenant.lmsMonthly ? tenant.lmsMonthly + tenant.outreachMonthly : 0} licenses
                    assigned
                  </StyledLicenceText>
                  <StyledSubscriptionEnds>
                    {activePlanData?.month?.data?.next_billing_at &&
                      `Subscription ends:
                      ${moment
                        .unix(activePlanData?.month?.data?.next_billing_at)
                        .format(dateFormat)}`}
                  </StyledSubscriptionEnds>
                </Stack>
              </Stack>
            </Stack>

            {itsPremiumPlan && (
              <Stack
                gap={'24px'}
                direction={'row'}
                sx={{
                  width: '100%',
                }}
                alignItems={'center'}
              >
                <img src={images.app.SRArrowImg} alt={''} />
                <Stack
                  gap={'8px'}
                  sx={{
                    width: '100%',
                  }}
                >
                  <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                    <StyledPlanBaseName>
                      Basic annual - ${yearlyPlan?.price || 0} py
                    </StyledPlanBaseName>
                    <Button
                      variant="contained"
                      color="secondary"
                      loading={loading ? loading === yearlyPlan?.planId : false}
                      disabled={loading}
                      onClick={() => {
                        initiatePayment(
                          yearlyPlan.planId,
                          subscriptions?.subscription?.[yearlyPlan?.planKey],
                          false,
                        );
                      }}
                    >
                      Add licenses
                    </Button>
                  </Stack>
                  <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                    <StyledLicenceText>
                      {tenant.lmsAnnual_consumed
                        ? tenant.lmsAnnual_consumed + tenant.outreachAnnual_consumed
                        : 0}{' '}
                      / {tenant.lmsAnnual ? tenant.lmsAnnual + tenant.outreachAnnual : 0} licenses
                      assigned
                    </StyledLicenceText>
                    <StyledSubscriptionEnds>
                      {activePlanData?.year?.data?.next_billing_at &&
                        `Subscription ends:
                        ${moment
                          .unix(activePlanData?.year?.data?.next_billing_at)
                          .format(dateFormat)}`}
                    </StyledSubscriptionEnds>
                  </Stack>
                </Stack>
              </Stack>
            )}
          </Stack>
        </CardContent>
      </Card>
      <Spacer y={4} />
      <Card sx={{ borderRadius: '12px', padding: '40px' }}>
        <CardContent>
          <Box sx={{ width: '100%' }}>
            <Stack
              justifyContent={'space-between'}
              direction={'row'}
              width={'100%'}
              alignItems={'center'}
            >
              <Box>
                <Typography variant="h3" color="textSecondary">
                  <b>Pay As You Go Services</b>
                </Typography>
                <Spacer x={1} y={1} />
                <Typography variant="body1" color="textSecondary">
                  Data enrichment, Email validation, AI Co-workers and AI Coaches
                </Typography>
              </Box>
              <Button
                color="secondary"
                onClick={() => {
                  history.push('/admin/spendinglimits');
                }}
                startIcon={<MoveDownIcon sx={{ color: '#1976D2' }} />}
              >
                Manage spending limits
              </Button>
            </Stack>
            <Box>
              <Stack
                gap={'24px'}
                direction={'row'}
                sx={{
                  width: '100%',
                }}
                alignItems={'center'}
              >
                <img
                  src={images.app.payGIcon}
                  style={{ marginTop: '18px', width: '60px', height: '60px' }}
                />
                <Stack
                  gap={'8px'}
                  sx={{
                    width: '100%',
                    marginTop: '24px',
                  }}
                >
                  <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                    <StyledPlanBaseName sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                      Balance Available - ${formatCurrency(tenant?.credits)}
                    </StyledPlanBaseName>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => {
                        toggleRechargeModal('data');
                      }}
                      disabled={!allPlans?.data?.plans?.length}
                    >
                      Add Funds
                    </Button>
                  </Stack>
                  <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                    <StyledCreditUsed>
                      Balance used this month :{' '}
                      <StyledCreditsValue component={'span'}>
                        ${formatCurrency(summaryData?.creditsUsed)}
                      </StyledCreditsValue>
                    </StyledCreditUsed>
                  </Stack>
                </Stack>
              </Stack>
            </Box>
          </Box>
        </CardContent>
      </Card>
    </>
  );
};

export default SubscriptionCardSub;
