import React from 'react';
import images from 'src/config/images';

import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import PaymentIcon from '@mui/icons-material/Payment';
import PaymentsIcon from '@mui/icons-material/Payments';
import learningIcon from '../assets/images/learningIcon.svg';
import outreachIcon from '../assets/images/outreachIcon.svg';
import { ReactComponent as ContactsIcon } from 'src/img/nav/contactsIcon.svg';
import { ReactComponent as DashboardIcon } from 'src/img/nav/dashboardIcon.svg';
import { ReactComponent as SequenceNavIcon } from 'src/img/nav/sequenceNavIcon.svg';
import { ReactComponent as ConversationIcon } from 'src/img/nav/conversationIcon.svg';
import { ReactComponent as TaskIcon } from 'src/img/nav/taskIcon.svg';

const getMenuItems = (
  path = '',
  sideBar = '',
  isReseller,
  isPartner,
  role,
  hasLmsAccess,
  hasOutreachAccess,
) => {

  let menuItems = [];
  switch (sideBar) {
    case 'admin': {
      menuItems = [
        ...(['teamlead', 'admin', 'manager']?.includes(role)
          ? [
              {
                id: 'manageCompany',
                title: 'Manage Company',
                menuIcon: <AccountBalanceIcon />,
                expand: false,
                link: `/admin/manage-company/users`,
                activeClass: path.startsWith('/admin/manage-company') ? 'active' : '',
                in: [
                  '/admin/manage-company/users',
                  '/admin/manage-company/teams',
                  '/admin/manage-company/company-profile',
                ].includes(path),
              },
              {
                type: 'divider',
              },
            ]
          : []),
        ...(['admin']?.includes(role)
          ? [
              {
                id: 'billing',
                title: 'Billing',
                menuIcon: <PaymentIcon />,
                link: '/admin/billing/details',
                activeClass: path.startsWith('/admin/billing') ? 'active' : '',
                in: ['/admin/billing'].includes(path),
              },
              {
                type: 'divider',
              },
            ]
          : []),
        ...(isPartner
          ? [
              {
                id: 'Resellers',
                title: 'Resellers',
                link: '/admin/resellers/dashboard',
                activeClass: path.startsWith('/admin/resellers/dashboard') ? 'active' : '',
                // menuIcon: <SupervisorAccountIcon />,
                menuIcon: <img src={images.app.resellerIcon} style={{ width: '24px' }} />,
              },
              {
                id: 'Automations',
                title: 'Automations',
                link: '/admin/resellers/automation',
                activeClass: path.startsWith('/admin/resellers/automation') ? 'active' : '',
                menuIcon: <PaymentsIcon />,
              },
              {
                type: 'divider',
              },
            ]
          : []),
        ...(['teamlead', 'admin', 'manager']?.includes(role) && (hasOutreachAccess || hasLmsAccess)
          ? [
              {
                type: 'mainHelper',
                title: 'Products',
              },
            ]
          : []),
        ...(['teamlead', 'admin', 'manager']?.includes(role) && hasOutreachAccess
          ? [
              {
                id: 'outreachSettings',
                title: 'Outreach',
                expand: true,
                menuIcon: <img src={outreachIcon} alt="outreachIcon" />,
                link: '/admin/settings',
                in: [
                  '/admin/fields',
                  '/admin/fields/lookups',
                  '/admin/settings',
                  '/admin/integrations',
                  '/admin/data/summary',
                ].includes(path),
                child: [
                  {
                    id: 'outreachSettings',
                    title: 'Outreach settings',
                    link: '/admin/settings',
                    activeClass: path.startsWith('/admin/settings') ? 'active' : '',
                  },
                  {
                    id: 'manage-fields',
                    title: 'Field management',
                    activeClass: path.startsWith('/admin/fields') ? 'active' : '',
                    link: '/admin/fields',
                  },
                  {
                    id: 'central-lookup',
                    title: 'Central lookups',
                    activeClass: path.startsWith('/admin/fields/lookups') ? 'active' : '',
                    link: '/admin/fields/lookups',
                  },
                  {
                    id: 'integrations',
                    title: 'Integrations',
                    link: '/admin/integrations',
                    activeClass: path.startsWith('/admin/integrations') ? 'active' : '',
                  },
                  {
                    id: 'data',
                    title: 'Data services',
                    link: '/admin/data/waterfall',
                    activeClass: path.startsWith('/admin/data/summary') ? 'active' : '',
                  },
                ],
              },
            ]
          : []),
        ...(['teamlead', 'admin', 'manager']?.includes(role) && hasLmsAccess
          ? [
              // {
              //   id: 'aiLearningSettings',
              //   title: 'Learning',
              //   // activeClass: path.startsWith('/admin/roleplay') ? 'active' : '',
              //   expand: true,
              //   link: '/admin/roleplay-minutes',
              //   in: [
              //     // '/admin/notes',
              //     // '/admin/course',
              //     // '/admin/course/create',
              //     // '/admin/roleplay/setting',
              //     '/admin/roleplay-minutes',
              //     // '/admin/competitions',
              //   ].includes(path),
              //   menuIcon: <img src={learningIcon} alt="learningIcon" width={25} />,
              //   child: [
              //     {
              //       id: 'roleplay',
              //       title: 'Manage AI Coach',
              //       link: '/admin/roleplay-minutes',
              //       activeClass: path.startsWith('/admin/roleplay-minutes') ? 'active' : '',
              //     },
                  // {
                  //   type: 'helper',
                  //   id: 'learning-customisation',
                  //   title: 'Customisation',
                  // },
                  // {
                  //   id: 'roleplaySettings',
                  //   title: 'AI Coaching Settings',
                  //   activeClass: path.startsWith('admin/roleplay') ? 'active' : '',
                  //   link: '/admin/roleplay/setting',
                  // },
                  // {
                  //   id: 'course',
                  //   title: 'Course',
                  //   activeClass: path.startsWith('admin/course') ? 'active' : '',
                  //   link: '/admin/course',
                  // },
                  // {
                  //   id: 'create-course',
                  //   title: 'Create course',
                  //   activeClass: path.startsWith('/admin/course/create') ? 'active' : '',
                  //   link: '/admin/course/create',
                  // },
                  // {
                  //   id: 'competitions',
                  //   title: 'Competitions',
                  //   activeClass: path.startsWith('/admin/competitions') ? 'active' : '',
                  //   link: '/admin/competitions',
                  // },
                  // {
                  //   id: 'Notes',
                  //   title: 'Notes',
                  //   activeClass: path.startsWith('/admin/notes') ? 'active' : '',
                  //   link: '/admin/notes',
                  // },
              //   ],
              // },
            ]
          : []),
      ];
      break;
    }

    case 'profile':
    default: {
      menuItems = [
        {
          id: 'dashboard',
          title: 'Dashboard',
          menuIcon: <DashboardIcon />,
          expand: false,
          link: '/boards',
          activeClass: path.startsWith('/boards') ? 'active' : '',
          iconActiveClass: 'active-fill-path',
        },
        {
          id: 'sequences',
          title: 'Sequences',
          activeClass: path.startsWith('/sequence') ? 'active' : '',
          link: '/sequences',
          menuIcon: <SequenceNavIcon />,
          iconActiveClass: 'active-stroke-path',
        },
        {
          id: 'conversations',
          title: 'Conversations',
          activeClass: ['/inbox', '/outbox', '/sent'].includes(path) ? 'active' : '',
          link: '/inbox',
          menuIcon: <ConversationIcon />,
          iconActiveClass: 'active-stroke-path',
        },
        {
          id: 'tasks',
          title: 'Tasks',
          expand: false,
          link: '/tasks',
          menuIcon: <TaskIcon />,
          activeClass: path.startsWith('/tasks') ? 'active' : '',
          iconActiveClass: 'active-stroke-path',
        },
        {
          id: 'contacts',
          title: 'People',
          activeClass: path.startsWith('/contacts') ? 'active' : '',
          link: '/contacts',
          menuIcon: <ContactsIcon />,
          iconActiveClass: 'active-stroke-path',
        },
      ];
    }
  }
  return menuItems;
};

export default getMenuItems;
