import React, { useState, useMemo } from 'react';
import { Button } from 'src/components/shared';
import { Typography } from '@mui/material';
import { AppGrid } from 'src/components/App';
import { teamBulkAction } from './config';
import TeamActionDrawer from './TeamActionDrawer';
import { useColumns } from './useColumns';
import { setOutreachBoarding } from 'src/modules/app/actions/appActions';
import { useDispatch, useSelector } from 'react-redux';
import useTheme from '@mui/styles/useTheme';
import Icon from 'src/components/lms/Icon';

function TeamsGrid({
  drawerToggle,
  teams,
  teamBulkUpdate,
  updateTeamStatus,
  globals,
  loading,
  ...props
}) {
  const [rowsSelected, setRowsSelected] = useState([]);
  const [bulkAction, setBulkAction] = useState('');
  const { data, filters, paging, sort } = teams;
  const columns = useColumns(data, drawerToggle, props, updateTeamStatus);
  const isLoading = useSelector((state) => state.app?.outreachBoarding?.isLoading);

  const filtersAvailable = [
    {
      key: 'status',
      title: 'All Status',
      type: 'dropdown',
      cancellable: true,
      searchable: true,
      isPrimary: true,
      options: [
        {
          label: 'Active',
          value: 'active',
        },
        {
          label: 'Inactive',
          value: 'inactive',
        },
        {
          label: 'Pending',
          value: 'pending',
        },
      ],
    },
  ];
  const dispatch = useDispatch();
  const theme = useTheme()

  const handlePerPageChange = (data1, data2) => { };

  const onFilterChange = (key, value) => {
    if (value && typeof value !== 'undefined') {
      if (key === '_search') {
        props.fetchTeams(
          {
            ...paging,
            pageNo: 0,
          },
          { ...filters, [key]: value },
          sort,
        );
      } else {
        props.fetchTeams(paging, { ...filters, [key]: value }, sort);
      }
    } else {
      delete filters[key];
      props.fetchTeams(paging, filters, sort);
    }
  };

  const onSort = (sort) => {
    props.fetchTeams(paging, filters, sort);
  };

  const handleTableChange = ({ pageNo, perPage, searchText, sortOrder }) => {
    props.fetchTeams(
      {
        pageNo,
        perPage,
      },
      filters,
      sort,
    );
  };

  const handleColumnChange = (data1, data2) => { };

  const onBulkAction = (key) => {
    setBulkAction(key);
  };

  const closeBulkAction = (key) => {
    setBulkAction(undefined);
    setRowsSelected([]);
  };
  const selectTeams = useMemo(() => {
    return data?.filter((item, index) => rowsSelected.includes(index));
  }, [rowsSelected, data]);

  const openTeamGuidance = () => {
    dispatch(setOutreachBoarding({ manageCompanyCarousel: true }))
  }

  return (
    <>
      <AppGrid
        selectableRows="multiple"
        filters={filtersAvailable}
        columns={columns.map((col, index) => ({
          ...col,
          options: columns?.columns?.length
            ? { ...col.options, ...columns?.columns[index] }
            : col.options,
        }))}
        data={data}
        onSort={onSort}
        onTableRowPerPageChange={handlePerPageChange}
        onTableChange={handleTableChange}
        onColumnChange={() => handleColumnChange(false)}
        onFilterChange={onFilterChange}
        appliedFilters={filters}
        loading={loading?.teams}
        bulkActions={teamBulkAction}
        onBulkAction={onBulkAction}
        options={{
          sortOrder: {
            name: sort?.name || 'none',
            direction: sort?.direction || 'none',
          },
          serverSide: true,
          pagination: true,
          page: paging.pageNo,
          rowsPerPage: paging.perPage,
          count: paging.count,
          selectableRows: 'multiple',
          searchPlaceholder: 'Search Teams',
          searchText: filters?._search ? filters._search : '',
          searchOpen: true,
          search: true,
          filter: false,
          download: false,
          viewColumns: false,
          selectToolbarPlacement: 'replace',
          renderCustomFilterActions: () => {
            return (
              <>
                <Button disabled={isLoading} sx={{ color: theme.palette.text.grey }} variant="text" onClick={openTeamGuidance} startIcon={<Icon name="blueRocket" />}>Guidance</Button>
                <Button
                  variant="contained"
                  color="secondary"
                  size="medium"
                  onClick={() => {
                    drawerToggle({ edit: null, open: true });
                  }}
                >
                  <Typography variant="body2">Create team</Typography>
                </Button>
              </>
            );
          },
          onRowSelectionChange: (selected, all) => {
            setRowsSelected(all.map((row) => row.dataIndex));
          },
          rowsSelected: rowsSelected,
        }}
      />

      <TeamActionDrawer
        action={bulkAction}
        onClose={closeBulkAction}
        teamBulkUpdate={teamBulkUpdate}
        selectTeams={selectTeams}
        globals={globals}
      />
    </>
  );
}

export default TeamsGrid;
