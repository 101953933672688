import React, { useMemo, useState } from 'react';
import { Box, Link, Stack, Typography, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Icon from 'src/components/lms/Icon';
import useSubscription from 'src/components/hooks/useSubscription';
import { setOutreachBoarding } from 'src/modules/app/actions/appActions';
import { formatCurrency } from 'src/utils/helper';
import UpgradeSubscriptionModal from '../Sidebar/UpgradeSubscriptionModal';

const TrialSubscriptionCard = () => {
  const theme = useTheme();
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const welcomeCarousel = useSelector((state) => state.app.outreachBoarding.welcomeCarousel);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const {
    activeSubscriptionPlan,
    isFreeTrialSubscriptionPlan,
    isPremiumSubscriptionPlan,
    leftSubscriptionDays,
    availableCredit,
    subscriptionTrackingTime,
  } = useSubscription();

  const upgradeSubscriptionBannerStyles = useUpgradeSubscriptionBannerStyles({
    progress: subscriptionTrackingTime,
  });

  const updateSubscription = () => {
    if (user?.acl?.accessLevel?.toLowerCase() === 'admin') {
      history.push('/admin/billing/subscriptions');
    } else {
      setIsModalOpen(true);
    }
  };

  if (!activeSubscriptionPlan?.title) {
    return null;
  }

  return (
    <>
      <Box sx={{ padding: theme.spacing('8px') }}>
        <Box
          sx={{
            padding: theme.spacing('12px'),
            borderRadius: 2,
            backgroundColor: theme.palette.background.bg2,
          }}
        >
          <Stack gap={1.5}>
            <Stack flexDirection="row" alignItems="center" justifyContent="space-between" gap={1}>
              <Typography color={theme.palette.text.white}>
                {activeSubscriptionPlan?.title ?? ''}
              </Typography>
              <Stack
                sx={{ cursor: 'pointer' }}
                onClick={() => {
                  dispatch(setOutreachBoarding({ welcomeCarousel: !welcomeCarousel }));
                }}
              >
                <Icon name="rocket" />
              </Stack>
            </Stack>
            {isFreeTrialSubscriptionPlan && (
              <Stack className={upgradeSubscriptionBannerStyles.progressBar}>
                <Stack />
              </Stack>
            )}
            {!isPremiumSubscriptionPlan && (
              <Stack flexDirection="row" justifyContent="space-between">
                <Typography variant="caption" color={theme.palette.text.grey11}>
                  {leftSubscriptionDays > 0
                    ? `${leftSubscriptionDays} day${leftSubscriptionDays !== 1 ? 's' : ''} left`
                    : 'Trial has ended'}
                </Typography>

                <Typography
                  component="div"
                  variant="caption"
                  fontWeight={500}
                  color={theme.palette.text.yellow}
                  sx={{ textDecoration: 'underline', minWidth: 'max-content', cursor: 'pointer' }}
                  onClick={updateSubscription}
                >
                  Upgrade
                </Typography>
              </Stack>
            )}
            <Stack flexDirection="row" justifyContent="space-between">
              <Typography variant="caption" color={theme.palette.text.grey11}>
                {availableCredit > 0
                  ? `$${formatCurrency(Number(availableCredit))} left`
                  : 'No Credit Available'}
              </Typography>

              <Typography
                component="div"
                variant="caption"
                fontWeight={500}
                color={theme.palette.text.yellow}
                sx={{ textDecoration: 'underline', minWidth: 'max-content', cursor: 'pointer' }}
                onClick={updateSubscription}
              >
                Add Balance
              </Typography>
            </Stack>
          </Stack>
        </Box>
      </Box>
      <UpgradeSubscriptionModal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />
    </>
  );
};

export default TrialSubscriptionCard;

const useUpgradeSubscriptionBannerStyles = makeStyles((theme) => ({
  progressBar: ({ progress }) => ({
    height: 10,
    borderRadius: 42.23,
    backgroundColor: '#060A0D',
    '& > div': {
      height: '100%',
      width: `${progress}%`,
      borderRadius: 42.23,
      background:
        'linear-gradient(247.38deg, rgba(255, 212, 128, 0.5) 18.34%, rgba(242, 64, 164, 0.5) 49.87%, rgba(25, 118, 210, 0.5) 85.29%)',
    },
  }),
}));
