export const FeatureCaseKeys = {
  SCRATCHPAD_ENABLED: 'scratchpadEnabled',
  CHAT_WIDGET: 'chatWidget',
  LINKEDIN_AUTOMATION: 'linkedinAutomation',
  GENERATE_SEQUENCE_WITH_AI: 'generateSequenceWithAI',
  GENERATE_EMAIL_TEMPLATE_WITH_AI: 'generateEmailTemplateWithAI',
  GENERATE_LINKEDIN_MESSAGE_TEMPLATE_WITH_AI: 'generateLinkedinMessageTemplateWithAI',
  GENERATE_LINKEDIN_CONNECTION_TEMPLATE_WITH_AI: 'generateLinkedinConnectionTemplateWithAI',
  GENERATE_LINKEDIN_MAIL_TEMPLATE_WITH_AI: 'generateLinkedinInMailTemplateWithAI',
  STANDARD: 'standard',
  EMAIL_VERIFICATION: 'emailVerification',
  GENERATE_TONE_OF_VOICE_WITH_AI: 'generateToneOfVoiceWithAI',
  HAS_ICP_ACCESS: 'hasICPAccess',
  ENABLED_AI_COWORKERS: 'enabledAiCoWorkers',
};
