import React from 'react';
import { Box, Typography, IconButton, Dialog, DialogContent } from '@mui/material';
import { Button } from 'src/components/shared';
import CloseIcon from '@mui/icons-material/Close';
import images from 'src/config/images';

const LearnMoreModal = ({ open, onClose }) => {
  const handleClose = async () => {
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: '12px',
        },
      }}
    >
      <Box>
        <img
          src={images.background.LearnMoreModel}
          alt="Learn more"
          style={{
            width: '100%',
            height: 'auto',
            display: 'block',
          }}
        />
        <IconButton
          onClick={onClose}
          size="small"
          sx={{
            position: 'absolute',
            right: 17,
            top: 17,
            color: '#666',
          }}
        >
          <CloseIcon />
        </IconButton>

        <DialogContent sx={{ p: 3 }}>
          <Typography variant="h1" sx={{ mb: 2 }}>
            Monthly Spending Limits
          </Typography>

          <Typography variant="body2" color="textPrimary" sx={{ mb: 2 }}>
            For pay-as-you-go services, managers can set two types of limits:
          </Typography>

          <Typography variant="body2" sx={{ mb: 0.5 }}>
            <b>Account Spending Limit</b>
          </Typography>
          <Typography variant="body2" color="textPrimary" sx={{ mb: 2 }}>
            This is the total budget for all users. It serves as the overriding cap to control
            overall spending.
          </Typography>

          <Typography variant="body2" sx={{ mb: 0.5 }}>
            <b>User Spending Limit</b>
          </Typography>
          <Typography variant="body2" color="textPrimary" sx={{ mb: 3 }}>
            This is the maximum each individual user can spend. It prevents a single user from using
            all the cash in the central pool.
          </Typography>

          <Box sx={{ display: 'flex', gap: 1, mb: 3, textAlign: 'justify' }}>
            <Typography variant="body2" color="textPrimary">
              💡 Pro Tip: To avoid frequent manual top-ups, we recommend setting user limits to{' '}
              <b>10%</b> more than they are likely to use. This ensures flexibility while keeping
              total spending under control.
            </Typography>
          </Box>

          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Button variant="contained" onClick={handleClose} sx={{ px: 4 }}>
              Got it!
            </Button>
          </Box>
        </DialogContent>
      </Box>
    </Dialog>
  );
};

export default LearnMoreModal;
