import React, { useMemo, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Card, CardContent, Typography, Divider, CardHeader, Stack } from '@mui/material';
import { Spacer, Button } from 'src/components/shared';
import { ConfirmDialog } from 'src/components/App';

import EmailIcon from '@mui/icons-material/Email';
import ReplyIcon from '@mui/icons-material/Reply';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import { sendTaskReply } from 'src/modules/tasks/actions/taskActions';
import { EmailHeader, EmailChip, HoverButton } from './styles';
import TaskReplyForm from '../../TaskReplies/TaskReplyForm';
import moment from 'moment';
import { dateTimeFormat } from 'src/config';
import { capitalizeFirstLetter } from 'src/utils/helper';
import Attachments from './Attachments';

function Message({ message, toggleNoteForm, type, ...props }) {
  const dispatch = useDispatch();
  const [showActions, setShowActions] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [showReplyEmail, setShowReplyEmail] = useState(false);
  const { content, subject } = message;
  const replyFormRef = useRef(null);

  const toggleReplyForm = () => {
    setShowReplyEmail(!showReplyEmail);
    if (!showReplyEmail) {
      setTimeout(() => {
        replyFormRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }, 0);
    }
  };

  const toggleDelete = () => {
    setShowDelete(!showDelete);
  };

  const onConfirm = async () => {
    try {
      setDeleting(true);
      await props.deleteTaskReply(message.id);
      toggleDelete();
      setDeleting(false);
    } catch (error) {
      setDeleting(false);
      toggleDelete();
    }
  };

  const handleTaskReply = (data) => {
    return new Promise((resolve, reject) => {
      return dispatch(sendTaskReply(data, resolve, reject));
    });
  };

  let emailType = 'manual';
  let color = '#1976D2';
  let textColor = '#00000099';
  const task = message?.task || {};
  if (task && task?.id) {
    if (task?.sequence && task?.sequence !== '') {
      emailType = task?.taskType === 'email' ? 'automated' : 'manual';
    } else {
      emailType = 'manual';
    }
    if (type !== 'inbox' && message.status === 'received') {
      emailType = 'replied';
    }
    color = emailType === 'manual' ? '#EC407A' : '#1976D2';
    color = emailType === 'manual' ? '#EC407A' : emailType === 'replied' ? '#FF9800' : '#1976D2';
    textColor =
      emailType === 'manual' ? '#EC407A' : emailType === 'replied' ? '#00000099' : '#00000099';
  }

  const attachments = message?.message?.data?.attachments || message?.message?.attachments || [];

  const showAttachments = useMemo(
    () => (message.type === 'send' || message.type === 'reply') && attachments.length > 0,
    [message, attachments],
  );

  let date = '';
  switch (message.status) {
    case 'received':
      date = message.createdAt;
      break;

    case 'sent':
      date = message.sentDate;
      break;

    default:
      date = message.sendOn;
      break;
  }

  return (
    <>
      <Card
        elevation={0}
        sx={{
          border: '1px solid #dddddd',
          position: 'relative',
          overflow: 'visible',
          '& pre': { maxWidth: '800px', overflowWrap: 'break-word', whiteSpace: 'break-spaces' },
        }}
        onMouseEnter={() => setShowActions(true)}
        onMouseLeave={() => setShowActions(false)}
      >
        <EmailHeader>
          <EmailChip>
            <EmailIcon sx={{ fontSize: '18px', fill: color }} />
            <Spacer x={1} y={1} />
            <Typography variant="caption" color="textSecondary">
              {capitalizeFirstLetter(emailType)} email
            </Typography>
          </EmailChip>
          {showActions && (
            <Stack direction="row" alignItems="center" mr={2} gap={1}>
              <Button
                startIcon={<ReplyIcon />}
                size="small"
                color="secondary"
                onClick={toggleReplyForm}
              >
                Reply
              </Button>
              <HoverButton startIcon={<NoteAddIcon />} size="small" onClick={toggleNoteForm}>
                Add note
              </HoverButton>
              {/* <HoverButton startIcon={<DeleteIcon />} size="small" onClick={toggleDelete}>
                Delete
              </HoverButton> */}
            </Stack>
          )}
        </EmailHeader>

        <CardContent>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="body1" color="textSecondary">
              {subject}
            </Typography>
            <Typography variant="caption" color="textSecondary">
              {moment(date || '').format(dateTimeFormat)}
              &nbsp; ({moment(date || '').fromNow()})
            </Typography>
          </Stack>
          <Spacer x={1} y={1} />
          <Typography component="div" variant="body2" sx={{ color: 'rgba(0,0,0,0.44)' }}>
            {type === 'inbox' ? (
              <div dangerouslySetInnerHTML={{ __html: content }} />
            ) : (
              <div
                dangerouslySetInnerHTML={{
                  __html: message?.message?.body || message?.message?.data?.body || content,
                }}
              />
            )}
          </Typography>

          <Spacer x={2} y={2} />
          {showReplyEmail && (
            <div ref={replyFormRef}>
              <TaskReplyForm
                email={message}
                type={'reply'}
                sendTaskReply={handleTaskReply}
                closeReplyForm={toggleReplyForm}
                includeTemplateData
                fetchTaskReplies={props.fetchTaskReplies}
              />
            </div>
          )}
          {showAttachments && (
            <Attachments attachments={attachments} messageId={message.message.id} />
          )}
        </CardContent>
      </Card>
      <Spacer x={5} y={5} />
      <ConfirmDialog
        title="Delete"
        open={showDelete}
        loading={deleting}
        onConfirm={onConfirm}
        onClose={toggleDelete}
      >
        Are you sure you want to delete this reply? This action cannot be undone.
      </ConfirmDialog>
    </>
  );
}

export default Message;
