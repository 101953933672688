import React, { useEffect, useState, useRef } from 'react';
import * as Yup from 'yup';
import {
  Box,
  FormHelperText,
  Stack,
  ButtonBase,
  Popover,
  Switch,
  Menu,
  MenuItem,
  Autocomplete,
  Chip,
  Avatar,
  TextField,
  ButtonGroup,
  Alert,
  Card,
  Grid,
  CircularProgress,
  Tooltip,
} from '@mui/material';
import { Button, Form, Modal, Spacer, DateRangePickerAlt } from 'src/components/shared';
import Typography from '@mui/material/Typography';
import { useStyles } from '../ProfileIntegration/styles';
import { daysOfWeek } from 'src/config';
import moment from 'moment';
import InfoIcon from '@mui/icons-material/Info';
import { DayChip, Item } from './styles';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined';
import toast from 'src/utils/toast';
import { Accordion, ConfirmDialog } from 'src/components/App';
import { hasIn } from 'lodash';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ConnectEmail from './index';
import { fetchUsersLookup } from '../../../users/api/usersApi';
import {
  allEnrollAssignmentDelete,
  enrollAssignmentDelete,
  enrollAssignmentPermissions,
  getEnrollUser,
} from '../../api/authApis';
import { Slider } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import {
  checkLinkedInOtp,
  getLinkedInStatus,
  loginLinkedIn,
  setLinkedInAutomationSetting,
} from '../../api/authApis';
import { putUser } from '../../actions/authActions';
import { getQueryParams } from 'src/modules/app/utils/helper';
import Signature from '../SignatureForm';
import Icon from 'src/components/lms/Icon';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import images from '../../../../config/images';
import CheckWithAppDialog from './LinkedinDialogs/CheckWithAppDialog';
import SecurityVerification from './LinkedinDialogs/SecurityVerification';
import SecurityVerificationFailed from './LinkedinDialogs/SecurityVerificationFailed';
import useFeatureAccess from '../../../../components/hooks/useFeatureAccess';
import { FeatureCaseKeys } from 'src/config/featureCase';

const defaultTime = {
  defaultStart: '9:00',
  defaultEnd: '17:00',
};

const autoSearchOtions = {
  workEmail: 'Work email',
  personalEmail: 'Personal email',
  mobile: 'Phone number',
};

const regex24hour = /^([01]\d|2[0-3]):?([0-5]\d)$/;

const LinkType = {
  email: 'email',
  linkedIn: 'linkedIn',
};

const linkedInAutomationSetting = {
  connectionReqLimit: 'Connection requests',
  connectionReqByMailLimit: 'Connection requests by email',
  messagesLimit: 'Messages',
  inmailLimit: 'InMails',
  profileViewLimit: 'Profile views',
  likedLimit: 'Likes',
  followingsLimit: 'Followings',
};

const linkedInAutomationSettingPremiumLimit = {
  connectionReqLimit: 75,
  connectionReqByMailLimit: 75,
  messagesLimit: 150,
  inmailLimit: 25,
  profileViewLimit: 400,
  likedLimit: 100,
  followingsLimit: 100,
};

const linkedInAutomationSettingBaseLimit = {
  connectionReqLimit: 75,
  connectionReqByMailLimit: 75,
  messagesLimit: 100,
  inmailLimit: 25,
  profileViewLimit: 200,
  likedLimit: 50,
  followingsLimit: 50,
};

const linkedInStatus = {
  IN_PROGRESS: 'inprogress',
  RE_AUTH: 're_auth_required',
  OTP_REQUIRED: 'otp_required',
  OTP_SUBMITTED: 'otp_submitted',
  OTP_INVALID: 'otp_invalid',
  COMPLETED: 'completed',
  FAILED: 'failed',
  INVALID_CREDENTIAL: 'invalid_credential',
  VERIFICATION_REQUIRED: 'verification_required',
  VERIFICATION_REQUIRED_WITH_APP: 'app_challenge',
  INVALID_USER_RESPONSE: 'invalid_user_response',
  TRY_ANOTHER_WAY: 'TRY_ANOTHER_WAY',
};

function SchedulingWindow({ editUser, status, formData, Container, ...props }) {
  const userData = useSelector((state) => state?.auth?.user);
  const tenant = useSelector((state) => state.app.tenant);
  const query = getQueryParams();

  const initialLinkedInValue = {
    linkedinAutomationSettings: userData?.automationSettings?.linkedinAutomationSettings ?? {
      connectionReqLimit: 10,
      connectionReqByMailLimit: 10,
      messagesLimit: 10,
      inmailLimit: 10,
      profileViewLimit: 10,
      likedLimit: 10,
      followingsLimit: 10,
    },
    linkedinHolidaySettings: userData?.automationSettings?.linkedinHolidaySettings ?? [],
    linkedinSchedulingWindow: userData?.automationSettings?.linkedinSchedulingWindow ?? {
      dailyWindow: { start: '', end: '' },
      weeklyWindow: [],
    },
  };

  const globalConfig = useSelector((state) => state.app.globals);
  const adminSettings = useSelector((state) => state.admin.admin.tenantSettings.data[0]);
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [linkedInActionData, setLinkedInActionData] = useState(initialLinkedInValue);
  const [disconnectLoadingBtn, setDisconnectLoadingBtn] = useState(false);
  const [linkedInData, setLinkedInData] = useState({});
  const [linkedinContractType, setLinkedinContractType] = useState(userData.linkedinContractType);
  const [linkedInOtp, setLinkedInOtp] = useState('');
  const [diconnectLinkedInModal, setDiconnectLinkedInModal] = useState(false);
  const [connectLinkedIn, setConnectLinkedIn] = useState(false);
  const [showOtpPanel, setShowOtpPanel] = useState(false);
  const [linkedInError, setLinkedInError] = useState('');
  const [linkedInLoginCred, setLinkedInLoginCred] = useState({ email: '', password: '' });
  const [linkedInConnectBtnLoading, setLinkedInConnectBtnLoading] = useState(false);
  const [emailVerificationLoading, setEmailVerificationLoading] = useState('');
  const [holidayBtnLoading, setHolidayBtnLoading] = useState(false);
  const [popOverKey, setPopoverKey] = useState('');
  const [linkType, setLinkType] = useState(LinkType.email);
  const [holidays, setHolidays] = useState(editUser?.holidaySettings || []);
  const [editHoliday, setEditHoliday] = useState(null);
  const [showLinkedinApp, setShowLinkedinApp] = useState(false);
  const [showLinkedinSMS, setShowLinkedinSMS] = useState(false);
  const [linkdinOtpError, setLinkedinOtpError] = useState('');
  const [showLinkedinVerification, setShowLinkedinVerificationFailed] = useState(false);
  const [sequenceDelay, setSequenceDelay] = useState({
    sequenceOffset: hasIn(editUser, 'sequenceOffset') ? editUser?.sequenceOffset : false,
    sequenceOffsetinMinutes: hasIn(editUser, 'sequenceOffsetinMinutes')
      ? editUser?.sequenceOffsetinMinutes
      : 15,
  });
  const [autoSearchData, setAutoSearchData] = useState({
    autoEnrich: hasIn(editUser, 'autoEnrich') ? editUser?.autoEnrich : false,
    autoEnrichType: hasIn(editUser, 'autoEnrichType') ? editUser?.autoEnrichType : '',
  });
  const [isShowAutoSearchError, setIsShowAutoSearchError] = useState(false);
  const [deleteHolidayModal, setDeleteHolidayModal] = useState(null);
  const [currentUser, setCurrentUser] = useState({});
  const [userList, setUserList] = useState([]);
  const [addUserIdList, setAddUserList] = useState([]);
  const [selectedUserList, setSelectedUserList] = useState([]);
  const [getSelectedUserList, setGetSelectedUserList] = useState([]);
  const [userLength, setUserLength] = useState();
  const [isGetData, setIsGetData] = useState(false);

  const { defaultStart, defaultEnd } = defaultTime;

  const classes = useStyles();

  const linkedInDataRef = useRef();
  linkedInDataRef.current = linkedInData;

  const holidayRef = useRef();
  holidayRef.current = editHoliday;

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
    setEditHoliday(null);
  };

  useEffect(() => {
    if (editUser && editUser?.id) {
      setCurrentUser(editUser);
    }
  }, [editUser]);
  const handleUserChange = (event, newValue) => {
    // Prevent duplicates by filtering out users that are already in the selected list
    const uniqueNewValue = newValue.filter(
      (newUser) => !selectedUserList.some((selectedUser) => selectedUser.id === newUser.id),
    );
    // Update selectedUserList with unique new selections
    setSelectedUserList([...selectedUserList, ...uniqueNewValue]);
    setGetSelectedUserList([...selectedUserList, ...uniqueNewValue]);
  };

  useEffect(() => {
    if (query.selectedTab) {
      setLinkType(query.selectedTab);
      const url = new URL(window.location);
      const params = new URLSearchParams(url.search);
      params.delete('selectedTab');
      url.search = params.toString();
      window.history.replaceState({}, document.title, url.toString());
    }
  }, [query.selectedTab]);

  const fetchUserList = async () => {
    try {
      const userLists = await fetchUsersLookup();
      setUserList(userLists);
    } catch (error) {
      console.error('Error fetching user list:', error);
    }
  };

  const GetUserList = async () => {
    try {
      setIsGetData(true);
      const res = await getEnrollUser(editUser?.id);
      const mergedData = userList.map((item1) => {
        const matchingItem = res.find((item2) => item2.assigner.id === item1._id);
        return matchingItem
          ? { ...item1, assigner: matchingItem?.assigner, _id: matchingItem?.id }
          : null;
      });
      const filteredArray = mergedData.filter((item) => item !== null && item !== undefined);
      setUserLength(filteredArray.length || 0);
      setSelectedUserList(filteredArray || []);
      setGetSelectedUserList(filteredArray || []);
      setIsGetData(false);
    } catch (error) {
      console.error('Error fetching user list:', error);
    }
  };

  useEffect(() => {
    fetchUserList();
  }, []);

  useEffect(() => {
    if (!userList || userList.length === 0) return; // Early exit if userList is undefined or empty
    GetUserList();
  }, [userList]);

  const handleSubmit = async (values, form) => {
    let data = {
      schedulingWindow: { ...values },
      emailLimit: values.emailLimit,
    };

    if (editUser && editUser?.id) {
      const updatedRow = { id: editUser?.id, data: data };
      setLoading(true);
      await props.putUser(updatedRow);
      setLoading(false);
    }
  };

  const open = Boolean(anchorEl);

  const saveUserData = async (data, setLoadingState) => {
    if (data.autoEnrich && !data.autoEnrichType) {
      setIsShowAutoSearchError(true);
      return;
    }
    if (editUser && editUser?.id) {
      const updatedRow = {
        id: editUser?.id,
        data,
      };
      setLoadingState?.(true);
      setHolidayBtnLoading(true);
      await props.putUser(updatedRow);
      setLoadingState?.(false);
      setHolidayBtnLoading(false);
    }
  };

  const updateUserEmailVerificationSettings = async (value) => {
    setEmailVerificationLoading(value);
    const { emailVerificationSettings } = adminSettings;
    const isEmailVerificationEnabled =
      emailVerificationSettings?.mode !== value || !emailVerificationSettings?.enabled;

    const updateEmailVerificationSettings = {
      enabled: isEmailVerificationEnabled,
      mode: value,
    };
    await props.updateSettings(
      {
        emailVerificationSettings: { ...updateEmailVerificationSettings },
      },
      adminSettings.id,
    );
    setEmailVerificationLoading('');
  };

  const getActiveEmailValidation = (key) => {
    const { emailVerificationSettings } = adminSettings;
    if (emailVerificationSettings?.enabled && emailVerificationSettings?.mode === key) return true;
    return false;
  };

  const enrollmentSaveUserData = async (data) => {
    const assignerId = data.map((v) => v?.id);
    const payload = {
      sender: editUser?.id,
      assigner: assignerId,
    };
    const res = await enrollAssignmentPermissions(payload);
    if (res) {
      toast.success('Add the enroll user!', 'tc');
    }
    GetUserList();
  };

  const getStartTime = () => {
    const time = editUser?.schedulingWindow?.dailyWindow?.start;
    if (!time || time === 'Invalid Date') return defaultStart;
    else return time;
  };

  const getEndTime = () => {
    const time = editUser?.schedulingWindow?.dailyWindow?.end;
    if (!time || time === 'Invalid Date') return defaultEnd;
    else return time;
  };

  const step = 2;

  const handleUserDelete = async (data, index) => {
    const filterData = selectedUserList.filter((v) => v.id !== data.id || v?._id !== data?._id);
    setSelectedUserList(filterData);
    setGetSelectedUserList(filterData);
    await enrollAssignmentDelete(data?._id);
    toast.success('Enrolled user deleted!', 'tc');
  };

  const handleClear = async (data) => {
    if (data) {
      const deleteUserIdList = data?.map((v) => v?._id);
      const payload = {
        ids: deleteUserIdList,
      };
      await allEnrollAssignmentDelete(payload);
      toast.success('Enrolled users deleted!', 'tc');
      GetUserList();
    }
  };

  const handleGetLinkedInStatus = async (data) => {
    try {
      // Set session data if available, otherwise retrieve from reference
      data = data?.sessionId ? data : linkedInDataRef.current;
      if (!data.sessionId) return null;

      // Fetch LinkedIn status
      const res = await getLinkedInStatus(data.sessionId);
      const sessionDetails = res?.sessionDetails;

      // Recursive call if sessionId is missing
      if (!sessionDetails?.sessionId) return handleGetLinkedInStatus(data);

      // Update LinkedIn data and mark connection success
      setLinkedInData(sessionDetails);
      setConnectLinkedIn(true);

      const { status } = sessionDetails;

      // Handle various LinkedIn statuses dynamically
      switch (status) {
        case linkedInStatus.IN_PROGRESS:
        case linkedInStatus.OTP_SUBMITTED:
          return handleGetLinkedInStatus(data);

        case linkedInStatus.VERIFICATION_REQUIRED_WITH_APP:
          if (!showLinkedinApp) {
            setShowLinkedinApp(true);
            return handleGetLinkedInStatus(data);
          }
          break;

        case linkedInStatus.INVALID_USER_RESPONSE:
          if (!showLinkedinVerification) {
            setShowLinkedinVerificationFailed(true);
            return handleGetLinkedInStatus(data);
          }
          break;

        case linkedInStatus.TRY_ANOTHER_WAY:
          if (!showLinkedinSMS) {
            setShowLinkedinSMS(true);
            return handleGetLinkedInStatus(data);
          }
          break;

        case linkedInStatus.OTP_REQUIRED:
          if (showLinkedinSMS) {
            handleGetLinkedInStatus(data);
            return setLinkedInError('');
          } else setShowLinkedinSMS(true);
          // if (showOtpPanel) {
          //   handleGetLinkedInStatus(data);
          //   return setLinkedInError('');
          // } else setShowOtpPanel(true);
          break;

        case linkedInStatus.OTP_INVALID:
          if (!showLinkedinSMS) {
            setShowLinkedinSMS(true);
          }
          setLinkedinOtpError('You have entered an invalid OTP. Please check and try again.');
          setLinkedInError('You have entered an invalid OTP. Please check and try again.');
          // setShowOtpPanel(true);
          break;

        case linkedInStatus.RE_AUTH:
          setShowLinkedinVerificationFailed(true);
          setLinkedInError('LinkedIn requires re-authentication. Please log in again.');
          break;

        case linkedInStatus.INVALID_CREDENTIAL:
        case linkedInStatus.FAILED:
          toast.error('Invalid LinkedIn credentials');
          setShowLinkedinVerificationFailed(true);
          setLinkedInError(
            'You have entered invalid LinkedIn credentials. Please fix them and try again.',
          );
          setLinkedInLoginCred({ email: '', password: '' });
          if (!showLinkedinVerification) {
            setShowLinkedinVerificationFailed(true);
          }
          resetLinkedInOtpPanel();
          break;

        case linkedInStatus.COMPLETED:
          setLinkedinOtpError('');
          toast.success('LinkedIn account connected successfully.');
          resetLinkedInOtpPanel();
          break;

        case linkedInStatus.VERIFICATION_REQUIRED:
          setLinkedInError('The verification has failed. Please check and try again.');
          resetLinkedInOtpPanel();
          break;
      }

      // Stop button loading
      setLinkedInConnectBtnLoading(false);
    } catch (error) {
      // Handle any unexpected errors
      console.error('Error while fetching LinkedIn status:', error);
      setLinkedInError('An error occurred while connecting to LinkedIn. Please try again.');
    }
  };

  // Helper function to reset OTP panel and fields
  const resetLinkedInOtpPanel = () => {
    setShowOtpPanel(false);
    setShowLinkedinSMS(false);
    setLinkedInOtp('');
    setShowLinkedinApp(false);
  };

  const submitLinkedInConnection = async () => {
    try {
      if (!linkedInDataRef.current?._id) {
        setLinkedInConnectBtnLoading(true);
        const res = await loginLinkedIn(
          userData.id,
          linkedInLoginCred.email,
          linkedInLoginCred.password,
          userData.userAgent,
          userData.screenResolution,
        );
        if (res.sessionDetails.sessionId) {
          setLinkedInData(res.sessionDetails);
          handleGetLinkedInStatus(res.sessionDetails);
        }
      } else if (linkedInOtp) {
        await checkLinkedInOtp(linkedInDataRef.current?.sessionId, {
          action: 'OTP',
          data: linkedInOtp,
        });
        setLinkedInConnectBtnLoading(true);
        setLinkedInError('');
        handleGetLinkedInStatus();
      } else if (userData.linkedinContractType !== linkedinContractType) {
        await new Promise((resolve, reject) =>
          dispatch(putUser({ id: userData.id, data: { linkedinContractType } }, resolve, reject)),
        );
        toast.success('Linked contract updated');
      }
    } catch (error) {
      console.error('Error: ', error);
      setLinkedInError(
        'You have entered invalid LinkedIn credentials. Please fix them and try again.',
      );
      setLinkedInConnectBtnLoading(false);
      setLinkedInOtp('');
      setShowOtpPanel(false);
      setLinkedInLoginCred({ email: '', password: '' });
    }
  };
  // { "action" : "RESEND" // "RESEND" or "OTP" or "TRY_ANOTHER_WAY" }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await getLinkedInStatus(userData.linkedinSessionId);
        if (res.sessionDetails?.status === linkedInStatus.COMPLETED) {
          setLinkedInData(res.sessionDetails);
          setConnectLinkedIn(true);
        }
      } catch (e) {}
    };
    if (userData.linkedinSessionId) fetchData();
  }, [userData.linkedinSessionId]);

  const linkedInConnectioComplete =
    linkedInDataRef.current?.sessionId &&
    linkedInDataRef.current?.status === linkedInStatus.COMPLETED;

  const isLinkedinActive = useFeatureAccess(FeatureCaseKeys.LINKEDIN_AUTOMATION) || false;
  const emailVerification = useFeatureAccess(FeatureCaseKeys.EMAIL_VERIFICATION) || false;

  const disconnectLinkedin = async () => {
    setDisconnectLoadingBtn(true);
    await new Promise((resolve, reject) =>
      dispatch(
        putUser(
          {
            id: userData.id,
            data: { linkedinSessionId: '', linkedinContractType: '' },
          },
          resolve,
          reject,
        ),
      ),
    );
    setDisconnectLoadingBtn(false);
    setLinkedInData({});
    setLinkedinContractType('');
    setLinkedInLoginCred({ email: '', password: '' });
    setDiconnectLinkedInModal(false);
  };

  return (
    <>
      <>
        <CheckWithAppDialog
          open={showLinkedinApp}
          onResend={async () => {
            await checkLinkedInOtp(linkedInDataRef.current?.sessionId, {
              action: 'RESEND',
            });
            handleGetLinkedInStatus();
          }}
          onSMSClick={async () => {
            await checkLinkedInOtp(linkedInDataRef.current?.sessionId, {
              action: 'TRY_ANOTHER_WAY',
            });
            handleGetLinkedInStatus();
          }}
        />
        <SecurityVerification
          linkedInOtp={linkedInOtp}
          setLinkedInOtp={setLinkedInOtp}
          open={showLinkedinSMS}
          setShowLinkedinSMS={setShowLinkedinSMS}
          linkdinOtpError={linkdinOtpError}
          setLinkedinOtpError={setLinkedinOtpError}
          onSMSClick={async () => {
            await submitLinkedInConnection();
          }}
          loading={linkedInConnectBtnLoading}
        />
        <SecurityVerificationFailed
          open={showLinkedinVerification}
          onClose={() => setShowLinkedinVerificationFailed(false)}
        />
      </>
      <Form
        initialValues={{
          weeklyWindow:
            editUser?.schedulingWindow?.weeklyWindow &&
            Array.isArray(editUser?.schedulingWindow?.weeklyWindow)
              ? editUser?.schedulingWindow?.weeklyWindow
              : [1, 2, 3, 4, 5],
          dailyWindow: {
            start: getStartTime(),
            end: getEndTime(),
          },
          emailLimit: currentUser.emailLimit || '',
        }}
        validationSchema={Yup.object().shape({
          emailLimit: Yup.number()
            .typeError("That doesn't look like a number")
            .positive("Limit can't start with a minus")
            .integer("Limit can't include a decimal point")
            .min(1)
            .required('An Email Limit is Required.'),
          weeklyWindow: Yup.array().test({
            message: () => 'Please choose at least one day.',
            test: (values) => {
              if (!values) return false;
              return values?.length !== 0; // empty {}
            },
          }),
          dailyWindow: Yup.object().test(
            'dailyWindow',
            'dailyWindow',
            async (values, { createError }) => {
              const today = moment().format('YYYY-MM-DD');
              const start = moment(today + ' ' + values.start);
              const end = moment(today + ' ' + values.end);
              if (!regex24hour.test(values.start))
                return createError({
                  message: 'Your start time must be of a valid format.',
                  path: 'dailyWindow',
                });

              if (!regex24hour.test(values.end))
                return createError({
                  message: 'Your end time must be of a valid format.',
                  path: 'dailyWindow',
                });

              if (start > end) {
                return createError({
                  message: 'Your start time must be before your end time.',
                  path: 'dailyWindow',
                });
              }
              const difference = moment.duration(end.diff(start)).asHours();
              if (difference < 7) {
                return createError({
                  message:
                    'Please choose a window of at least 7 hours between the start and date time.',
                  path: 'dailyWindow',
                });
                return false;
              }
              if (difference > 12) {
                return createError({
                  message:
                    'Please choose a window that does not exceed 12 hours between the start and date time.',
                  path: 'dailyWindow',
                });
                return false;
              }
              return true;
            },
          ),
        })}
        enableReinitialize={true}
        onSubmit={handleSubmit}
        validateOnBlur={true}
        validateOnChange={false}
      >
        {({ values, ...formProps }) => {
          return (
            <form
              onSubmit={(e) => {
                e.preventDefault();
                formProps.submitForm();
                return false;
              }}
              style={{ height: '100%' }}
              noValidate
            >
              {isLinkedinActive && (
                <React.Fragment>
                  <ButtonGroup
                    sx={{
                      gap: '8px',
                      border: '1px solid rgba(0, 0, 0, 0.12)',
                      padding: '4px',

                      '& button': {
                        color: 'rgba(0, 0, 0, 0.60)',
                        borderColor: 'transparent',
                        width: '100px',
                        borderRadius: '6px !important',

                        '&.active': {
                          background: 'rgba(25, 118, 210, 0.08)',
                          color: '#1976D2',
                        },
                      },
                    }}
                  >
                    <Button
                      className={linkType === LinkType.email ? 'active' : ''}
                      onClick={() => setLinkType(LinkType.email)}
                      color="secondary"
                    >
                      Email
                    </Button>
                    <Button
                      className={linkType === LinkType.linkedIn ? 'active' : ''}
                      onClick={() => setLinkType(LinkType.linkedIn)}
                      color="secondary"
                    >
                      LinkedIn
                    </Button>
                  </ButtonGroup>

                  <Spacer y={2} />
                </React.Fragment>
              )}

              {isLinkedinActive && linkType === LinkType.linkedIn ? (
                <React.Fragment>
                  <Accordion
                    title={
                      <Typography variant="h3" className={'accordion-title'}>
                        Connect LinkedIn Accounts
                      </Typography>
                    }
                    subTitle="Your LinkedIn credentials are fully encrypted and required to perform automated actions in a background. We do not have direct access and control over your LinkedIn account."
                    sx={AccordionStyle}
                    icon={<ArrowRightIcon />}
                  >
                    <Container>
                      {(linkedInConnectioComplete || !connectLinkedIn) && (
                        <Stack>
                          <Stack
                            sx={{
                              padding: '30px',
                              background: 'rgba(0, 0, 0, 0.04)',
                              flexFlow: 'row wrap',
                              justifyContent: 'space-between',
                              gap: '40px',
                            }}
                          >
                            <Stack sx={{ flexDirection: 'row', gap: '16px', alignItems: 'center' }}>
                              <Icon
                                name="linkedin"
                                style={{
                                  width: '40px',
                                  height: '40px',
                                  '& path': { color: '#1976D2' },
                                }}
                              />
                              <Typography
                                sx={{
                                  fontSize: '18px',
                                  fontWeight: '500',
                                  color: 'rgba(0, 0, 0, 0.87)',
                                }}
                              >
                                {linkedInDataRef.current?._id
                                  ? linkedInDataRef.current?.userName
                                  : 'LinkedIn Accounts'}
                              </Typography>
                            </Stack>
                            <Button
                              variant={
                                linkedInDataRef.current?.status === linkedInStatus.RE_AUTH
                                  ? 'contained'
                                  : 'contained'
                              }
                              color={
                                linkedInDataRef.current?.status === linkedInStatus.RE_AUTH
                                  ? 'error'
                                  : 'secondary'
                              }
                              onClick={() => {
                                if (linkedInDataRef.current?.status === linkedInStatus.RE_AUTH)
                                  disconnectLinkedin();
                                else if (linkedInDataRef.current?._id)
                                  setDiconnectLinkedInModal(true);
                                else setConnectLinkedIn(true);
                              }}
                            >
                              {linkedInDataRef.current?.status === linkedInStatus.RE_AUTH
                                ? 'Reconnect your account'
                                : linkedInDataRef.current?._id
                                ? 'Remove Account'
                                : 'Connect'}
                            </Button>
                          </Stack>
                          {linkedInConnectioComplete && (
                            <React.Fragment>
                              <Spacer y={4} />
                              <Stack sx={{ flexDirection: 'row', gap: '30px' }}>
                                <Stack
                                  sx={{ flex: 1, gap: '10px', '& > div': { margin: '8px 0 0' } }}
                                >
                                  <Typography
                                    sx={{ fontSize: '18px', fontWeight: 500, color: 'black' }}
                                  >
                                    LinkedIn Contract
                                  </Typography>
                                  <Typography
                                    sx={{ fontSize: '14px', color: 'rgba(0, 0, 0, 0.6)' }}
                                  >
                                    {`Applicable only to Sales Navigator / Recruiter users with multiple LinkedIn contracts`}
                                  </Typography>
                                </Stack>
                                <Stack
                                  sx={{
                                    flex: 1,
                                    alignSelf: 'end',
                                    '& > div': { margin: '8px 0 0' },
                                  }}
                                >
                                  <Form.Field.Select
                                    options={[]}
                                    variant="outlined"
                                    optLabel="label"
                                    optValue="value"
                                    placeholder="Choose contract"
                                    value={null}
                                  />
                                </Stack>
                              </Stack>
                            </React.Fragment>
                          )}
                        </Stack>
                      )}
                      {connectLinkedIn && linkedInData.status !== linkedInStatus.COMPLETED && (
                        <Stack>
                          <Stack sx={{ flexDirection: 'row', gap: '30px' }}>
                            <Stack
                              sx={{
                                flex: 1,
                                '& > div': { margin: '8px 0 0' },
                                '& .MuiFormControl-root': {
                                  background: linkedInLoginCred.email ? '' : '#EEE !important',
                                },
                              }}
                            >
                              <Typography
                                sx={{ fontSize: '14px', fontWeight: 500, color: 'black' }}
                              >
                                LinkedIn Account Email
                              </Typography>
                              <Form.Field.Input
                                value={linkedInLoginCred.email}
                                onChange={(email) =>
                                  setLinkedInLoginCred((prevState) => {
                                    return {
                                      ...prevState,
                                      email,
                                    };
                                  })
                                }
                                fullWidth
                                variant="outlined"
                                name="email"
                                type="email"
                              />
                            </Stack>
                            <Stack
                              sx={{
                                flex: 1,
                                '& > div': { margin: '8px 0 0' },
                                '& .MuiFormControl-root': {
                                  background: linkedInLoginCred.password ? '' : '#EEE !important',
                                },
                              }}
                            >
                              <Typography
                                sx={{ fontSize: '14px', fontWeight: 500, color: 'black' }}
                              >
                                LinkedIn Password
                              </Typography>
                              <Form.Field.Input
                                fullWidth
                                value={linkedInLoginCred.password}
                                onChange={(password) =>
                                  setLinkedInLoginCred((prevState) => {
                                    return {
                                      ...prevState,
                                      password,
                                    };
                                  })
                                }
                                variant="outlined"
                                type="password"
                                name="password"
                              />
                            </Stack>
                          </Stack>
                          {/*{showOtpPanel && (*/}
                          {/*  <React.Fragment>*/}
                          {/*    <Spacer y={3} />*/}
                          {/*    <Stack sx={{ flexDirection: 'row', gap: '30px' }}>*/}
                          {/*      <Stack sx={{ flex: 1, '& > div': { margin: '8px 0 0' } }}>*/}
                          {/*        <Typography*/}
                          {/*          sx={{ fontSize: '14px', fontWeight: 500, color: 'black' }}*/}
                          {/*        >*/}
                          {/*          Security Code*/}
                          {/*        </Typography>*/}
                          {/*        <Form.Field.Input*/}
                          {/*          value={linkedInOtp}*/}
                          {/*          onChange={setLinkedInOtp}*/}
                          {/*          fullWidth*/}
                          {/*          variant="outlined"*/}
                          {/*          name="email"*/}
                          {/*        />*/}
                          {/*      </Stack>*/}
                          {/*      <Stack*/}
                          {/*        sx={{*/}
                          {/*          flex: 1,*/}
                          {/*          alignSelf: 'end',*/}
                          {/*          '& > div': { margin: '8px 0 0' },*/}
                          {/*        }}*/}
                          {/*      >*/}
                          {/*        <Typography*/}
                          {/*          sx={{*/}
                          {/*            fontSize: '14px',*/}
                          {/*            fontWeight: 500,*/}
                          {/*            color: 'black',*/}
                          {/*            whiteSpace: 'pre-line',*/}
                          {/*          }}*/}
                          {/*        >*/}
                          {/*          {`Please insert the 6 digit verification code  sent to you by LinkedIn*/}
                          {/*    to completed the verification process.*/}
                          {/*    If not received the code check your spam folder.`}*/}
                          {/*        </Typography>*/}
                          {/*      </Stack>*/}
                          {/*    </Stack>*/}
                          {/*  </React.Fragment>*/}
                          {/*)}*/}
                          {linkedInError && (
                            <React.Fragment>
                              <Spacer y={3} />
                              <Alert
                                sx={{ padding: '8px 16px !important' }}
                                variant="outlined"
                                severity="error"
                                onClose={() => setLinkedInError('')}
                              >
                                {linkedInError}
                              </Alert>
                            </React.Fragment>
                          )}
                        </Stack>
                      )}
                      {connectLinkedIn && (
                        <React.Fragment>
                          <Spacer y={4} />
                          <Stack
                            sx={{
                              width: '100%',
                              gap: '24px',
                              alignItems: linkedInConnectioComplete ? 'flex-end' : 'flex-start',
                            }}
                          >
                            <Button
                              sx={{
                                width: 'fit-content',
                                '&:disabled': {
                                  backgroundColor: 'rgba(0, 0, 0, 0.38)',
                                  color: '#fff',
                                },
                              }}
                              variant={'contained'}
                              color="secondary"
                              loading={linkedInConnectBtnLoading}
                              onClick={submitLinkedInConnection}
                              disabled={
                                linkedInConnectBtnLoading ||
                                (!linkedInData?._id &&
                                  (!linkedInLoginCred.email || !linkedInLoginCred.password))
                              }
                            >
                              {linkedInConnectioComplete ? 'Update' : 'Connect'}
                            </Button>
                          </Stack>
                        </React.Fragment>
                      )}
                    </Container>
                    <ConfirmDialog
                      sx={{ '& .MuiDialog-paper': { borderRadius: '10px' } }}
                      title={'Disconnect'}
                      open={diconnectLinkedInModal}
                      onClose={() => setDiconnectLinkedInModal(false)}
                      buttonTitle={'Yes, Continue'}
                      loading={disconnectLoadingBtn}
                      confirmButtonVariant="contained"
                      onConfirm={disconnectLinkedin}
                      showCancelButton
                      cancelButtonVariant={'outlined'}
                      cancelButtonColor={'secondary'}
                      closeIconColor={'#00000042'}
                    >
                      <Typography
                        sx={{
                          fontSize: '16px',
                          color: '#000000DE',
                          whiteSpace: 'pre-line',
                        }}
                      >
                        {`Are you sure you want to disconnect account?  
                      This will stop further LinkedIn activity.`}
                      </Typography>
                    </ConfirmDialog>
                  </Accordion>
                  <Spacer y={2} />
                  <Accordion
                    title={
                      <Typography variant="h3" className={'accordion-title'}>
                        LinkedIn actions limits
                      </Typography>
                    }
                    subTitle="Protect your LinkedIn reputation by limiting the numbers of activities executed:"
                    sx={AccordionStyle}
                    icon={<ArrowRightIcon />}
                  >
                    {Object.keys(linkedInActionData?.linkedinAutomationSettings)?.map((action) => {
                      return (
                        <Stack
                          sx={{
                            flexDirection: 'row',
                            gap: '32px',
                            alignItems: 'center',
                            padding: '15px 30px',
                          }}
                        >
                          <Typography
                            sx={{
                              width: 'clamp(240px, 100%, 30%)',
                              color: 'rgba(0, 0, 0, 0.60)',
                              fontFamily: 'Inter',
                              fontSize: '14px',
                              fontWeight: 500,
                              lineHeight: '24px',
                            }}
                          >
                            {linkedInAutomationSetting[action]}
                          </Typography>
                          <Form.Field.Number
                            sx={{
                              aspectRatio: 1.6,
                              justifyContent: 'center',
                              margin: 0,
                              width: '60px',
                              borderRadius: '5px',
                              background: '#FAFAFA',
                              boxShadow:
                                '0px 0px 2px 0px rgba(0, 0, 0, 0.05), 0px 0px 4px 0px rgba(0, 0, 0, 0.05), 0px 0px 2px 0px rgba(0, 0, 0, 0.05)',
                              '& input': {
                                textAlign: 'center !important',
                                color: '#1976D2',
                                fontFamily: 'Inter',
                                fontSize: '14px',
                                fontWeight: 700,
                              },
                              '& .MuiInputBase-root::before, .MuiInputBase-root::after': {
                                borderBottom: 'none !important',
                              },
                            }}
                            type="number"
                            hideCustomComponent
                            value={linkedInActionData.linkedinAutomationSettings?.[action]}
                            maxValue={linkedInAutomationSettingBaseLimit[action]}
                            onChange={(value) => {
                              let updateValue = 0;
                              if (value <= linkedInAutomationSettingBaseLimit[action]) {
                                updateValue = value;
                              } else if (value) {
                                return;
                              }
                              setLinkedInActionData((prevState) => {
                                return {
                                  ...prevState,
                                  linkedinAutomationSettings: {
                                    ...prevState.linkedinAutomationSettings,
                                    [action]: updateValue,
                                  },
                                };
                              });
                            }}
                          />
                          <Stack sx={{ width: '449px !important' }}>
                            <Stack justifyContent={'space-between'} flexDirection={'row'}>
                              <Typography variant="caption" sx={{ color: 'rgba(0, 0, 0, 0.60)' }}>
                                0
                              </Typography>
                              <Typography variant="caption" sx={{ color: 'rgba(0, 0, 0, 0.60)' }}>
                                {linkedInAutomationSettingBaseLimit[action]}
                              </Typography>
                            </Stack>
                            <Slider
                              onChange={(_, value) => {
                                let updateValue = 0;
                                if (value <= linkedInAutomationSettingBaseLimit[action]) {
                                  updateValue = value;
                                } else if (value) {
                                  return;
                                }
                                setLinkedInActionData((prevState) => {
                                  return {
                                    ...prevState,
                                    linkedinAutomationSettings: {
                                      ...prevState.linkedinAutomationSettings,
                                      [action]: updateValue,
                                    },
                                  };
                                });
                              }}
                              value={linkedInActionData.linkedinAutomationSettings?.[action]}
                              max={linkedInAutomationSettingBaseLimit[action]}
                              sx={{
                                flexGrow: 1,
                                color: '#EEEEEE',
                                height: 8,
                                '& .MuiSlider-track': {
                                  border: 'none',
                                  height: '10px',
                                  background:
                                    'linear-gradient(270deg, #1976D2 2.99%, rgba(25, 118, 210, 0.00) 150.43%);',
                                },
                                '& .MuiSlider-rail': {
                                  background: '#D2D2D2',
                                },
                                '& .MuiSlider-thumb': {
                                  height: 16,
                                  width: 16,
                                  boxShadow: '0 0 1px #0000007a',
                                  backgroundColor: 'rgba(25, 118, 210, 1)',
                                  border: '2px solid white',
                                  '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
                                    boxShadow: 'inherit',
                                  },
                                  '&::before': {
                                    display: 'none',
                                  },
                                },
                              }}
                              valueLabelDisplay="auto"
                            />
                          </Stack>
                        </Stack>
                      );
                    })}
                    <Stack sx={{ padding: '30px' }}>
                      <Button
                        onClick={async () => {
                          const res = await setLinkedInAutomationSetting(linkedInActionData);
                          const data = res.user[0];
                          if (data.id) {
                            toast.success(`Success! Setting has been updated.`);
                            dispatch(putUser(data));
                          }
                        }}
                        sx={{ padding: '8px 22px', width: 'fit-content', marginLeft: 'auto' }}
                        variant={'contained'}
                        color="secondary"
                      >
                        Update
                      </Button>
                    </Stack>
                  </Accordion>
                  <Spacer y={2} />
                  <Accordion
                    title={
                      <Stack direction="row" spacing={1}>
                        <Typography variant="h3" className={'accordion-title'}>
                          LinkedIn Settings
                        </Typography>
                        <Box
                          onMouseEnter={(val) => {
                            setPopoverKey('settings');
                            handlePopoverOpen(val);
                          }}
                          onMouseLeave={handlePopoverClose}
                        >
                          <InfoIcon sx={{ fontSize: '20px', color: '#00000042 !important' }} />
                        </Box>
                      </Stack>
                    }
                    subTitle="Choose the days of the week and the hours you want your LinkedIn automation to be sent."
                    sx={AccordionStyle}
                    icon={<ArrowRightIcon />}
                  >
                    <Container>
                      <Stack direction="row" spacing={'12px'}>
                        {daysOfWeek.map((day, index) => {
                          return (
                            <ButtonBase
                              sx={{ cursor: 'pointer' }}
                              onClick={() => {
                                let newVal = [
                                  ...linkedInActionData?.linkedinSchedulingWindow?.weeklyWindow,
                                ];
                                if (newVal.includes(day.value))
                                  newVal = newVal.filter((id) => id !== day.value);
                                else newVal.push(day.value);
                                setLinkedInActionData((prevState) => {
                                  return {
                                    ...prevState,
                                    linkedinSchedulingWindow: {
                                      ...prevState.linkedinSchedulingWindow,
                                      weeklyWindow: newVal,
                                    },
                                  };
                                });
                              }}
                              key={`outreach-day-${index}`}
                            >
                              <DayChip
                                label={day.label}
                                variant={
                                  linkedInActionData?.linkedinSchedulingWindow?.weeklyWindow?.includes(
                                    day.value,
                                  )
                                    ? 'contained'
                                    : 'outlined'
                                }
                                color={
                                  linkedInActionData?.linkedinSchedulingWindow?.weeklyWindow?.includes(
                                    day.value,
                                  )
                                    ? 'secondary'
                                    : 'default'
                                }
                              />
                            </ButtonBase>
                          );
                        })}
                      </Stack>
                      <Spacer x={2} y={2} />
                      <Stack
                        direction="row"
                        spacing={'20px !important'}
                        alignItems="center"
                        sx={{ width: 618 }}
                      >
                        <Box flex={1}>
                          <Form.Field.Timepicker
                            options={daysOfWeek}
                            fullWidth
                            value={linkedInActionData?.linkedinSchedulingWindow?.dailyWindow?.start}
                            onChange={(val) => {
                              setLinkedInActionData((prevState) => {
                                return {
                                  ...prevState,
                                  linkedinSchedulingWindow: {
                                    ...prevState.linkedinSchedulingWindow,
                                    dailyWindow: {
                                      ...prevState.linkedinSchedulingWindow.dailyWindow,
                                      start: val,
                                    },
                                  },
                                };
                              });
                            }}
                            variant="outlined"
                            optLabel="name"
                            optValue="id"
                          />
                        </Box>
                        <Typography sx={{ margin: '0 !important' }}>to</Typography>
                        <Box sx={{ margin: '0 !important' }} mb={1} flex={1}>
                          <Form.Field.Timepicker
                            fullWidth
                            options={daysOfWeek}
                            value={linkedInActionData?.linkedinSchedulingWindow?.dailyWindow?.end}
                            onChange={(val) => {
                              setLinkedInActionData((prevState) => {
                                return {
                                  ...prevState,
                                  linkedinSchedulingWindow: {
                                    ...prevState.linkedinSchedulingWindow,
                                    dailyWindow: {
                                      ...prevState.linkedinSchedulingWindow.dailyWindow,
                                      end: val,
                                    },
                                  },
                                };
                              });
                            }}
                            variant="outlined"
                            optLabel="name"
                            optValue="id"
                          />
                        </Box>
                      </Stack>

                      <Stack sx={{ padding: '30px 6px 0px 6px' }}>
                        <Button
                          onClick={async () => {
                            const res = await setLinkedInAutomationSetting(linkedInActionData);
                            const data = res.user[0];
                            if (data.id) {
                              toast.success(`Success! Setting has been updated.`);
                              dispatch(putUser(data));
                            }
                          }}
                          sx={{ padding: '8px 22px', width: 'fit-content', marginLeft: 'auto' }}
                          variant={'contained'}
                          color="secondary"
                        >
                          Update
                        </Button>
                      </Stack>
                    </Container>
                  </Accordion>
                  <Spacer y={2} />
                  <Accordion
                    title={
                      <Typography variant="h3" className={'accordion-title'}>
                        Custom holidays
                      </Typography>
                    }
                    subTitle="Choose the holidays you want your outreach to be paused."
                    sx={AccordionStyle}
                    icon={<ArrowRightIcon />}
                  >
                    <Container>
                      <Box direction={'column'}>
                        <Stack direction="column" gap={'6px'} spacing={2}>
                          {linkedInActionData?.linkedinHolidaySettings?.map((holiday, index) => {
                            return (
                              <Stack
                                direction={'row'}
                                marginTop={'0 !important'}
                                alignItems={'center'}
                                gap={'20px'}
                                key={index}
                              >
                                <Typography variant="subtitle1" color={'#000'}>
                                  {moment(holiday?.eventDate).format('DD/MM/YYYY')} -{' '}
                                  {moment(holiday?.eventEndDate).format('DD/MM/YYYY')}
                                </Typography>
                                <Button
                                  iconButton={true}
                                  edge="end"
                                  aria-label="Delete date"
                                  onClick={(val) => {
                                    setPopoverKey('holiday');
                                    setEditHoliday({
                                      index,
                                      startDate: new Date(holiday?.eventDate),
                                      endDate: new Date(holiday?.eventEndDate),
                                    });
                                    handlePopoverOpen(val);
                                  }}
                                >
                                  <EditIcon />
                                </Button>
                                <Button
                                  iconButton={true}
                                  edge="end"
                                  aria-label="Delete date"
                                  onClick={() => setDeleteHolidayModal(index?.toString())}
                                >
                                  <DeleteIcon />
                                </Button>
                              </Stack>
                            );
                          })}
                        </Stack>
                        <Spacer y={2} />
                        <Button
                          variant="text"
                          color="secondary"
                          loading={holidayBtnLoading}
                          onClick={(val) => {
                            setPopoverKey('holiday');
                            handlePopoverOpen(val);
                          }}
                        >
                          + Add holiday
                        </Button>
                        <Popover
                          open={open && popOverKey === 'holiday'}
                          anchorEl={anchorEl}
                          onClose={handlePopoverClose}
                          anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                          }}
                        >
                          <DateRangePickerAlt
                            toggle={handlePopoverClose}
                            initialDateRange={
                              holidayRef?.current?.startDate && holidayRef?.current?.endDate
                                ? {
                                    startDate: holidayRef?.current?.startDate,
                                    endDate: holidayRef?.current?.endDate,
                                  }
                                : {}
                            }
                            minDate={new Date()}
                            onChange={async (val) => {
                              if (
                                new Date(val.startDate).setHours(0, 0, 0, 0) <
                                new Date().setHours(0, 0, 0, 0)
                              )
                                return toast.error(
                                  `Please select a future date for the holiday`,
                                  'tc',
                                );
                              const isValid = linkedInActionData?.linkedinHolidaySettings.find(
                                (existingRange, index) => {
                                  if (holidayRef?.current?.index === index) return false;
                                  else if (
                                    (new Date(existingRange?.eventDate).setHours(0, 0, 0, 0) >
                                      new Date(val?.startDate).setHours(0, 0, 0, 0) ||
                                      new Date(val?.startDate).setHours(0, 0, 0, 0) >
                                        new Date(existingRange?.eventEndDate).setHours(
                                          0,
                                          0,
                                          0,
                                          0,
                                        )) &&
                                    (new Date(existingRange?.eventDate).setHours(0, 0, 0, 0) >
                                      new Date(val?.endDate).setHours(0, 0, 0, 0) ||
                                      new Date(val?.endDate).setHours(0, 0, 0, 0) >
                                        new Date(existingRange?.eventEndDate).setHours(
                                          0,
                                          0,
                                          0,
                                          0,
                                        )) &&
                                    !(
                                      new Date(existingRange?.eventDate).setHours(0, 0, 0, 0) >
                                        new Date(val?.startDate).setHours(0, 0, 0, 0) &&
                                      new Date(val?.endDate).setHours(0, 0, 0, 0) >
                                        new Date(existingRange?.eventEndDate).setHours(0, 0, 0, 0)
                                    )
                                  ) {
                                    return false;
                                  } else return true;
                                },
                              );
                              if (!!isValid)
                                return toast.error(
                                  'Selected date already exist, Please check existing holidays!',
                                  'tc',
                                );
                              let updatedHolidays = [];
                              if (typeof holidayRef?.current?.index === 'number') {
                                const newData = [...linkedInActionData?.linkedinHolidaySettings];
                                newData[holidayRef?.current?.index] = {
                                  eventDate: moment(val?.startDate).format('YYYY-MM-DD'),
                                  eventEndDate: moment(val?.endDate).format('YYYY-MM-DD'),
                                };
                                updatedHolidays = newData;
                              } else
                                updatedHolidays = [
                                  ...linkedInActionData?.linkedinHolidaySettings,
                                  {
                                    eventDate: moment(val?.startDate).format('YYYY-MM-DD'),
                                    eventEndDate: moment(val?.endDate).format('YYYY-MM-DD'),
                                  },
                                ];
                              setEditHoliday(null);
                              const updatedLinkedInActionData = {
                                ...linkedInActionData,
                                linkedinHolidaySettings: updatedHolidays,
                              };
                              const res = await setLinkedInAutomationSetting(
                                updatedLinkedInActionData,
                              );
                              const data = res.user[0];
                              if (data.id) {
                                toast.success(`Success! Setting has been updated.`);
                                dispatch(putUser(data));
                              }
                              setLinkedInActionData(updatedLinkedInActionData);
                              handlePopoverClose();
                            }}
                          />
                        </Popover>
                      </Box>
                      <Modal
                        open={deleteHolidayModal}
                        title="Remove holiday"
                        okText="wertyb "
                        onClose={() => setDeleteHolidayModal(false)}
                      >
                        <Typography variant="h4">
                          Are you sure you want to remove holiday? All your emails for the scheduled
                          period will be resumed.
                        </Typography>

                        <Spacer y={2} />

                        <Stack direction="row" gap={'24px'} justifyContent={'flex-end'}>
                          <Button
                            variant="outlined"
                            color="secondary"
                            onClick={() => setDeleteHolidayModal(false)}
                          >
                            Cancel
                          </Button>
                          <Button
                            variant="contained"
                            color="secondary"
                            loading={holidayBtnLoading}
                            onClick={async () => {
                              const newData = [...linkedInActionData?.linkedinHolidaySettings];
                              newData.splice(Number(deleteHolidayModal), 1);
                              const updatedLinkedInActionData = {
                                ...linkedInActionData,
                                linkedinHolidaySettings: newData,
                              };
                              const res = await setLinkedInAutomationSetting(
                                updatedLinkedInActionData,
                              );
                              setLinkedInActionData(updatedLinkedInActionData);
                              const data = res.user[0];
                              if (data.id) {
                                toast.success(`Success! Setting has been updated.`);
                                dispatch(putUser(data));
                              }
                              setDeleteHolidayModal(false);
                            }}
                          >
                            Yes, Continue
                          </Button>
                        </Stack>
                      </Modal>
                    </Container>
                  </Accordion>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <Box sx={{ mb: 2 }}>
                    <Item>
                      {props && (
                        <ConnectEmail
                          editUser={userData}
                          status={props.status}
                          {...props}
                          putUser={putUser}
                        />
                      )}
                    </Item>
                  </Box>

                  <Box sx={{ display: 'flex', gap: '15px', flexDirection: 'column' }}>
                    <Accordion
                      title={
                        <Stack direction="row" spacing={1}>
                          <Typography variant="h3" className={'accordion-title'}>
                            Outreach Sending Schedule
                          </Typography>
                          <Box
                            onMouseEnter={(val) => {
                              setPopoverKey('settings');
                              handlePopoverOpen(val);
                            }}
                            onMouseLeave={handlePopoverClose}
                          >
                            <InfoIcon sx={{ fontSize: '20px', color: '#00000042 !important' }} />
                          </Box>
                        </Stack>
                      }
                      subTitle="Choose the days of the week and the hours you want your email outreach to be
                    sent."
                      sx={{
                        '&.MuiAccordion-root': {
                          borderBottom: 'none !important',
                          boxShadow:
                            '0px 3px 1px -2px rgba(0, 0, 0, 0.20), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12) !important',
                        },
                        '& .MuiAccordionSummary-content': {
                          margin: '0px !important',
                        },
                        '& .MuiAccordionSummary-root': {
                          borderBottom: 'none !important',
                          padding: '20px 20px 20px 20px !important',

                          '& .accordion-title': {
                            color: '#000000 !important',
                            fontSize: '18px',
                            fontFamily: 'Roboto',
                            letterSpacing: '-0.5px',
                            fontWeight: 500,
                          },
                          '& .accordion-sub-title': {
                            display: 'none',
                          },
                        },
                        '& .Mui-expanded': {
                          '& .accordion-sub-title': {
                            display: 'block',
                          },
                          '& .MuiSvgIcon-root': {
                            color: '#0000008A !important',
                          },
                        },
                        '& .MuiPaper-root': {
                          padding: '10px 20px 20px 20px !important',
                          boxShadow: 'none !important',
                        },
                      }}
                      icon={<ArrowRightIcon />}
                    >
                      <Container>
                        <Stack direction="row" spacing={'12px'}>
                          {daysOfWeek.map((day, index) => {
                            return (
                              <ButtonBase
                                sx={{ width: '78px', cursor: 'pointer' }}
                                onClick={() => {
                                  let newVal = [...values.weeklyWindow];
                                  if (newVal.includes(day.value)) {
                                    newVal = newVal.filter((id) => id !== day.value);
                                  } else {
                                    newVal.push(day.value);
                                  }
                                  formProps.setFieldValue('weeklyWindow', newVal);
                                }}
                                key={`outreach-day-${index}`}
                              >
                                <DayChip
                                  label={day.label}
                                  variant={
                                    values.weeklyWindow.includes(day.value)
                                      ? 'contained'
                                      : 'outlined'
                                  }
                                  color={
                                    values.weeklyWindow.includes(day.value)
                                      ? 'secondary'
                                      : 'default'
                                  }
                                />
                              </ButtonBase>
                            );
                          })}
                        </Stack>

                        {formProps?.errors?.weeklyWindow &&
                          formProps?.errors?.weeklyWindow !== '' && (
                            <>
                              <Spacer x={1} y={1} />
                              <FormHelperText error>
                                {formProps?.errors?.weeklyWindow}
                              </FormHelperText>
                            </>
                          )}

                        <Spacer x={2} y={2} />
                        <Stack
                          direction="row"
                          spacing={2}
                          alignItems="center"
                          sx={{ width: 618, gap: '20px' }}
                        >
                          <Box flex={1}>
                            <Form.Field.Timepicker
                              options={daysOfWeek}
                              fullWidth
                              name="dailyWindow.start"
                              variant="outlined"
                              optLabel="name"
                              optValue="id"
                            />
                          </Box>
                          <Typography sx={{ margin: '0 !important' }}>to</Typography>
                          <Box sx={{ margin: '0 !important' }} mb={1} flex={1}>
                            <Form.Field.Timepicker
                              fullWidth
                              options={daysOfWeek}
                              name="dailyWindow.end"
                              variant="outlined"
                              optLabel="name"
                              optValue="id"
                            />
                          </Box>
                        </Stack>

                        {formProps?.errors?.dailyWindow &&
                          formProps?.errors?.dailyWindow !== '' && (
                            <FormHelperText error>{formProps?.errors?.dailyWindow}</FormHelperText>
                          )}
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                          <Button
                            variant="contained"
                            color="secondary"
                            sx={{ padding: '8px 22px' }}
                            loading={isLoading}
                            onClick={async () => {
                              let data = {
                                schedulingWindow: { ...values },
                              };
                              if (editUser && editUser?.id) {
                                const updatedRow = { id: editUser?.id, data: data };
                                setIsLoading(true);
                                await props.putUser(updatedRow);
                                setIsLoading(false);
                              }
                            }}
                          >
                            Update
                          </Button>
                        </Box>
                      </Container>
                    </Accordion>

                    <Accordion
                      title={
                        <Stack direction="row" spacing={1}>
                          <Typography variant="h3" className={'accordion-title'}>
                            Email Sending Limit
                          </Typography>
                          <Box
                            onMouseEnter={(val) => {
                              setPopoverKey('settings');
                              handlePopoverOpen(val);
                            }}
                            onMouseLeave={handlePopoverClose}
                          >
                            <InfoIcon sx={{ fontSize: '20px', color: '#00000042 !important' }} />
                          </Box>
                        </Stack>
                      }
                      subTitle="Protect your mailbox reputation by limiting the numbers of emails sent."
                      sx={{
                        '&.MuiAccordion-root': {
                          borderBottom: 'none !important',
                          boxShadow:
                            '0px 3px 1px -2px rgba(0, 0, 0, 0.20), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12) !important',
                        },
                        '& .MuiAccordionSummary-content': {
                          margin: '0px !important',
                        },
                        '& .MuiAccordionSummary-root': {
                          borderBottom: 'none !important',
                          padding: '20px 20px 20px 20px !important',

                          '& .accordion-title': {
                            color: '#000000 !important',
                            fontSize: '18px',
                            fontFamily: 'Roboto',
                            letterSpacing: '-0.5px',
                            fontWeight: 500,
                          },
                          '& .accordion-sub-title': {
                            display: 'none',
                          },
                        },
                        '& .Mui-expanded': {
                          '& .accordion-sub-title': {
                            display: 'block',
                          },
                          '& .MuiSvgIcon-root': {
                            color: '#0000008A !important',
                          },
                        },
                        '& .MuiPaper-root': {
                          padding: '10px 20px 20px 20px !important',
                          boxShadow: 'none !important',
                        },
                      }}
                      icon={<ArrowRightIcon />}
                    >
                      <Container sx={{ display: 'flex', gap: '30px', flexDirection: 'column' }}>
                        <Form.Field.Input
                          rows={4}
                          sx={{ maxWidth: '618px', width: '100%' }}
                          variant="outlined"
                          name="emailLimit"
                          label="Send Email Limit Per Day"
                        />
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                          <Button
                            variant="contained"
                            color="secondary"
                            type="submit"
                            sx={{ padding: '8px 22px' }}
                            loading={loading}
                          >
                            Update
                          </Button>
                        </Box>
                      </Container>
                    </Accordion>
                    <Accordion
                      title="Custom holidays"
                      subTitle="Choose the holidays you want your email outreach to be paused."
                      sx={{
                        '&.MuiAccordion-root': {
                          borderBottom: 'none !important',
                          boxShadow:
                            '0px 3px 1px -2px rgba(0, 0, 0, 0.20), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12) !important',
                        },
                        '& .MuiAccordionSummary-content': {
                          margin: '0px !important',
                        },
                        '& .MuiAccordionSummary-root': {
                          borderBottom: 'none !important',
                          padding: '20px 20px 20px 20px !important',

                          '& .accordion-title': {
                            color: '#000000 !important',
                            fontSize: '18px',
                            fontFamily: 'Roboto',
                            letterSpacing: '-0.5px',
                            fontWeight: 500,
                          },
                          '& .accordion-sub-title': {
                            display: 'none',
                          },
                        },
                        '& .Mui-expanded': {
                          '& .accordion-sub-title': {
                            display: 'block',
                          },
                          '& .MuiSvgIcon-root': {
                            color: '#0000008A !important',
                          },
                        },
                        '& .MuiPaper-root': {
                          padding: '10px 20px 20px 20px !important',
                          boxShadow: 'none !important',
                        },
                      }}
                      icon={<ArrowRightIcon />}
                    >
                      <Container>
                        <Box direction={'column'}>
                          <Stack direction="column" gap={'6px'} spacing={2}>
                            {holidays?.map((holiday, index) => {
                              return (
                                <Stack
                                  direction={'row'}
                                  marginTop={'0 !important'}
                                  alignItems={'center'}
                                  gap={'20px'}
                                  key={index}
                                >
                                  <Typography variant="subtitle1" color={'#000'}>
                                    {moment(holiday?.eventDate).format('DD MMMM YYYY')} -{' '}
                                    {moment(holiday?.eventEndDate).format('DD MMMM YYYY')}
                                  </Typography>
                                  <Button
                                    iconButton={true}
                                    edge="end"
                                    aria-label="Delete date"
                                    onClick={(val) => {
                                      setPopoverKey('holiday');
                                      setEditHoliday({
                                        index,
                                        startDate: new Date(holiday?.eventDate),
                                        endDate: new Date(holiday?.eventEndDate),
                                      });
                                      handlePopoverOpen(val);
                                    }}
                                  >
                                    <EditIcon />
                                  </Button>
                                  <Button
                                    iconButton={true}
                                    edge="end"
                                    aria-label="Delete date"
                                    onClick={() => setDeleteHolidayModal(index?.toString())}
                                  >
                                    <DeleteIcon />
                                  </Button>
                                </Stack>
                              );
                            })}
                          </Stack>
                          <Spacer y={2} />
                          <Button
                            variant="text"
                            color="secondary"
                            loading={holidayBtnLoading}
                            onClick={(val) => {
                              setPopoverKey('holiday');
                              handlePopoverOpen(val);
                            }}
                          >
                            + Add holiday
                          </Button>
                          <Popover
                            open={open && popOverKey === 'holiday'}
                            anchorEl={anchorEl}
                            onClose={handlePopoverClose}
                            anchorOrigin={{
                              vertical: 'bottom',
                              horizontal: 'left',
                            }}
                          >
                            <DateRangePickerAlt
                              toggle={handlePopoverClose}
                              initialDateRange={
                                holidayRef?.current?.startDate && holidayRef?.current?.endDate
                                  ? {
                                      startDate: holidayRef?.current?.startDate,
                                      endDate: holidayRef?.current?.endDate,
                                    }
                                  : {}
                              }
                              minDate={new Date()}
                              onChange={(val) => {
                                if (
                                  new Date(val.startDate).setHours(0, 0, 0, 0) <
                                  new Date().setHours(0, 0, 0, 0)
                                )
                                  return toast.error(
                                    `Please select a future date for the holiday`,
                                    'tc',
                                  );
                                const isValid = holidays.find((existingRange, index) => {
                                  if (holidayRef?.current?.index === index) return false;
                                  else if (
                                    (new Date(existingRange?.eventDate).setHours(0, 0, 0, 0) >
                                      new Date(val?.startDate).setHours(0, 0, 0, 0) ||
                                      new Date(val?.startDate).setHours(0, 0, 0, 0) >
                                        new Date(existingRange?.eventEndDate).setHours(
                                          0,
                                          0,
                                          0,
                                          0,
                                        )) &&
                                    (new Date(existingRange?.eventDate).setHours(0, 0, 0, 0) >
                                      new Date(val?.endDate).setHours(0, 0, 0, 0) ||
                                      new Date(val?.endDate).setHours(0, 0, 0, 0) >
                                        new Date(existingRange?.eventEndDate).setHours(
                                          0,
                                          0,
                                          0,
                                          0,
                                        )) &&
                                    !(
                                      new Date(existingRange?.eventDate).setHours(0, 0, 0, 0) >
                                        new Date(val?.startDate).setHours(0, 0, 0, 0) &&
                                      new Date(val?.endDate).setHours(0, 0, 0, 0) >
                                        new Date(existingRange?.eventEndDate).setHours(0, 0, 0, 0)
                                    )
                                  ) {
                                    return false;
                                  } else return true;
                                });
                                if (!!isValid)
                                  return toast.error(
                                    'Selected date already exist, Please check existing holidays!',
                                    'tc',
                                  );
                                let updatedHolidays = [];
                                if (typeof holidayRef?.current?.index === 'number') {
                                  const newData = [...holidays];
                                  newData[holidayRef?.current?.index] = {
                                    eventDate: moment(val?.startDate).format('YYYY-MM-DD'),
                                    eventEndDate: moment(val?.endDate).format('YYYY-MM-DD'),
                                  };
                                  updatedHolidays = newData;
                                } else
                                  updatedHolidays = [
                                    ...holidays,
                                    {
                                      eventDate: moment(val?.startDate).format('YYYY-MM-DD'),
                                      eventEndDate: moment(val?.endDate).format('YYYY-MM-DD'),
                                    },
                                  ];
                                setEditHoliday(null);
                                saveUserData({ holidaySettings: updatedHolidays });
                                setHolidays(updatedHolidays);
                                handlePopoverClose();
                              }}
                            />
                          </Popover>
                        </Box>
                        <Modal
                          open={deleteHolidayModal}
                          title="Remove holiday"
                          okText="wertyb "
                          onClose={() => setDeleteHolidayModal(false)}
                        >
                          <Typography variant="h4">
                            Are you sure you want to remove holiday? All your emails for the
                            scheduled period will be resumed.
                          </Typography>

                          <Spacer y={2} />

                          <Stack direction="row" gap={'24px'} justifyContent={'flex-end'}>
                            <Button
                              variant="outlined"
                              color="secondary"
                              onClick={() => setDeleteHolidayModal(false)}
                            >
                              Cancel
                            </Button>
                            <Button
                              variant="contained"
                              color="secondary"
                              loading={holidayBtnLoading}
                              onClick={async () => {
                                const newData = [...holidays];
                                newData.splice(Number(deleteHolidayModal), 1);
                                await saveUserData({ holidaySettings: newData });
                                setHolidays(newData);
                                setDeleteHolidayModal(false);
                              }}
                            >
                              Yes, Continue
                            </Button>
                          </Stack>
                        </Modal>
                      </Container>
                    </Accordion>
                    <Accordion
                      title="Auto-search data"
                      subTitle="Automatically call the data waterfall when adding contacts to SuperReach."
                      sx={{
                        '&.MuiAccordion-root': {
                          borderBottom: 'none !important',
                          boxShadow:
                            '0px 3px 1px -2px rgba(0, 0, 0, 0.20), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12) !important',
                        },
                        '& .MuiAccordionSummary-content': {
                          margin: '0px !important',
                        },
                        '& .MuiAccordionSummary-root': {
                          borderBottom: 'none !important',
                          padding: '20px 20px 20px 20px !important',

                          '& .accordion-title': {
                            color: '#000000 !important',
                            fontSize: '18px',
                            fontFamily: 'Roboto',
                            letterSpacing: '-0.5px',
                            fontWeight: 500,
                          },
                          '& .accordion-sub-title': {
                            display: 'none',
                          },
                        },
                        '& .Mui-expanded': {
                          '& .accordion-sub-title': {
                            display: 'block',
                          },
                          '& .MuiSvgIcon-root': {
                            color: '#0000008A !important',
                          },
                        },
                        '& .MuiPaper-root': {
                          padding: '10px 20px 20px 20px !important',
                          boxShadow: 'none !important',
                        },
                      }}
                      icon={<ArrowRightIcon />}
                    >
                      <Container>
                        <Stack
                          direction={'row'}
                          gap={'24px'}
                          alignItems={isShowAutoSearchError ? 'flex-start' : 'center'}
                        >
                          <Switch
                            checked={autoSearchData?.autoEnrich}
                            color="secondary"
                            onClick={() => {
                              setAutoSearchData((prevValue) => {
                                return {
                                  ...prevValue,
                                  autoEnrich: !autoSearchData?.autoEnrich,
                                };
                              });
                              setIsShowAutoSearchError(false);
                            }}
                          />
                          <Stack gap={0.5}>
                            <Button
                              sx={{
                                display: 'flex',
                                gap: '6px',
                                alignItems: 'center',
                                fontWeight: 400,
                                fontSize: 16,
                                color: !autoSearchData?.autoEnrich ? '#00000061' : '#000000DE',
                              }}
                              disabled={!autoSearchData?.autoEnrich}
                              onClick={(val) => {
                                if (!autoSearchData?.autoEnrich) return null;
                                setPopoverKey('auto-search');
                                handlePopoverOpen(val);
                              }}
                            >
                              {autoSearchData?.autoEnrichType
                                ? autoSearchOtions[autoSearchData?.autoEnrichType] || ''
                                : 'Select data type'}{' '}
                              <ArrowDropDownOutlinedIcon />
                            </Button>
                            <Menu
                              anchorEl={anchorEl}
                              keepMounted={false}
                              anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                              }}
                              transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                              }}
                              open={
                                open && popOverKey === 'auto-search' && autoSearchData?.autoEnrich
                              }
                              onClose={handlePopoverClose}
                            >
                              {Object.keys(autoSearchOtions)?.map((key) => (
                                <MenuItem
                                  onClick={() => {
                                    setAutoSearchData((prevValue) => {
                                      return {
                                        ...prevValue,
                                        autoEnrichType: key,
                                      };
                                    });
                                    setIsShowAutoSearchError(false);
                                    handlePopoverClose();
                                  }}
                                >
                                  <Typography
                                    sx={{ color: 'black', '&:hover': { color: '#1976D2' } }}
                                  >
                                    {autoSearchOtions[key]}
                                  </Typography>
                                </MenuItem>
                              ))}
                            </Menu>
                            {autoSearchData.autoEnrich && isShowAutoSearchError && (
                              <Typography variant="caption" color="error">
                                Please select data type
                              </Typography>
                            )}
                          </Stack>
                        </Stack>
                        <Spacer y={1.5} />
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                          <Button
                            variant="contained"
                            color="secondary"
                            onClick={() => saveUserData(autoSearchData)}
                          >
                            Save
                          </Button>
                        </Box>
                      </Container>
                    </Accordion>
                    <Accordion
                      title={
                        <Stack direction="row" spacing={1}>
                          <Typography variant="h3" className={'accordion-title'}>
                            Add to sequence permission
                          </Typography>
                        </Stack>
                      }
                      subTitle="Allow a teammate to enrol prospects on your behalf. All enrolments will be sent from you, and tasks assigned to you."
                      sx={{
                        '&.MuiAccordion-root': {
                          borderBottom: 'none !important',
                          boxShadow:
                            '0px 3px 1px -2px rgba(0, 0, 0, 0.20), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12) !important',
                        },
                        '& .MuiAccordionSummary-content': {
                          margin: '0px !important',
                        },
                        '& .MuiAccordionSummary-root': {
                          borderBottom: 'none !important',
                          padding: '20px 20px 20px 20px !important',

                          '& .accordion-title': {
                            color: '#000000 !important',
                            fontSize: '18px',
                            fontFamily: 'Roboto',
                            letterSpacing: '-0.5px',
                            fontWeight: 500,
                          },
                          '& .accordion-sub-title': {
                            display: 'none',
                          },
                        },
                        '& .Mui-expanded': {
                          '& .accordion-sub-title': {
                            display: 'block',
                          },
                          '& .MuiSvgIcon-root': {
                            color: '#0000008A !important',
                          },
                        },
                        '& .MuiPaper-root': {
                          padding: '10px 20px 20px 20px !important',
                          boxShadow: 'none !important',
                        },
                      }}
                      icon={<ArrowRightIcon />}
                    >
                      <Container sx={{ display: 'flex', flexDirection: 'column', gap: '30px' }}>
                        <Box
                          sx={{
                            '& .MuiChip-root': {
                              backgroundColor: '#1976d2 !important',
                            },
                            ' & .MuiChip-deleteIcon': {
                              color: 'rgba(255, 255, 255, 0.7) !important',
                            },
                            '& .MuiChip-avatar': {
                              backgroundColor: '#1565c0 !important',
                              color: 'rgba(255, 255, 255, 0.7) !important',
                            },
                            '& .MuiChip-label': {
                              color: '#FFFFFF !important',
                            },
                          }}
                        >
                          <Autocomplete
                            multiple
                            id="select-users"
                            options={userList?.map((v) => ({
                              id: v?.id,
                              name: `${v?.fname} ${v?.lname}`,
                              avatarUrl: v?.userImage,
                            }))}
                            getOptionLabel={(option) => option.name}
                            renderInput={(params) => (
                              <TextField {...params} variant="outlined" label="Select users" />
                            )}
                            defaultValue={getSelectedUserList}
                            value={
                              getSelectedUserList.length > 0
                                ? getSelectedUserList
                                : addUserIdList || []
                            }
                            loading={isGetData}
                            onChange={handleUserChange}
                            clearOnEscape
                            clearIcon={
                              <span onClick={() => handleClear(getSelectedUserList)}>&times;</span>
                            }
                            renderTags={(value, getTagProps) =>
                              value.map((option, index) => {
                                const { key, ...tagProps } = getTagProps({ index });
                                return (
                                  <Chip
                                    key={key}
                                    label={option?.name}
                                    avatar={
                                      option?.avatarUrl ? (
                                        <Avatar src={option.avatarUrl} />
                                      ) : (
                                        <Avatar>{option?.name?.charAt(0)}</Avatar>
                                      )
                                    }
                                    {...tagProps}
                                    onDelete={() =>
                                      handleUserDelete(getSelectedUserList[index], index)
                                    }
                                  />
                                );
                              })
                            }
                          />
                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                          <Button
                            variant="contained"
                            color="secondary"
                            onClick={() => enrollmentSaveUserData(selectedUserList)}
                            disabled={selectedUserList?.length === userLength}
                          >
                            Save
                          </Button>
                        </Box>
                      </Container>
                    </Accordion>
                  </Box>
                </React.Fragment>
              )}
            </form>
          );
        }}
      </Form>
      {linkType === LinkType.email && (
        <>
          <Box mt={2}>
            <Accordion
              title={
                <Stack direction="row" spacing={1}>
                  <Typography variant="h3" className={'accordion-title'}>
                    Signature
                  </Typography>
                </Stack>
              }
              subTitle=""
              sx={{
                '&.MuiAccordion-root': {
                  borderBottom: 'none !important',
                  boxShadow:
                    '0px 3px 1px -2px rgba(0, 0, 0, 0.20), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12) !important',
                },
                '& .MuiAccordionSummary-content': {
                  margin: '0px !important',
                },
                '& .MuiAccordionSummary-root': {
                  borderBottom: 'none !important',
                  padding: '20px 20px 20px 20px !important',

                  '& .accordion-title': {
                    color: '#000000 !important',
                    fontSize: '18px',
                    fontFamily: 'Roboto',
                    letterSpacing: '-0.5px',
                    fontWeight: 500,
                  },
                  '& .accordion-sub-title': {
                    display: 'none',
                  },
                },
                '& .Mui-expanded': {
                  '& .accordion-sub-title': {
                    display: 'block',
                  },
                  '& .MuiSvgIcon-root': {
                    color: '#0000008A !important',
                  },
                },
                '& .MuiPaper-root': {
                  padding: '10px 20px 20px 20px !important',
                  boxShadow: 'none !important',
                },
              }}
              icon={<ArrowRightIcon />}
            >
              <Container sx={{ display: 'flex', flexDirection: 'column', gap: '30px' }}>
                <Box
                  sx={{
                    '& .MuiChip-root': {
                      backgroundColor: '#1976d2 !important',
                    },
                    ' & .MuiChip-deleteIcon': {
                      color: 'rgba(255, 255, 255, 0.7) !important',
                    },
                    '& .MuiChip-avatar': {
                      backgroundColor: '#1565c0 !important',
                      color: 'rgba(255, 255, 255, 0.7) !important',
                    },
                    '& .MuiChip-label': {
                      color: '#FFFFFF !important',
                    },
                  }}
                >
                  <Signature
                    editUser={userData}
                    status={props.status}
                    putUser={putUser}
                    {...props}
                  />
                </Box>
              </Container>
            </Accordion>
          </Box>
          {emailVerification && (
            <Box mt={2}>
              <Card className={classes.emailVerificationCard}>
                <Stack gap={'24px'}>
                  <Stack gap={'12px'}>
                    <Typography className={classes.emailVerification}>Email Validation </Typography>
                    <Typography className={classes.emailVerificationSubText}>
                      Validate emails using a third party service{' '}
                    </Typography>
                  </Stack>
                  <Card className={classes.emailVerificationSubCard}>
                    <Grid container>
                      <Grid item xs={5} sx={{ padding: '16px 24px 16px 0px' }}>
                        <Stack direction={'row'} alignItems={'center'}>
                          <Switch
                            checked={getActiveEmailValidation('addEmail')}
                            color="secondary"
                            onClick={() => updateUserEmailVerificationSettings('addEmail')}
                          />
                          <Box display="flex">
                            <Typography className={classes.emailVerificationSwitchLabel}>
                              when primary email added{' '}
                            </Typography>
                            <TooltipComponent
                              subTitle={
                                'Your email will be validated once you add a primary email address.'
                              }
                            />
                          </Box>
                          {emailVerificationLoading === 'addEmail' && (
                            <CircularProgress size={24} color={'secondary'} />
                          )}
                        </Stack>
                      </Grid>
                      <Grid item xs={3} />
                      <Grid item xs={4} />
                      <Grid item xs={5} sx={{ padding: '16px 24px 16px 0px' }}>
                        <Stack direction={'row'} alignItems={'center'}>
                          <Switch
                            checked={getActiveEmailValidation('sendEmail')}
                            color="secondary"
                            onClick={() => updateUserEmailVerificationSettings('sendEmail')}
                          />
                          <Box display="flex">
                            <Typography className={classes.emailVerificationSwitchLabel}>
                              when sending an email{' '}
                            </Typography>
                            <TooltipComponent
                              subTitle={
                                'Your email address will be validated the first time you send an email to it.'
                              }
                            />
                          </Box>
                          {emailVerificationLoading === 'sendEmail' && (
                            <CircularProgress size={24} color={'secondary'} />
                          )}
                        </Stack>
                      </Grid>
                    </Grid>
                  </Card>
                </Stack>
              </Card>
            </Box>
          )}
        </>
      )}
      <Spacer y={2} />
      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: 'none',
        }}
        open={open && popOverKey === 'settings'}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Box sx={{ maxWidth: 400 }} p={2}>
          <Stack direction="row" spacing={1}>
            <InfoIcon sx={{ color: '#00000042 !important' }} />
            <Typography variant="h2" color="textPrimary">
              Changing your outreach settings
            </Typography>
          </Stack>

          <Spacer x={2} y={2} />

          <Typography variant="body2" color="textSecondary">
            If you change your outreach settings after enrolling prospects, please note that
            enrollments will be subject to change to accommodate your new settings. For example, any
            email outreach that now falls outside of your new scheduling window will be rescheduled.
          </Typography>
          <Spacer x={1} y={1} />
          <Typography variant="body2" color="textSecondary">
            If you have any questions about this, please get in touch with our support team.
          </Typography>
        </Box>
      </Popover>
    </>
  );
}

export default SchedulingWindow;

const TooltipComponent = ({ subTitle }) => {
  return (
    <Tooltip
      title={
        <Stack padding={'24px'} maxWidth={'450px'}>
          <Stack gap="16px">
            <Box display={'flex'} gap={'10px'} alignItems={'center'}>
              <ErrorOutlineOutlinedIcon sx={{ fontSize: '24px', color: '#ffffffb3' }} />
              <Typography fontSize={'18px'} fontWeight={500}>
                Further instructions
              </Typography>
            </Box>
            <Typography fontSize={'16px'} fontWeight={400} sx={{ color: '#ffffffb3' }}>
              {subTitle}
            </Typography>
          </Stack>
        </Stack>
      }
      arrow
      placement={'bottom'}
      componentsProps={{
        tooltip: {
          sx: {
            borderRadius: '16px',
            bgcolor: '#082037',
            '& .MuiTooltip-arrow': {
              color: '#082037',
            },
          },
        },
      }}
    >
      <InfoIcon sx={{ fontSize: '20px', color: '#00000042 !important' }} />
    </Tooltip>
  );
};

const AccordionStyle = {
  '&.MuiAccordion-root': {
    boxShadow:
      '0px 1px 5px 0px #0000001F, 0px 2px 2px 0px #00000024, 0px 3px 1px -2px #00000033 !important',
  },

  '& .MuiAccordionSummary-root': {
    borderBottom: 'none !important',
    padding: '0px 30px !important',

    '& .MuiAccordionSummary-content': {
      margin: '30px 0px !important',
    },

    '& .accordion-title': {
      fontFamily: 'Roboto',
      color: '#000000DE !important',
      fontSize: '18px',
      letterSpacing: '-0.5px',
      fontWeight: 500,
    },
    '& .MuiSvgIcon-root': {
      color: 'rgba(0, 0, 0, 0.54) !important',
    },
  },

  '& .accordion-sub-title': {
    color: 'rgba(0, 0, 0, 0.6) !important',
  },
  '& .MuiPaper-root': {
    padding: '0px 30px 30px 30px',
  },
};
