import React from 'react';
import { FileWrapper } from './styles';
import { Box, FormHelperText, Typography } from '@mui/material';
import { bytesToSize } from 'src/utils/helper';

import images from 'src/config/images';
import { isEmpty } from 'lodash';
import { fetchAttachmentFile } from 'src/modules/app/api/appApis';

function File({ file, messageId }) {
  if (!file || isEmpty(file)) return null;
  const ext = file?.filename ? file.filename.split('.').pop() : '';

  const downloadBlob = (attachedFile) => {
    const fileType = file.contentType.split(';').shift();
    const blob = new Blob([attachedFile], { type: fileType });
    const url = URL.createObjectURL(blob);

    const a = document.createElement('a');
    a.href = url;
    a.download = file.filename;
    a.target = '_blank';
    document.body.appendChild(a);
    a.click();

    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  const getAttachmentFile = async () => {
    const attachedFile = await fetchAttachmentFile(file.grantId, file.id, messageId);
    downloadBlob(attachedFile);
  };

  return (
    <FileWrapper onClick={getAttachmentFile}>
      {images.file[ext] && <img src={images.file[ext]} />}
      <Box sx={{ flex: 1, alignItems: 'flex-start', maxWidth: '140px' }}>
        <Typography variant="body2" color="textSecondary" noWrap textAlign="left">
          {file?.filename || ''}
        </Typography>
        <FormHelperText textAlign="left" sx={{ color: 'rgba(0,0,0,0.4)' }}>
          {bytesToSize(file?.size)}
        </FormHelperText>
      </Box>
    </FileWrapper>
  );
}

export default File;
