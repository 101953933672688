export const guideStepList = [
    {
        key: 'welcomeCrmStep',
        title: 'Integrations',
        description:
            "Welcome! Here, you can manage your CRM integration with SuperReach. It’s easy to navigate, but here's a quick 20-second intro to get you started.",
    },
    {
        key: 'step1',
        title: 'Step 1: Connect Your CRM ',
        description:
            "To do this you will need some credentials from your CRM provider. Add these to the form provided by SuperReach and click connect.",
    },
    {
        key: 'step2',
        title: 'Step 2: Customise Your Fields',
        description:
            "Once your CRM is connected, you can customise the field mapping to align with your business best practices and data policies.",
    },
    {
        key: 'step3',
        title: 'Step 3: Set Your Rules',
        description:
            "Next, set up rules for how SuperReach handles scenarios like duplicate contacts. This minimises manual work and keeps your data clean.",
    },
    {
        key: 'step4',
        title: 'Step 4: Customise Your Notes',
        description:
            'Finally, choose how SuperReach activity notes appear in your CRM to streamline naming conventions and simplify your reporting.',
    },
];

export const teamGuideStepList = [
    {
        key: 'yourCompanyStep',
        title: 'Your Company',
        description:
            "Welcome! Here, you can manage your users, teams, and company profile. It’s easy to navigate, but here's a quick 20-second intro to get you started.",
    },
    {
        key: 'userStep',
        title: 'Users',
        description:
            "Invite users by email and assign their role. Each user gets a login to track progress and access personalised features.",
    },
    {
        key: 'teamStep',
        title: 'Teams',
        description:
            "Create teams to mirror your existing business structure and simplify the management of key performance indicators. ",
    },
    {
        key: 'companyProfileStep',
        title: 'Company Profile',
        description:
            "Your company profile is auto-created by SuperReach AI to enable features like your company’s tone of voice. You can update it anytime if needed!",
    },
];