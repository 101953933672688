import * as sequenceTypes from '../actions/sequenceTypes';
import { RESET_STORE } from 'src/modules/app/actions/appTypes';
import { sequenceStepTypes } from '../config';

const initialState = {
  loading: {
    contact: false,
    steps: false,
    step: false,
    errors: false,
    fetchContactError: false,
  },
  enrolments: {},
  sequenceId: '',
  contactIds: [],
  sequence: {},
  contact: {},
  steps: {},
  errors: {},
  activeEnrolment: '',
  action: '',
};

const SequenceReducer = (state = initialState, action) => {
  switch (action.type) {
    case sequenceTypes.SET_POPULATE_ENQUEUE_TEMPLATE:
      if (state.steps && state.steps[action.taskId]) {
        return {
          ...state,
          steps: {
            ...state.steps,
            [action.taskId]: {
              ...state.steps[action.taskId],
              ...(action.errors
                ? { errors: { ...state.errors, [action.contactId]: action.errors } }
                : {
                    template: {
                      ...state.steps[action.taskId].template,
                      subject: action.subject,
                      content: action.content,
                    },
                    description: action.content,
                  }),
            },
          },
        };
      }
      return state;

    case sequenceTypes.FETCH_PERSONALIZE_STEPS:
      return {
        ...state,
        loading: {
          ...state.loading,
          steps: true,
        },
        steps: {},
      };

    case sequenceTypes.SET_PERSONALIZE_STEPS:
      return {
        ...state,
        loading: {
          ...state.loading,
          steps: false,
        },
        steps: action.steps,
      };

    case sequenceTypes.SET_PERSONALIZE_STEP:
      return {
        ...state,
        steps: {
          ...state.steps,
          [action.taskId]: {
            ...state.steps[action.taskId],
            ...action.data,
          },
        },
      };

    case sequenceTypes.FETCH_ENROLMENT_ERRORS:
      return {
        ...state,
        loading: {
          ...state.loading,
          errors: true,
        },
      };

    case sequenceTypes.SET_ENROLMENT_ERRORS:
      return {
        ...state,
        loading: {
          ...state.loading,
          errors: true,
        },
        errors: {
          ...state.errors,
          ...(action.contactId ? { [action.contactId]: action.errors } : {}),
        },
      };

    case sequenceTypes.SET_ENROLMENT_DATA:
      return {
        ...state,
        enrolments: {
          ...state.enrolments,
          ...(action.enrolments.id
            ? {
                [action.enrolments.id]: {
                  ...action.enrolments,
                  alreadyCreated: state.enrolments?.[action.enrolments.id]?.alreadyCreated,
                },
              }
            : {}),
        },
      };

    case sequenceTypes.FETCH_ERRORS_LOADING:
      return {
        ...state,
        loading: {
          ...state.loading,
          fetchContactError: action.value,
        },
      };

    case sequenceTypes.FETCH_ENQUEUE_CONTACT:
      return {
        ...state,
        loading: {
          ...state.loading,
          contact: true,
        },
        contact: {},
      };

    case sequenceTypes.SET_ENQUEUE_CONTACT:
      return {
        ...state,
        loading: {
          ...state.loading,
          contact: false,
        },
        contact: action.contact,
      };

    case sequenceTypes.SET_ACTIVE_SEQUENCE_PERSONALIZE:
      return {
        ...state,
        activeEnrolment: action.enrolmentId,
        steps: {},
        contact: {},
        errors: {},
      };

    case sequenceTypes.ENQUEUE_SEQUENCE_PERSONALIZE:
      return {
        ...state,
        enrollmentSuccess: false,
        enrolments: action.enrolments,
        action: action.action,
      };

    case sequenceTypes.CLEAR_PERSONALIZE_SEQUENCE:
      return {
        ...state,
        enrolments: {},
        enrolmentData: {},
        errors: {},
        steps: {},
        activeEnrolment: '',
        enrollmentSuccess: false,
      };

    case sequenceTypes.ENROLLMENT_SUCCESS:
      return {
        ...state,
        enrollmentSuccess: action.value,
      };

    case RESET_STORE:
      return initialState;

    default:
      return state;
  }
};
export default SequenceReducer;
