import React, { useEffect, useState, useMemo } from 'react';
import * as Yup from 'yup';
import { Box } from '@mui/material';
import { Button, Spacer, Form, Modal } from 'src/components/shared';
import { fetchParentTeamLookup, fetchUsersLookup } from 'src/modules/users/api/usersApi';

import moment from 'moment';

function UserActionDrawer({ action, onClose, teamBulkUpdate, selectTeams, globals, ...props }) {
  const [title, setTitle] = useState('');
  const [loading, setLoading] = useState(false);

  const teamsStatus = useMemo(() => {
    return globals?.teamStatusList || [];
  }, [props.globals]);

  useEffect(() => {
    switch (action) {
      case 'changeStatus':
        setTitle(`Change Status`);
        break;

      case 'changedParentTeam':
        setTitle(`Change parent team`);
        break;

      case 'changedTeamLead':
        setTitle(`Change team lead`);
        break;

      default:
        break;
    }
  }, [action]);

  if (!action || action == '') return null;

  const handleSubmit = async (payload) => {
    try {
      setLoading(true);
      await teamBulkUpdate(selectTeams, payload);
      setLoading(false);
      onClose();
    } catch (error) {
      setLoading(false);
    }
  };

  const getContent = () => {
    switch (action) {
      case 'changeStatus':
        return (
          <Form
            initialValues={{
              status: 'active',
            }}
            validationSchema={Yup.object().shape({
              status: Yup.string().required('Please select status!'),
            })}
            onSubmit={(values, form) => {
              handleSubmit({ status: values.status.toLowerCase() });
            }}
          >
            {(props) => {
              return (
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    props.submitForm();
                    return false;
                  }}
                  noValidate
                  style={{ height: '100%' }}
                >
                  <Box
                    display={'flex'}
                    flexDirection="column"
                    justifyContent="space-between"
                    height="100%"
                  >
                    <Form.Field.Select
                      fullWidth
                      options={teamsStatus}
                      variant="outlined"
                      name="status"
                      label="Status"
                      optLabel="label"
                      optValue="value"
                      tip="Change status for all selected teams."
                      showNone={false}
                    />

                    <Box display={'flex'} spacing={2} mt={2} justifyContent="flex-end">
                      <Button variant="contained" color="secondary" type="submit" loading={loading}>
                        Confirm
                      </Button>
                    </Box>
                  </Box>
                </form>
              );
            }}
          </Form>
        );
        break;

      case 'changedParentTeam':
        return (
          <Form
            initialValues={{
              parentTeam: {},
            }}
            validationSchema={Yup.object().shape({
              parentTeam: Yup.object().test(
                'parentTeam',
                'Please select a parent team.',
                function (item) {
                  return item?.id && item?.id !== '';
                },
              ),
            })}
            onSubmit={(values, form) => {
              const data = JSON.parse(JSON.stringify(values));
              data.parentTeam = values.parentTeam.id;
              handleSubmit(data);
            }}
          >
            {(props) => {
              return (
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    props.submitForm();
                    return false;
                  }}
                  noValidate
                  style={{ height: '100%' }}
                >
                  <Box
                    display={'flex'}
                    flexDirection="column"
                    justifyContent="space-between"
                    height="100%"
                  >
                    <Form.Field.AutoComplete
                      multiple={false}
                      fullWidth
                      options={[]}
                      variant="outlined"
                      remoteMethod={(search) => {
                        return fetchParentTeamLookup(search);
                      }}
                      name="parentTeam"
                      label="Parent Team"
                      optLabel="name"
                      optValue="id"
                      tip="Change parent team for all selected team."
                    />

                    <Box display={'flex'} spacing={2} mt={2} justifyContent="flex-end">
                      <Button variant="contained" color="secondary" type="submit" loading={loading}>
                        Confirm
                      </Button>
                    </Box>
                  </Box>
                </form>
              );
            }}
          </Form>
        );
        break;

      case 'changedTeamLead':
        return (
          <Form
            initialValues={{
              teamLead:
                {
                  name:
                    `${selectTeams?.[0]?.teamLead?.fname || ''} ${
                      selectTeams?.[0]?.teamLead?.lname || ''
                    }`.trim() || 'N/A',
                  id: selectTeams?.[0]?.teamLead?.id || null,
                } || {},
            }}
            validationSchema={Yup.object().shape({
              teamLead: Yup.object().test(
                'parentTeam',
                'Please select a team lead.',
                function (item) {
                  return item?.id && item?.id !== '';
                },
              ),
            })}
            onSubmit={(values, form) => {
              const data = JSON.parse(JSON.stringify(values));
              data.teamLead = values.teamLead.id;
              console.warn('changedTeamLead', values, data);
              handleSubmit(data);
            }}
          >
            {(props) => {
              return (
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    props.submitForm();
                    return false;
                  }}
                  noValidate
                  style={{ height: '100%' }}
                >
                  <Box
                    display={'flex'}
                    flexDirection="column"
                    justifyContent="space-between"
                    height="100%"
                  >
                    <Form.Field.AutoComplete
                      multiple={false}
                      fullWidth
                      options={[]}
                      variant="outlined"
                      remoteMethod={(search) => {
                        return fetchUsersLookup(search);
                      }}
                      name="teamLead"
                      label="Team Lead"
                      optLabel="name"
                      optValue="id"
                      tip="Change team lead for all selected teams."
                      value={props?.values?.teamLead}
                    />

                    <Box display={'flex'} spacing={2} mt={2} justifyContent="flex-end">
                      <Button variant="contained" color="secondary" type="submit" loading={loading}>
                        Confirm
                      </Button>
                    </Box>
                  </Box>
                </form>
              );
            }}
          </Form>
        );
        break;

      default:
        break;
    }
  };

  return (
    <Modal
      title={title}
      subtitle={`${selectTeams.length} ${selectTeams.length > 1 ? 'teams' : 'team'} selected`}
      open={action && typeof action !== 'undefined'}
      onClose={onClose}
      size="xs"
    >
      {getContent()}
    </Modal>
  );
}

export default UserActionDrawer;
