import { makeStyles } from '@mui/styles';

export const useGuideModalStyles = makeStyles((theme) => ({
    root: {
        '&.MuiDialog-root': {
            '& .MuiDialog-paper': {
                width: '640px',
                border: '1px solid',
                borderColor: theme.palette.border.blue,
            },

            '& .header-container': {
                minHeight: '320px !important',
                padding: '24px !important',
                justifyContent: 'flex-start !important',
                background: 'transparent !important',
            },

            '& .main-container': {
                padding: '32px !important',

                '& .title': {
                    fontSize: '24px',
                    fontWeight: 500,
                },
            },

            '& .footer-container': {
                '& > div': {
                    width: '100%',
                },

                [theme.breakpoints.down('md')]: {
                    marginTop: '24px !important',

                    '& button': {
                        borderRadius: 4,
                    },
                },
            },

            [theme.breakpoints.down('md')]: {
                '& .header-container, & .header-bg': {
                    minHeight: '270px !important',
                    objectFit: 'cover',
                },

                '& .main-container': {
                    padding: '24px !important',
                    '& .title': {
                        fontSize: '18px !important',

                        '& ~ p': {
                            fontSize: '14px !important',
                        },
                    },
                },
            },

            [theme.breakpoints.down('sm')]: {
                '& .header-container, & .header-bg': {
                    minHeight: '190px !important',
                },

                '& .MuiDialog-container': {
                    alignItems: 'flex-start',
                    marginTop: 90,
                },
            },
        },
    },
}));
