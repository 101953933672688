import React, { useState, useEffect } from 'react';
import { Grid, Box, Typography, Tooltip, Tabs, Tab } from '@mui/material';
import { Accordion, Card, ConfirmDialog } from 'src/components/App';
import { Button, Spacer } from 'src/components/shared';
import CheckIcon from '@mui/icons-material/Check';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { useStyles } from './styles';
import Stack from '@mui/material/Stack';
import { tenantIntegration } from '../../config/integrationData';
import SearchInput from 'src/components/App/AppGrid/SearchInput';
import ErrorIcon from '@mui/icons-material/Error';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
import Icon from 'src/components/lms/Icon';
import { useDispatch, useSelector } from 'react-redux';
import { setOutreachBoarding } from 'src/modules/app/actions/appActions';
import useTheme from '@mui/styles/useTheme';

const crmConnectKey = {
  Bullhorn: {
    clientId: 'Client Id',
    secrete: 'Client Secrete',
    data_center: 'Data Center',
    username: 'Username',
    pass: 'Password',
  },
  Vincere: { clientId: 'Client Id', apikey: 'Api Key', crmTenantId: 'Vincere Domain' },
  Salesforce: { key: 'Consumer Key', secret: 'Consumer Secret', instance_url: 'Tenant URL' },
  Hubspot: { access_token: 'Access Token', crmAccountId: 'Account Id' },
  Lusha: { apikey: 'Api Key' },
  Uplead: { apikey: 'Api Key' },
  Anymail: { apikey: 'Api Key' },
  Kendo: { apikey: 'Api Key' },
  Apollo: { apikey: 'Api Key' },
  Getanymail: { apikey: 'Api Key' },
  Kaspr: { apikey: 'Api Key' },
  Hunter: { apikey: 'Api Key' },
  ContactOut: { apikey: 'Api Key' },
  RocketReach: { apikey: 'Api Key' },
  Nymeria: { apikey: 'Api Key' },
  PeopleDataLab: { apikey: 'Api Key' },
};

const IntegrationsCards = ({
  integrations,
  setDrawerOpen,
  disconnectIntegration,
  onSettingsClick,
}) => {
  const [integrationData, setSelectedIntegrationData] = useState({});
  const [open, setOpen] = useState(false);
  const [intId, setIntId] = useState('');
  const [intStatus, setIntStatus] = useState('inactive');
  const [intName, setIntName] = useState({});
  const [currentTab, setCurrentTab] = useState(0);
  const [tenantIntegrationData, setTenantIntegrationData] = useState(tenantIntegration);
  const dispatch = useDispatch()
  const isLoading = useSelector((state) => state.app?.outreachBoarding?.isLoading);

  const classes = useStyles();
  const theme = useTheme();
  const { data } = integrations;

  const onChange = (tag, val) => {
    if (val.length > 0) {
      const filteredData = tenantIntegrationData?.filter((item) => {
        return item.name.toLowerCase().includes(val.toLowerCase());
      });
      setTenantIntegrationData(filteredData);
    } else {
      setTenantIntegrationData(tenantIntegration);
    }
  };

  const handleClose = (e) => {
    e.stopPropagation();
    setOpen(false);
  };

  const onConfirm = (id, status, flag) => {
    setOpen(true);
    if (flag && status === 'inactive') {
      disconnectIntegration(id, status);
      setOpen(false);
    }
  };

  useEffect(() => {
    if (data?.length > 0) {
      data.map((value) => {
        if (value?.name === 'Bullhorn') {
          const newIntegrationData = tenantIntegrationData.filter((integration) => {
            if (integration.name === 'Bullhorn') {
              integration.errorMessage = value.errorMessage;
              integration.statusMessage = value.statusMessage;
              integration.integration = value.integration;
              integration.status = value.status;
              integration.id = value.id;
              integration.subsettings = true;
              return integration;
            }
          });
          setSelectedIntegrationData(newIntegrationData);
        }
        if (value?.name === 'Vincere') {
          const newIntegrationData = tenantIntegrationData.filter((integration) => {
            if (integration.name === 'Vincere') {
              integration.errorMessage = value.errorMessage;
              integration.statusMessage = value.statusMessage;
              integration.integration = value.integration;
              integration.status = value.status;
              integration.id = value.id;
              integration.subsettings = true;
              return integration;
            }
          });
          setSelectedIntegrationData(newIntegrationData);
        }
        if (value?.name === 'JobAdder') {
          const newIntegrationData = tenantIntegrationData.filter((integration) => {
            if (integration.name === 'JobAdder') {
              integration.errorMessage = value.errorMessage;
              integration.statusMessage = value.statusMessage;
              integration.integration = value.integration;
              integration.status = value.status;
              integration.id = value.id;
              integration.subsettings = true;
              return integration;
            }
          });
          setSelectedIntegrationData(newIntegrationData);
        }
        if (value?.name === 'Salesforce') {
          const newIntegrationData = tenantIntegrationData.filter((integration) => {
            if (integration.name === 'Salesforce') {
              integration.errorMessage = value.errorMessage;
              integration.statusMessage = value.statusMessage;
              integration.integration = value.integration;
              integration.status = value.status;
              integration.id = value.id;
              integration.subsettings = true;
              return integration;
            }
          });
          setSelectedIntegrationData(newIntegrationData);
        }
        if (value?.name === 'Hubspot') {
          const newIntegrationData = tenantIntegrationData.filter((integration) => {
            if (integration.name === 'Hubspot') {
              integration.errorMessage = value.errorMessage;
              integration.statusMessage = value.statusMessage;
              integration.integration = value.integration;
              integration.status = value.status;
              integration.id = value.id;
              integration.subsettings = true;
              return integration;
            }
          });
          setSelectedIntegrationData(newIntegrationData);
        }
        if (value?.name === 'Lusha') {
          const newIntegrationData = tenantIntegrationData.filter((integration) => {
            if (integration.name === 'Lusha') {
              integration.errorMessage = value.errorMessage;
              integration.statusMessage = value.statusMessage;
              integration.integration = value.integration;
              integration.status = value.status;
              integration.id = value.id;
              integration.subsettings = false;
              return integration;
            }
          });
          setSelectedIntegrationData(newIntegrationData);
        }
        if (value?.name === 'Uplead') {
          const newIntegrationData = tenantIntegrationData.filter((integration) => {
            if (integration.name === 'Uplead') {
              integration.errorMessage = value.errorMessage;
              integration.statusMessage = value.statusMessage;
              integration.integration = value.integration;
              integration.status = value.status;
              integration.id = value.id;
              integration.subsettings = false;
              return integration;
            }
          });
          setSelectedIntegrationData(newIntegrationData);
        }
        if (value?.name === 'Anymail') {
          const newIntegrationData = tenantIntegrationData.filter((integration) => {
            if (integration.name === 'Anymail') {
              integration.errorMessage = value.errorMessage;
              integration.statusMessage = value.statusMessage;
              integration.integration = value.integration;
              integration.status = value.status;
              integration.id = value.id;
              integration.subsettings = false;
              return integration;
            }
          });
          setSelectedIntegrationData(newIntegrationData);
        }
        if (value?.name === 'Kendo') {
          const newIntegrationData = tenantIntegrationData.filter((integration) => {
            if (integration.name === 'Kendo') {
              integration.errorMessage = value.errorMessage;
              integration.statusMessage = value.statusMessage;
              integration.integration = value.integration;
              integration.status = value.status;
              integration.id = value.id;
              integration.subsettings = false;
              return integration;
            }
          });
          setSelectedIntegrationData(newIntegrationData);
        }
        if (value?.name === 'Apollo') {
          const newIntegrationData = tenantIntegrationData.filter((integration) => {
            if (integration.name === 'Apollo') {
              integration.errorMessage = value.errorMessage;
              integration.statusMessage = value.statusMessage;
              integration.integration = value.integration;
              integration.status = value.status;
              integration.id = value.id;
              integration.subsettings = false;
              return integration;
            }
          });
          setSelectedIntegrationData(newIntegrationData);
        }
        if (value?.name === 'Getanymail') {
          const newIntegrationData = tenantIntegrationData.filter((integration) => {
            if (integration.name === 'Getanymail') {
              integration.errorMessage = value.errorMessage;
              integration.statusMessage = value.statusMessage;
              integration.integration = value.integration;
              integration.status = value.status;
              integration.id = value.id;
              integration.subsettings = false;
              return integration;
            }
          });
          setSelectedIntegrationData(newIntegrationData);
        }
        if (value?.name === 'Kaspr') {
          const newIntegrationData = tenantIntegrationData.filter((integration) => {
            if (integration.name === 'Kaspr') {
              integration.errorMessage = value.errorMessage;
              integration.statusMessage = value.statusMessage;
              integration.integration = value.integration;
              integration.status = value.status;
              integration.id = value.id;
              integration.subsettings = false;
              return integration;
            }
          });
          setSelectedIntegrationData(newIntegrationData);
        }
        if (value?.name === 'Hunter') {
          const newIntegrationData = tenantIntegrationData.filter((integration) => {
            if (integration.name === 'Hunter') {
              integration.errorMessage = value.errorMessage;
              integration.statusMessage = value.statusMessage;
              integration.integration = value.integration;
              integration.status = value.status;
              integration.id = value.id;
              integration.subsettings = false;
              return integration;
            }
          });
          setSelectedIntegrationData(newIntegrationData);
        }
        if (value?.name === 'ContactOut') {
          const newIntegrationData = tenantIntegrationData.filter((integration) => {
            if (integration.name === 'ContactOut') {
              integration.errorMessage = value.errorMessage;
              integration.statusMessage = value.statusMessage;
              integration.integration = value.integration;
              integration.status = value.status;
              integration.id = value.id;
              integration.subsettings = false;
              return integration;
            }
          });
          setSelectedIntegrationData(newIntegrationData);
        }
        if (value?.name === 'RocketReach') {
          const newIntegrationData = tenantIntegrationData.filter((integration) => {
            if (integration.name === 'RocketReach') {
              integration.errorMessage = value.errorMessage;
              integration.statusMessage = value.statusMessage;
              integration.integration = value.integration;
              integration.status = value.status;
              integration.id = value.id;
              integration.subsettings = false;
              return integration;
            }
          });
          setSelectedIntegrationData(newIntegrationData);
        }
        if (value?.name === 'Nymeria') {
          const newIntegrationData = tenantIntegrationData.filter((integration) => {
            if (integration.name === 'Nymeria') {
              integration.errorMessage = value.errorMessage;
              integration.statusMessage = value.statusMessage;
              integration.integration = value.integration;
              integration.status = value.status;
              integration.id = value.id;
              integration.subsettings = false;
              return integration;
            }
          });
          setSelectedIntegrationData(newIntegrationData);
        }
        if (value?.name === 'PeopleDataLab') {
          const newIntegrationData = tenantIntegrationData.filter((integration) => {
            if (integration.name === 'PeopleDataLab') {
              integration.errorMessage = value.errorMessage;
              integration.statusMessage = value.statusMessage;
              integration.integration = value.integration;
              integration.status = value.status;
              integration.id = value.id;
              integration.subsettings = false;
              return integration;
            }
          });
          setSelectedIntegrationData(newIntegrationData);
        }
      });
    }
    if (data?.length < 1) {
      const newIntegrationData = tenantIntegrationData.filter((integration) => {
        integration.status = '';
        integration.id = '';
        integration.integration = {
          clientId: '',
          secrete: '',
          username: '',
          pass: '',
          data_center: '',
        };
      });
      setSelectedIntegrationData(newIntegrationData);
    }
  }, [data]);

  const deactivate = (interList) => {
    onConfirm(interList.id, interList.status, false);
    setIntId(interList.id);
    setIntStatus(interList.status);
    setIntName(interList);
  };

  const openCrmGuidance = () => {
    dispatch(setOutreachBoarding({ crmCarousel: true }))
  }

  return (
    <>
      <Spacer y={1} />

      <Tabs
        value={currentTab}
        onChange={(e, val) => {
          setCurrentTab(val);
        }}
        sx={{
          '.Mui-selected': {
            color: '#1976d2',
          },
          '.MuiTabs-indicator': {
            backgroundColor: 'transparent',
          },
        }}
      >
        <Tab sx={{ textTransform: 'initial', fontSize: '15px' }} label="CRM integrations" />
        <Tab sx={{ textTransform: 'initial', fontSize: '15px' }} label="Data providers" />
      </Tabs>

      <Spacer y={2} />
      <Box display="flex" justifyContent={"space-between"} alignItems={"center"}>
        <SearchInput
          size="small"
          color="secondary"
          placeholder="Search Integration"
          onSearch={(val) => onChange('_search', val)}
        />
        <Button disabled={isLoading} sx={{ color: theme.palette.text.grey }} variant="text" onClick={openCrmGuidance} startIcon={<Icon name="blueRocket" />}>Guidance</Button>
      </Box>
      <Spacer y={2.5} />

      {/* <Accordion
        title="CRM"
        expanded="true"
        icon={<ArrowRightIcon sx={{ color: 'rgba(0, 0, 0 , .60) !important' }} />}
        sx={{
          '&.MuiAccordion-root': {
            backgroundColor: 'transparent',
          },
          '&.MuiAccordion-root.Mui-expanded': {
            paddingBottom: '40px',
          },
          '& .MuiAccordionSummary-root': { border: '0 !important' },
          '& .accordion-title': {
            fontWeight: '500 !important',
            fontSize: '20px',
            padding: '12px 0',
          },
        }}
      > */}
      {currentTab === 0 && (
        <Grid container spacing={3}>
          {tenantIntegrationData
            ?.filter((tenant) =>
              ['Bullhorn', 'Vincere', 'Salesforce', 'Hubspot', 'JobAdder']?.includes(tenant?.name),
            )
            ?.map((interList, index) => {
              const bullhornConnectionError =
                ['down', 'error', 'slow']?.includes(
                  data?.find((item) => item?.name === 'Bullhorn')?.serviceStatus,
                ) && interList?.name === 'Bullhorn';
              const hasError =
                bullhornConnectionError ||
                !!interList?.errorMessage ||
                ['token invalid', 'disconnected']?.includes(
                  interList?.statusMessage?.toLowerCase(),
                );
              return (
                <Grid
                  item
                  xs={6}
                  sm={6}
                  md={6}
                  lg={4}
                  key={`interList-${interList.mainTitle}-${interList.id}-${index}`}
                >
                  <Card
                    className={classes.mainCardIntegrations}
                    cardContent={{
                      className: classes.cardContentIntegrationCard,
                      children: (
                        <>
                          <Box display="flex" flexDirection={'column'} alignItems="center">
                            <Box className={classes.cardHeader}>
                              <Typography variant="h1" className="title">
                                {interList.name}
                              </Typography>
                              {hasError && (
                                <Box className={classes.cardWarning}>
                                  <ErrorIcon style={{ fontSize: 14 }} />{' '}
                                  <Typography fontSize={'14px'}>Error</Typography>
                                </Box>
                              )}
                            </Box>
                            <Box
                              component="img"
                              sx={{
                                objectFit: 'contain',
                                height: 45,
                                width: 45,
                                maxHeight: { xs: 40, md: 57 },
                                maxWidth: { xs: 40, md: 50 },
                              }}
                              m={3}
                              alt="img"
                              src={interList.src}
                            />
                          </Box>
                          <Box className={classes.integrationDescription} p={1}>
                            {interList.description}
                          </Box>
                          <Box
                            className={classes.cardFooter}
                            display={'flex'}
                            justifyContent="center"
                            marginBottom={'28px'}
                            padding="0 20px"
                          >
                            <Box className={classes.gridFooterRowData} flexDirection="column">
                              <Box className="connect">
                                <Stack direction="row" spacing={'20px'} alignItems="center" mb={1}>
                                  {isActive(interList) && (
                                    <Box visibility={'hidden'}>
                                      <SettingsRoundedIcon />
                                    </Box>
                                  )}
                                  <Button
                                    color="secondary"
                                    sx={{
                                      padding: '5px 24px',
                                      height: '38px',
                                      ...(bullhornConnectionError
                                        ? {
                                          color: 'white',
                                          backgroundColor: '#ED6C02',
                                          '& svg': { color: 'white' },
                                        }
                                        : {}),
                                    }}
                                    variant={isActive(interList) ? 'contained' : 'outlined'}
                                    className={
                                      isActive(interList)
                                        ? classes.connectedBtn
                                        : classes.connectBtn
                                    }
                                    startIcon={
                                      isActive(interList) ? <AddCircleOutlineIcon /> : null
                                    }
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      setDrawerOpen({ edit: interList, open: true });
                                    }}
                                  >
                                    <span className="btn-text">
                                      {bullhornConnectionError
                                        ? 'Manage error'
                                        : isActive(interList)
                                          ? 'Manage'
                                          : 'Connect'}
                                    </span>
                                  </Button>
                                  {isActive(interList) && (
                                    <Tooltip title="Manage feilds" placement="right" arrow>
                                      <Box
                                        sx={{ cursor: 'pointer' }}
                                        onClick={() => {
                                          interList?.subsettings &&
                                            isActive(interList) &&
                                            onSettingsClick(interList?.name, interList?.id);
                                        }}
                                      >
                                        <SettingsRoundedIcon />
                                      </Box>
                                    </Tooltip>
                                  )}
                                </Stack>
                              </Box>
                              <Stack alignItems={'center'}>
                                <Box
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    if (isActive(interList)) {
                                      deactivate(interList);
                                    }
                                  }}
                                  style={{ cursor: 'pointer' }}
                                >
                                  <Tooltip title="Disconnect">
                                    <Typography
                                      sx={{ color: '#00000061', fontWeight: 500, fontSize: '14px' }}
                                    >
                                      {isActive(interList) ? 'Disconnect' : ''}
                                    </Typography>
                                  </Tooltip>
                                </Box>
                              </Stack>
                            </Box>
                          </Box>
                        </>
                      ),
                    }}
                  />
                </Grid>
              );
            })}
        </Grid>
      )}
      {/* </Accordion> */}

      {/* <Accordion
        title="Data Providers"
        icon={<ArrowRightIcon sx={{ color: 'rgba(0, 0, 0 , .60) !important' }} />}
        sx={{
          '&.MuiAccordion-root': {
            backgroundColor: 'transparent',
          },
          '&.MuiAccordion-root.Mui-expanded': {
            paddingBottom: '40px',
          },
          '& .MuiAccordionSummary-root': { border: '0 !important' },
          '& .accordion-title': {
            fontWeight: '500 !important',
            fontSize: '20px',
            padding: '12px 0',
          },
        }}
      > */}
      {currentTab === 1 && (
        <Grid container spacing={3}>
          {tenantIntegrationData
            ?.filter(
              (tenant) =>
                !['Bullhorn', 'Vincere', 'Salesforce', 'Hubspot', 'JobAdder']?.includes(
                  tenant?.name,
                ),
            )
            ?.map((interList, index) => {
              const hasError =
                !!interList?.errorMessage ||
                ['token invalid', 'disconnected']?.includes(
                  interList?.statusMessage?.toLowerCase(),
                );
              return (
                <Grid
                  item
                  xs={6}
                  sm={6}
                  md={6}
                  lg={4}
                  key={`interList-${interList.mainTitle}-${interList.id}-${index}`}
                >
                  <Card
                    className={classes.mainCardIntegrations}
                    cardContent={{
                      className: classes.cardContentIntegrationCard,
                      children: (
                        <>
                          <Box display="flex" flexDirection={'column'} alignItems="center">
                            <Box className={classes.cardHeader}>
                              <Typography variant="h1" className="title">
                                {interList.name}
                              </Typography>
                              {hasError && (
                                <Box className={classes.cardWarning}>
                                  <ErrorIcon style={{ fontSize: 14 }} />{' '}
                                  <Typography fontSize={'14px'}>Error</Typography>
                                </Box>
                              )}
                            </Box>
                            <Box
                              component="img"
                              sx={{
                                objectFit: 'contain',
                                height: 45,
                                width: 45,
                                maxHeight: { xs: 40, md: 57 },
                                maxWidth: { xs: 40, md: 50 },
                              }}
                              m={3}
                              alt="img"
                              src={interList.src}
                            />
                          </Box>
                          <Box className={classes.integrationDescription} p={1}>
                            {interList.description}
                          </Box>
                          <Box
                            className={classes.cardFooter}
                            display={'flex'}
                            justifyContent="center"
                            marginBottom={'28px'}
                            padding="0 20px"
                          >
                            <Box className={classes.gridFooterRowData} flexDirection="column">
                              <Box className="connect">
                                <Stack direction="row" spacing={2} mb={1}>
                                  <Button
                                    color="secondary"
                                    sx={{ padding: '5px 24px', height: '38px' }}
                                    variant={isActive(interList) ? 'contained' : 'outlined'}
                                    className={
                                      isActive(interList)
                                        ? classes.connectedBtn
                                        : classes.connectBtn
                                    }
                                    startIcon={
                                      isActive(interList) ? (
                                        <SettingsRoundedIcon />
                                      ) : (
                                        <AddCircleOutlineIcon />
                                      )
                                    }
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      setDrawerOpen({ edit: interList, open: true });
                                    }}
                                  >
                                    <span className="btn-text">
                                      {isActive(interList) ? 'Manage' : 'Connect'}
                                    </span>
                                  </Button>
                                </Stack>
                              </Box>
                              <Stack alignItems={'center'}>
                                <Box
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    if (isActive(interList)) {
                                      deactivate(interList);
                                    }
                                  }}
                                  style={{ cursor: 'pointer' }}
                                >
                                  <Tooltip title="Disconnect">
                                    <Typography
                                      sx={{ color: '#00000061', fontWeight: 500, fontSize: '14px' }}
                                    >
                                      {isActive(interList) ? 'Disconnect' : ''}
                                    </Typography>
                                  </Tooltip>
                                </Box>
                              </Stack>
                            </Box>
                          </Box>
                        </>
                      ),
                    }}
                  />
                </Grid>
              );
            })}
        </Grid>
      )}
      {/* </Accordion> */}

      <Spacer y={2} />

      <ConfirmDialog
        title={`Disconnect ${intName?.name}`}
        open={open}
        onClose={handleClose}
        onConfirm={() => {
          if (intStatus === 'active') {
            disconnectIntegration(intId, intStatus);
            setOpen(false);
          }
        }}
        loading={false}
      >
        <Stack gap={'20px'} alignItems={'center'} flexDirection={'row'}>
          {intName?.src && (
            <Box
              component="img"
              sx={{
                objectFit: 'contain',
                height: 45,
                width: 45,
              }}
              alt="img"
              src={intName.src}
            />
          )}
          <Typography variant="body1" color="textSecondary">
            Are you sure you want to disconnect <b>{intName?.name || ''}</b>.<br />
            All services with SuperReach will stop.
          </Typography>
        </Stack>
      </ConfirmDialog>
    </>
  );
};

const isActive = (interList) => interList.status === 'active';

export default IntegrationsCards;
