import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Typography, Box, Grid, ButtonBase } from '@mui/material';
import { Modal, Spacer, Drawer } from 'src/components/shared';
import images from 'src/config/images';

import CloneSequenceForm from './CloneSequenceForm';
import CreateSequenceForm from './CreateSequenceForm';

import { toggleAddSequenceForm } from 'src/modules/app/actions/appActions';
import { cloneSequence, postSequence } from 'src/modules/sequence/actions/sequenceActions';
import {
  ContentWrapper,
  ModalAIContentTitle,
  ModalContainer,
  ModalContentSubTitle,
  ModalContentTitle,
} from './style';
import { includes } from 'lodash';
import useFeatureAccess from '../../../../../components/hooks/useFeatureAccess';
import { FeatureCaseKeys } from 'src/config/featureCase';

const SequenceForm = ({ ...props }) => {
  const tenant = useSelector((state) => state.app.tenant);
  const history = useHistory();
  const [showDefaultModal, setShowDefaultModal] = useState(true);
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [showCloneForm, setShowCloneForm] = useState(false);

  useEffect(() => {
    setShowDefaultModal(true);
  }, []);

  const switchForm = (type) => {
    setShowDefaultModal(false);
    if (type === 'createWithAI') {
      props.toggleAddSequenceForm();
      history.replace('/sequence?onboarding=true&ai=true');
    } else if (type === 'create') {
      props.toggleAddSequenceForm();
      history.replace('/sequence?onboarding=true');
      // setShowCloneForm(false);
      // setShowCreateForm(true);
    } else {
      setShowCreateForm(false);
      setShowCloneForm(true);
    }
  };

  const onClose = () => {
    setShowDefaultModal(true);
    setShowCreateForm(false);
    setShowCloneForm(false);
    props.toggleAddSequenceForm();
  };

  const onCreateSequence = (sequence) => {
    return props.postSequence(sequence);
  };

  const gridSize = useFeatureAccess(FeatureCaseKeys.GENERATE_SEQUENCE_WITH_AI) ? 4 : 6;
  return (
    <>
      <ModalContainer>
        <Modal
          sx={{
            '& .MuiDialog-paper': {
              borderRadius: '13px',
              '& .MuiDialogTitle-root': {
                padding: '30px',
                fontFamily: 'Inter',
              },
              '& .MuiDialogContent-root': {
                padding: '0px 30px 30px 30px',
              },
              '& .MuiGrid-item:hover': {
                borderRadius: '7px',
                border: '1px solid #1976D2',
                background: 'rgba(25, 118, 210, 0.08)',
              },
              '& .MuiGrid-item:active': {
                borderRadius: '7px',
                border: '1px solid #1976D2',
                background: 'rgba(25, 118, 210, 0.08)',
              },
              '& .MuiTouchRipple-root': {
                background: 'none !important',
              },
            },
          }}
          size={useFeatureAccess(FeatureCaseKeys.GENERATE_SEQUENCE_WITH_AI) ? 'md' : 'sm'}
          title="Create sequence"
          open={showDefaultModal}
          onClose={onClose}
        >
          <Box>
            <Grid container>
              <Grid item xs={gridSize} padding={'16px 24px 24px 24px'}>
                <ButtonBase
                  onClick={() => {
                    switchForm('clone');
                  }}
                >
                  <Box
                    display={'flex'}
                    justifyContent="center"
                    flexDirection="column"
                    alignItems="center"
                    gap={'30px'}
                  >
                    <img src={images.sequence.CloneSeqIcon} style={{ height: 74 }} />
                    <ContentWrapper>
                      <ModalContentTitle>Use existing sequence</ModalContentTitle>
                      <ModalContentSubTitle>
                        Easily replicate successful outreach sequences.
                      </ModalContentSubTitle>
                    </ContentWrapper>
                  </Box>
                </ButtonBase>
              </Grid>
              <Grid item xs={gridSize} padding={'16px 24px 24px 24px'}>
                <ButtonBase
                  onClick={() => {
                    switchForm('create');
                  }}
                >
                  <Box
                    display={'flex'}
                    justifyContent="center"
                    flexDirection="column"
                    alignItems="center"
                    gap={'30px'}
                  >
                    <img src={images.sequence.AddSeqIcon} style={{ height: 74 }} />
                    <ContentWrapper>
                      <ModalContentTitle>Create new sequence</ModalContentTitle>
                      <ModalContentSubTitle>
                        Design unique outreach sequences from scratch.
                      </ModalContentSubTitle>
                    </ContentWrapper>
                  </Box>
                </ButtonBase>
              </Grid>
              {useFeatureAccess(FeatureCaseKeys.GENERATE_SEQUENCE_WITH_AI) && (
                <Grid item xs={gridSize} padding={'16px 24px 24px 24px'}>
                  <ButtonBase
                    onClick={() => {
                      switchForm('createWithAI');
                    }}
                  >
                    <Box
                      display={'flex'}
                      justifyContent="center"
                      flexDirection="column"
                      alignItems="center"
                      gap="30px"
                    >
                      <img src={images.sequence.AiGenerateIcon} style={{ height: 74 }} />
                      <ContentWrapper>
                        <ModalAIContentTitle>Generate with AI</ModalAIContentTitle>
                        <ModalContentSubTitle>
                          Generate outreach sequences with AI assistance.
                        </ModalContentSubTitle>
                      </ContentWrapper>
                    </Box>
                  </ButtonBase>
                </Grid>
              )}
            </Grid>
          </Box>
        </Modal>
      </ModalContainer>

      <Modal title="Clone Sequence" size="xs" open={showCloneForm} onClose={onClose}>
        <CloneSequenceForm cloneSequence={props.cloneSequence} />
      </Modal>

      <Drawer
        title="Create sequence"
        open={showCreateForm}
        onClose={onClose}
        showCloseConfirmation={true}
      >
        <CreateSequenceForm
          sequenceAccessTypeList={props.sequenceAccessTypeList}
          sequenceEmailTypeList={props.sequenceEmailTypeList}
          user={props.user}
          onClose={onClose}
          onSubmit={onCreateSequence}
        />
      </Drawer>
    </>
  );
};

const mapStateToProps = (state) => ({
  sequenceAccessTypeList: state.app.globals.sequenceAccessTypeList,
  sequenceEmailTypeList: state.app.globals.sequenceEmailTypeList,
  user: state.auth.user,
});

const mapDispatchToProps = (dispatch) => ({
  cloneSequence: (sequenceId, sequenceName) => {
    return new Promise((resolve, reject) => {
      dispatch(cloneSequence(sequenceId, sequenceName, resolve, reject));
    });
  },
  postSequence: (data) => {
    return new Promise((resolve, reject) => {
      dispatch(postSequence(data, resolve, reject));
    });
  },
  toggleAddSequenceForm: () => dispatch(toggleAddSequenceForm()),
});

export default connect(mapStateToProps, mapDispatchToProps)(SequenceForm);
