import React, { useState } from 'react';
import { Box, Typography, Tooltip, Card, Grid, AvatarGroup, Stack } from '@mui/material';
import { Button, Avatar, Form, Spacer } from 'src/components/shared';
import MoveDownIcon from '@mui/icons-material/MoveDown';
import AddNewUserDialog from './AddNewUserDialog';
import images from 'src/config/images';

const SpendingLimitSection = ({
  section,
  values,
  onRemoveUser,
  onAddUser,
  onInputChange,
  storeRemovedUser,
}) => {
  const [open, setOpen] = useState(false);

  const { title, description, balanceUsed, users, userCount, color, tooltip } = section;
  const sectionValues = values?.[section.key] || {};
  const defaultUserLimit = sectionValues.defaultUserLimit || 0;

  const handleAddUser = () => {
    setOpen(true);
  };

  const handleClose = (allOverrides) => {
    if (allOverrides) {
      const currentValues = values?.[section.key] || {};
      currentValues.overrides = allOverrides;
      const newUsers = [];
      const removedUsers = [];
      allOverrides.forEach((override) => {
        if ((override.isNew === true || override.action === 'update') && override.user) {
          newUsers.push({ user: override.user, userLimit: override.userLimit });
        } else if (override.action === 'remove' && override.user) {
          onRemoveUser(section.key, override.user._id);
          removedUsers.push(override);
        }
      });
      if (newUsers.length > 0) {
        onAddUser(section.key, newUsers, 'add');
      }
      storeRemovedUser({ [section.key]: removedUsers });
    }
    setOpen(false);
  };

  return (
    <Card
      sx={{
        p: 3,
        mb: 2,
        border: '1px solid #E0E0E0',
        borderRadius: '8px',
        boxShadow: 'none',
        position: 'relative',
        overflow: 'hidden',
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          bottom: 0,
          width: '10px',
          background: color.includes('linear-gradient') ? color : undefined,
          backgroundColor: !color.includes('linear-gradient') ? color : undefined,
          borderTopLeftRadius: '16px',
          borderBottomLeftRadius: '16px',
        }}
      />

      <Grid container spacing={2} alignItems="center" justifyContent="space-between">
        <Grid item xs={12} md={4}>
          <Box display="flex" alignItems="center" justifyContent="space-between" sx={{ mb: 2 }}>
            <Stack direction="row" gap={1} alignItems="center" justifyContent="center">
              <Typography variant="body1" color="textPrimary">
                <b>{title}</b>
              </Typography>
              <Tooltip
                title={tooltip}
                placement="top"
                arrow
                componentsProps={{
                  tooltip: {
                    sx: {
                      width: '200px',
                      height: '106px',
                      fontSize: '10px',
                      fontWeight: '300',
                      lineHeight: '1.5',
                      bgcolor: '#1A2633',
                      borderRadius: '8px',
                      padding: '8px 10px',
                      '& .MuiTooltip-arrow': {
                        color: '#1A2633',
                      },
                    },
                  },
                }}
              >
                <img src={images.app.helpIcon} alt="help" width={24} height={24} />
              </Tooltip>
            </Stack>
          </Box>
          <Typography variant="body2" color="textSecondary">
            {description}
          </Typography>
          <Spacer x={1} y={1} />
          <Typography variant="body2" color="textPrimary">
            Balance used this month: ${balanceUsed}
          </Typography>
        </Grid>

        {/* Second Column: Input Fields */}
        <Grid item xs={12} md={5}>
          <Stack direction="row" gap={2} alignItems="flex-end" justifyContent="flex-end">
            <Box>
              <Typography variant="body1" color="textPrimary" sx={{ mb: -1 }}>
                Default Account Limit
              </Typography>
              <Form.Field.Input
                name={`${section.key}.defaultAccountLimit`}
                placeholder="$500"
                variant="outlined"
                fullWidth
                onChange={(e) => {
                  onInputChange();
                }}
                InputProps={{
                  startAdornment: (
                    <Typography color="textSecondary" sx={{ marginRight: 0.5 }}>
                      $
                    </Typography>
                  ),
                }}
                size="small"
              />
            </Box>
            <Box>
              <Typography variant="body1" color="textPrimary" sx={{ mb: -1 }}>
                Default User Limit
              </Typography>
              <Form.Field.Input
                name={`${section.key}.defaultUserLimit`}
                placeholder="$500"
                variant="outlined"
                fullWidth
                onChange={(e) => {
                  onInputChange();
                }}
                InputProps={{
                  startAdornment: (
                    <Typography color="textSecondary" sx={{ marginRight: 0.5 }}>
                      $
                    </Typography>
                  ),
                }}
                size="small"
              />
            </Box>
          </Stack>
        </Grid>

        {/* Third Column: Avatars and Button */}
        <Grid item xs={12} md={3} sx={{ mt: 2 }}>
          <Box display="flex" alignItems="center" justifyContent="flex-end" sx={{ mb: 1 }}>
            <AvatarGroup
              spacing="medium"
              max={6}
              sx={{
                '& .MuiAvatarGroup-avatar': {
                  width: 24,
                  height: 24,
                  fontSize: 12,
                },
              }}
              onClick={handleAddUser}
            >
              {users.map((user, index) => (
                <Avatar key={index} name={user.fullName} size={24} />
              ))}
            </AvatarGroup>
            <Typography color="textSecondary" variant="body2">
              {userCount} Users
            </Typography>
          </Box>
          <Box display="flex" justifyContent="flex-end">
            <Button color="secondary" onClick={handleAddUser}>
              <MoveDownIcon sx={{ marginRight: '6px' }} color="secondary" />
              <Typography variant="body2" color="secondary">
                Add Custom User Limits
              </Typography>
            </Button>
          </Box>
        </Grid>
      </Grid>

      <AddNewUserDialog
        open={open}
        onInputChange={onInputChange}
        onClose={handleClose}
        sectionKey={section.key}
        values={values}
        defaultUserLimit={defaultUserLimit}
      />
    </Card>
  );
};

export default SpendingLimitSection;
