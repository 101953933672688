import { Formik } from 'formik';
import React from 'react';
import { Form } from 'src/components/shared';
import { fetchUsersLookup } from 'src/modules/users/api/usersApi';
import images from 'src/config/images';
import {
  Box,
  Button,
  Card,
  CardContent,
  Stack,
  Typography,
  IconButton,
  Skeleton,
} from '@mui/material';
import * as Yup from 'yup';
import { putTeam } from '../../actions/adminActions';
import { useDispatch } from 'react-redux';
import theme, { palette, typography } from 'src/config/theme';

const TeamForm = ({
  handleFormSubmit,
  teamData,
  setTeamData,
  toggleTeamNameEdit,
  usersData,
  isTeamNameEdit,
  teamId,
  isLoader,
}) => {
  const dispatch = useDispatch();
  const validationSchema = Yup.object().shape({
    teamName: Yup.string().required('Team name is required'),
  });
  return (
    <Formik enableReinitialize initialValues={teamData} validationSchema={validationSchema}>
      {({ values }) => (
        <form>
          {isLoader ? (
            <Skeleton variant="rectangular" width="100%" height={50} />
          ) : (
            <Card
              sx={{ borderRadius: '8px', border: `1px solid ${palette.border.lightWhite}`, mb: 3 }}
            >
              <CardContent sx={{ p: 3 }}>
                <Stack direction="row" alignItems="center" justifyContent="space-between">
                  <Box display="flex" alignItems="center" gap={2} flex={1}>
                    <img src={images.icons.CompanyIcon} alt="CompanyIcon" />
                    {isTeamNameEdit ? (
                      <Typography variant="body3">{values?.teamName}</Typography>
                    ) : (
                      <Form.Field.Input
                        fullWidth
                        rows={4}
                        variant="outlined"
                        name="teamName"
                        label="Add a team name"
                        type="text"
                        sx={{ width: '60%' }}
                      />
                    )}

                    {isTeamNameEdit ? (
                      <IconButton
                        onClick={() => {
                          toggleTeamNameEdit();
                          if (isTeamNameEdit === false) {
                            handleFormSubmit(values);
                          }
                        }}
                      >
                        <img src={images.icons.CompanyEdit} alt="Company edit" />
                      </IconButton>
                    ) : (
                      <IconButton
                        onClick={() => {
                          toggleTeamNameEdit();
                          if (isTeamNameEdit === false) {
                            handleFormSubmit(values);
                          }
                        }}
                        disabled={!values?.teamName}
                      >
                        <img src={images.icons.CompanyCheckIcon} alt="CompanyCheckIcon" />
                      </IconButton>
                    )}
                  </Box>

                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="flex-end"
                    gap={4}
                    flex={1}
                  >
                    <Box display="flex" alignItems="center" gap={1}>
                      <Typography variant="body4">Team Leader:</Typography>
                      {isTeamNameEdit ? (
                        <Button
                          onClick={toggleTeamNameEdit}
                          variant="text"
                          sx={{
                            fontSize: '14px',
                            fontWeight: 500,
                            color: palette.text.black1,
                            textTransform: 'none',
                          }}
                        >
                          {values?.teamLead?.name && values.teamLead.name !== 'undefined undefined'
                            ? values.teamLead.name
                            : 'N/A'}
                        </Button>
                      ) : (
                        <Form.Field.AutoComplete
                          options={[]}
                          fullWidth
                          name="teamLead"
                          label="Team Lead"
                          placeholder="Team Lead"
                          variant="outlined"
                          remoteMethod={(val) => {
                            return fetchUsersLookup(val, false, false, {
                              permission_neq: 'user',
                              _size: 100,
                            });
                          }}
                          optLabel="name"
                          optValue="id"
                          multiple={false}
                          sx={{ width: 200 }}
                          onChange={(val) => {
                            setTeamData({
                              ...teamData,
                              teamLead: Object.keys(val || {}).length === 0 ? null : val,
                            });
                          }}
                          value={values.teamLead}
                        />
                      )}
                    </Box>

                    <Typography variant="body4">
                      Users:{' '}
                      <Typography variant="body4" color={palette.text.black1}>
                        {usersData.length}
                      </Typography>
                    </Typography>
                    <Form.Field.Switch
                      variant="outlined"
                      name="status"
                      sx={{ mt: 0 }}
                      onChange={() =>
                        dispatch(putTeam(teamId, { status: values.status ? 'inactive' : 'active' }))
                      }
                    />
                  </Box>
                </Stack>
              </CardContent>
            </Card>
          )}
        </form>
      )}
    </Formik>
  );
};

export default TeamForm;
