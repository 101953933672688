import React, { useState } from 'react';
import { Box } from '@mui/material';
import { Drawer } from 'src/components/shared';
import { tenantIntegration } from '../../config/integrationData';
import IntegrationsCards from '../../components/Integrations/IntegrationsCards';
import IntegrationForm from '../../components/Integrations/IntegrationForm';
import { useStyles } from './styles';
import { useHistory } from 'react-router';
import Onboarding from '../../components/OnboardingCrm';
import { guideStepList } from '../../components/OnboardingCrm/config';

function IntegrationsView({ integrations, ...props }) {
  const [drawerOpen, setDrawerOpen] = useState({ edit: null, open: false });
  const classes = useStyles();
  const history = useHistory();

  return (
    <Box>
      <IntegrationsCards
        setDrawerOpen={setDrawerOpen}
        onSettingsClick={(name, id) => {
          if (['Bullhorn', 'Vincere', 'JobAdder', 'Salesforce', 'Hubspot'].includes(name))
            history.push(`/admin/integrations/${name}/${id}`);
        }}
        disconnectIntegration={props.disconnectIntegration}
        integrations={integrations}
      />
      {/* <IntegrationDetails /> */}
      <Drawer
        title={`${drawerOpen.edit?.name} Setting`}
        open={drawerOpen.open}
        onClose={() => {
          setDrawerOpen({ edit: null, open: false });
        }}
      >
        <IntegrationForm
          editUser={drawerOpen.edit}
          drawerToggle={setDrawerOpen}
          onComplete={() => { }}
          tenantIntegration={tenantIntegration}
          {...props}
          onClose={() => {
            setDrawerOpen({ edit: null, open: false });
          }}
        />
      </Drawer>
      <Onboarding
        onboardinCarousel="crmCarousel"
        onboardingModal="crmModal"
        onboardingguideSteps="crmguideSteps"
        imageStep="crmStep"
        guideStepList={guideStepList}
      />
    </Box>
  );
}

export default IntegrationsView;
