import React, { useMemo, useState, useEffect } from 'react';
import { Box, Typography, Stack, CircularProgress, Container } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { Button, Form } from 'src/components/shared';
import MoveDownIcon from '@mui/icons-material/MoveDown';
import SpendingLimitSection from './SpendingLimitSection';
import LearnMoreModal from './LearnMoreModal';
import {
  fetchTenantSpendingLimit,
  updateTenantSpendingLimit,
  fetchTenantSpendingUsage,
} from '../../api/adminApi';
import { useSelector } from 'react-redux';
import toast from 'src/utils/toast';
import { ConfirmDialog } from 'src/components/App';
import useFeatureAccess from '../../../../components/hooks/useFeatureAccess';
import { FeatureCaseKeys } from 'src/config/featureCase';

const sections = [
  {
    title: 'Data and Email',
    description: 'Set your monthly spending limit for finding and validating contact information',
    color: '#00B295',
    key: 'data',
    tooltip:
      'Data and Email services support profile enrichment by sourcing emails and phone numbers, while Email Validation ensures their accuracy. Each successful request incurs a cost, deducted from your Data balance.',
  },
  {
    title: 'AI Coach',
    key: 'aiCoach',
    description: 'Set your monthly spending limit for AI Coaching and Assessments',
    color: '#F240A4',
    tooltip:
      'AI Coaching refers to AI experiences on the learning platform including AI Coaching and AI Assessments. Each AI session incurs a cost, which is deducted from your AI Coaching balance.',
  },
  {
    title: 'AI Co-Workers',
    key: 'aiCoWorkers',
    description:
      'Set your monthly spending limit for AI tasks such as content generation and task execution',
    color: 'linear-gradient(244.32deg, #FFD480 23.65%, #F240A4 56.41%, #1976D2 95.33%)',
    tooltip:
      'AI Co-Workers handle tasks like creating content, generating sequences, and running workflows. Each task incurs a cost, which is deducted from your AI Co-Worker balance.',
  },
];

const defaultOveride = {
  user: '',
  userLimit: 0,
  action: 'add',
};

const SpendingLimits = () => {
  const history = useHistory();
  const currentUser = useSelector((state) => state.auth.user);
  const tenantId = currentUser.tenants?.[0].id;
  const [spendingLimits, setSpendingLimits] = useState({});
  const [customLimitUsers, setcustomLimitUsers] = useState({});
  const [isLearnMoreOpen, setIsLearnMoreOpen] = useState(false);
  const [limitUsage, setLimitUsage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [storeRemovedUser, setStoreRemovedUser] = useState({});
  const [pendingNavigation, setPendingNavigation] = useState(null);

  const fetchSpendingLimits = useMemo(
    () => async () => {
      try {
        setIsLoading(true);
        const [limitsResponse, usageResponse] = await Promise.all([
          fetchTenantSpendingLimit(tenantId),
          fetchTenantSpendingUsage(),
        ]);

        setSpendingLimits(limitsResponse.spendingLimits);
        setLimitUsage(usageResponse);
        setcustomLimitUsers(limitsResponse.customLimitUsers);
      } catch (error) {
        console.error('Error fetching spending limits:', error);
        toast.error('Failed to fetch spending limits');
      } finally {
        setIsLoading(false);
      }
    },
    [tenantId],
  );

  const hasLmsAccess = useMemo(
    () => currentUser?.lmsmonthly === true || currentUser?.lmsannual === true,
    [currentUser],
  );

  const hasAiCoWorkersAccess = useFeatureAccess(FeatureCaseKeys.ENABLED_AI_COWORKERS);

  const filteredSections = useMemo(
    () =>
      sections.filter((section) => {
        switch (section.key) {
          case 'aiCoach':
            return hasLmsAccess;
          case 'aiCoWorkers':
            return hasAiCoWorkersAccess;
          default:
            return true;
        }
      }),
    [hasLmsAccess, hasAiCoWorkersAccess],
  );

  useEffect(() => {
    fetchSpendingLimits();
  }, [tenantId]);

  const storeRemoved = (data) => {
    setStoreRemovedUser((prev) => ({ ...prev, ...data }));
  };

  const initialValues = useMemo(() => {
    const spendingLimitsArray = Object.values(spendingLimits);
    return filteredSections.reduce((acc, section) => {
      const matchingLimit = spendingLimitsArray.find((limit) => limit.type === section.key) || {};
      const customLimitUsersInSection = Object.values(customLimitUsers)
        .filter((user) =>
          user.spendingLimits.some(
            (limit) => limit.type === section.key && limit.hasOwnProperty('customLimit'),
          ),
        )
        .map((user) => ({
          user: { ...user, name: user?.fname + ' ' + user?.lname, id: user.id },
          userLimit:
            user.spendingLimits.find((limit) => limit.type === section.key)?.customLimit || 0,
          action: 'add',
        }));
      acc[section.key] = {
        defaultAccountLimit: matchingLimit.tenantLimit || 0,
        defaultUserLimit: matchingLimit.userLimit || 0,
        overrides:
          customLimitUsersInSection.length > 0
            ? [...customLimitUsersInSection]
            : [{ ...defaultOveride }],
      };
      return acc;
    }, {});
  }, [spendingLimits, filteredSections, customLimitUsers, fetchSpendingLimits]);

  const handleSave = async (values) => {
    try {
      setIsLoading(true);
      const sectionData = filteredSections.map((section) => {
        const sectionValues = values[section.key] || {};
        const tenantLimit = sectionValues.defaultAccountLimit || 0;
        const userLimit = sectionValues.defaultUserLimit || 0;

        return {
          type: section.key,
          tenantLimit: parseInt(tenantLimit),
          userLimit: parseInt(userLimit),
        };
      });
      await updateTenantSpendingLimit(tenantId, sectionData);
      await fetchSpendingLimits();
      setHasChanges(false);
      setIsLoading(false);
      toast.success('User spending limit has been updated');
    } catch (error) {
      toast.error('User spending limit has not been updated');
      setIsLoading(false);
    }
  };

  const limitUsageKeys = {
    data: 'dataCredits',
    aiCoach: 'aiCoachCredits',
    aiCoWorkers: 'aiCoworkersCredits',
  };

  const updatedSections = filteredSections.map((section) => {
    const customLimitUsersArray = Object.values(customLimitUsers);
    const customLimitUsersInSection = customLimitUsersArray
      .filter((user) =>
        user.spendingLimits.some(
          (limit) => limit.type === section.key && limit.hasOwnProperty('customLimit'),
        ),
      )
      .map((user) => ({
        id: user._id,
        fullName: `${user.fname} ${user.lname}`,
        customLimit: user.spendingLimits.find((limit) => limit.type === section.key)?.customLimit,
      }));

    return {
      ...section,
      users: [...customLimitUsersInSection],
      userCount: customLimitUsersInSection.length,
      balanceUsed: limitUsage[limitUsageKeys[section.key]],
    };
  });

  const handleAddUser = (sectionKey, newUsers, action) => {
    setcustomLimitUsers((prev) => {
      const updatedUsers = { ...prev };

      newUsers.forEach(({ user, userLimit }) => {
        const userAlreadyExists = Object.values(updatedUsers).some((existingUser) =>
          existingUser.spendingLimits.some(
            (limit) => limit.type === sectionKey && existingUser.id === user.id,
          ),
        );

        if (!userAlreadyExists && action !== 'remove') {
          updatedUsers[user.id] = {
            ...user,
            spendingLimits: [
              ...(updatedUsers[user.id]?.spendingLimits || []),
              { type: sectionKey, customLimit: userLimit },
            ],
          };
        }
      });

      return updatedUsers;
    });
  };

  const handleInputChange = () => {
    setHasChanges(true);
  };

  const handleRemoveUser = (sectionKey, userId) => {
    setcustomLimitUsers((prev) => {
      const updatedUsers = Object.values(prev).map((user) => {
        if (user._id === userId) {
          const updatedSpendingLimits = user.spendingLimits.filter(
            (limit) => limit.type !== sectionKey,
          );
          return { ...user, spendingLimits: updatedSpendingLimits };
        }
        return user;
      });
      return updatedUsers.reduce((acc, user, index) => ({ ...acc, [index]: user }), {});
    });
  };

  useEffect(() => {
    const unblock = history.block((location, action) => {
      if (hasChanges) {
        setPendingNavigation({ location, action });
        setOpenDialog(true);
        return false;
      }
      return true;
    });
    return () => {
      unblock();
    };
  }, [hasChanges, history]);

  const handleCloseDialog = () => {
    setOpenDialog(false);
    history.block(null);

    if (pendingNavigation) {
      const { location, action } = pendingNavigation;
      if (action === 'POP') {
        history.goBack();
      } else {
        history.push(location);
      }
      setPendingNavigation(null);
    }
  };

  return (
    <Container maxWidth="xl" sx={{ mt: 2 }}>
      <Box>
        {isLoading ? (
          <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
            <CircularProgress />
          </Box>
        ) : (
          <Form enableReinitialize={true} initialValues={initialValues}>
            {({ values }) => (
              <>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="flex-start"
                  sx={{ mb: 2 }}
                >
                  <Box>
                    <Stack direction="row" gap={1} alignItems="center" mb={1}>
                      <MoveDownIcon
                        sx={{ marginRight: '6px', fontSize: '25px', color: '#666666' }}
                      />
                      <Typography variant="h2">Monthly Spending Limits</Typography>
                      <Typography
                        variant="body2"
                        color="secondary"
                        sx={{ cursor: 'pointer' }}
                        onClick={() => setIsLearnMoreOpen(true)}
                      >
                        Learn more
                      </Typography>
                    </Stack>
                    <Box ml={5}>
                      <Typography variant="body2" color="textSecondary">
                        Control your monthly spend for pay-as-you-go services
                      </Typography>
                    </Box>
                  </Box>
                  <Stack direction="row" gap={2}>
                    <Button
                      variant="text"
                      onClick={() => {
                        history.block(null);
                        history.goBack();
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => handleSave(values)}
                      disabled={!hasChanges}
                    >
                      Save
                    </Button>
                  </Stack>
                </Box>

                {updatedSections.map((section, index) => (
                  <SpendingLimitSection
                    key={index}
                    section={section}
                    values={values}
                    onAddUser={handleAddUser}
                    onRemoveUser={handleRemoveUser}
                    onInputChange={handleInputChange}
                    storeRemovedUser={storeRemoved}
                    fetchSpendingLimits={fetchSpendingLimits}
                  />
                ))}

                <LearnMoreModal open={isLearnMoreOpen} onClose={() => setIsLearnMoreOpen(false)} />

                <ConfirmDialog
                  open={openDialog}
                  onClose={handleCloseDialog}
                  onConfirm={() => {
                    handleSave(values);
                    setOpenDialog(false);
                  }}
                  buttonTitle="Save"
                  buttonProps={{
                    variant: 'contained',
                    sx: {
                      backgroundColor: '#EF5350',
                    },
                  }}
                >
                  <Typography>Don't forget to click 'Save' to keep your changes!</Typography>
                </ConfirmDialog>
              </>
            )}
          </Form>
        )}
      </Box>
    </Container>
  );
};

export default SpendingLimits;
