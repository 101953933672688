/* Users Types */

export const FETCH_ADMIN_USERS_TABS = 'FETCH_ADMIN_USERS_TABS';
export const SET_ADMIN_USERS_TABS = 'SET_ADMIN_USERS_TABS';

export const FETCH_ADMIN_USERS = 'FETCH_ADMIN_USERS';
export const FETCH_USERS_DATA = 'FETCH_USERS_DATA';
export const UPDATE_ADMIN_USER = 'UPDATE_ADMIN_USER';
export const SET_ADMIN_USER = 'SET_ADMIN_USER';
export const SAVE_ADMIN_USER = 'SAVE_ADMIN_USER';
export const UPDATE_USER = 'UPDATE_USER';
export const SET_ADMIN_USERS = 'SET_ADMIN_USERS';
export const USER_BULK_UPDATE = 'USER_BULK_UPDATE';

export const UPDATE_ADMIN_USER_STATUS = 'UPDATE_ADMIN_USER_STATUS';
export const SET_ADMIN_USER_STATUS = 'SET_ADMIN_USER_STATUS';
export const UPDATE_USER_STATUS = 'UPDATE_USER_STATUS';
/* Teams Types */

export const FETCH_ADMIN_TEAMS_TABS = 'FETCH_ADMIN_TEAMS_TABS';
export const SET_ADMIN_TEAMS_TABS = 'SET_ADMIN_TEAMS_TABS';

export const FETCH_ADMIN_TEAMS = 'FETCH_ADMIN_TEAMS';
export const SET_ADMIN_TEAMS = 'SET_ADMIN_TEAMS';

export const FETCH_TEAMS_DATA = 'FETCH_TEAMS_DATA';
export const SET_TEAMS_DATA = 'SET_TEAMS_DATA';

export const SET_ADMIN_TEAM = 'SET_ADMIN_TEAM';
export const SAVE_ADMIN_TEAMS = 'SAVE_ADMIN_TEAMS';

export const UPDATE_TEAM_STATUS = 'UPDATE_TEAM_STATUS';
export const SET_TEAM_STATUS = 'SET_TEAM_STATUS';

export const TEAM_BULK_UPDATE = 'TEAM_BULK_UPDATE';
export const USER_BULK_DELETE = 'USER_BULK_DELETE';

export const UPDATE_ADMIN_TEAM = 'UPDATE_ADMIN_TEAM';
export const UPDATE_TEAM_ADMIN = 'UPDATE_TEAM_ADMIN';

/* Offices Types */

export const FETCH_ADMIN_OFFICES_TABS = 'FETCH_ADMIN_OFFICES_TABS';
export const SET_ADMIN_OFFICES_TABS = 'SET_ADMIN_OFFICES_TABS';

export const FETCH_ADMIN_OFFICES = 'FETCH_ADMIN_OFFICES';
export const SET_ADMIN_OFFICES = 'SET_ADMIN_OFFICES';

export const UPDATE_ADMIN_OFFICE = 'UPDATE_ADMIN_OFFICE';
export const UPDATE_OFFICE_ADMIN = 'UPDATE_OFFICE_ADMIN';

export const UPDATE_OFFICE_STATUS = 'UPDATE_OFFICE_STATUS';
export const SET_OFFICE_STATUS = 'SET_OFFICE_STATUS';

export const SAVE_ADMIN_OFFICE = 'SAVE_ADMIN_OFFICE';
export const SET_ADMIN_OFFICE = 'SET_ADMIN_OFFICE';

export const FETCH_OFFICES_DATA = 'FETCH_OFFICES_DATA';
export const SET_OFFICES_DATA = 'SET_OFFICES_DATA';

export const OFFICE_BULK_UPDATE = 'OFFICE_BULK_UPDATE';

/* Templates Types */

export const FETCH_ADMIN_TEMPLATES_TABS = 'FETCH_ADMIN_TEMPLATES_TABS';
export const SET_ADMIN_TEMPLATES_TABS = 'SET_ADMIN_TEMPLATES_TABS';

export const FETCH_ADMIN_TEMPLATES = 'FETCH_ADMIN_TEMPLATES';
export const SET_ADMIN_TEMPLATES = 'SET_ADMIN_TEMPLATES';

export const FETCH_TEMPLATES_DATA = 'FETCH_TEMPLATES_DATA';
export const SET_TEMPLATES_DATA = 'SET_TEMPLATES_DATA';

export const UPDATE_TEMPLATE_STATUS = 'UPDATE_TEMPLATE_STATUS';
export const SET_TEMPLATE_STATUS = 'SET_TEMPLATE_STATUS';

export const SAVE_ADMIN_TEMPLATE = 'SAVE_ADMIN_TEMPLATE';
export const SET_ADMIN_TEMPLATE = 'SET_ADMIN_TEMPLATE';

export const UPDATE_ADMIN_TEMPLATE = 'UPDATE_ADMIN_TEMPLATE';
export const PUT_ADMIN_TEMPLATE = 'PUT_ADMIN_TEMPLATE';

export const TEMPLATE_BULK_UPDATE = 'TEMPLATE_BULK_UPDATE';

/* Integration Types */

export const FETCH_ADMIN_INTEGRATIONS = 'FETCH_ADMIN_INTEGRATIONS';

export const SET_ADMIN_INTEGRATIONS = 'SET_ADMIN_INTEGRATIONS';
export const SET_ADMIN_INTEGRATION = 'SET_ADMIN_INTEGRATION';

export const SAVE_ADMIN_INTEGRATIONS = 'SAVE_ADMIN_INTEGRATIONS';
export const SAVE_ADMIN_RESCHEDULE_EMAIL = 'SAVE_ADMIN_RESCHEDULE_EMAIL';
export const DISCONNECT_ADMIN_INTEGRATION = 'DISCONNECT_ADMIN_INTEGRATION';
export const UPDATE_INTEGRATION_ADMIN = 'UPDATE_INTEGRATION_ADMIN';

/* Setting Types */

export const SETTING_NAME_ADMIN = 'SETTING_NAME_ADMIN';
export const FETCH_SETTING_ADMIN = 'FETCH_SETTING_ADMIN';
export const SET_SETTING_ADMIN = 'SET_SETTING_ADMIN';
export const UPDATE_SETTING_ADMIN = 'UPDATE_SETTING_ADMIN';
export const PUT_SETTING_ADMIN = 'PUT_SETTING_ADMIN';
export const DELETE_EVENT_ADMIN = 'DELETE_EVENT_ADMIN';

export const FETCH_CENTRAL_LOOKUPS = 'FETCH_CENTRAL_LOOKUPS';
export const SET_CENTRAL_LOOKUPS = 'SET_CENTRAL_LOOKUPS';
export const FETCH_CENTRAL_LOOKUP = 'FETCH_CENTRAL_LOOKUP';
export const POST_CENTRAL_LOOKUP = 'POST_CENTRAL_LOOKUP';
export const PUT_CENTRAL_LOOKUP = 'PUT_CENTRAL_LOOKUP';
export const DELETE_CENTRAL_LOOKUP = 'DELETE_CENTRAL_LOOKUP';

export const SYNC_LOOKUP_WITH_CRM = 'SYNC_LOOKUP_WITH_CRM';

export const FETCH_LOOKUP_LIST = 'FETCH_LOOKUP_LIST';
export const SET_LOOKUP_LIST = 'SET_LOOKUP_LIST';
export const POST_LOOKUP_LIST = 'POST_LOOKUP_LIST';
export const PUT_LOOKUP_LIST = 'PUT_LOOKUP_LIST';
export const DELETE_LOOKUP_LIST = 'DELETE_LOOKUP_LIST';
