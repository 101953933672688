import React, { useState, useMemo, useEffect } from 'react';
import { Box, Typography, Stack } from '@mui/material';
import { Button, Form, Modal } from 'src/components/shared';
import { FieldArray } from 'formik';
import * as Yup from 'yup';
import AddIcon from '@mui/icons-material/Add';
import { fetchUsersLookup } from 'src/modules/users/api/usersApi';
import images from 'src/config/images';
import { useSelector } from 'react-redux';
import { updateTenantCustomSpendingLimit } from '../../api/adminApi';
import toast from 'src/utils/toast';
const AddNewUserDialog = ({ open, onClose, sectionKey, values, onInputChange }) => {
  const [users, setUsers] = useState([]);
  const [removedUsers, setRemovedUsers] = useState([]);
  const currentUser = useSelector((state) => state.auth.user);
  const tenantId = currentUser.tenants?.[0].id;
  const [filteredOverrides, setFilteredOverrides] = useState([]);
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setFilteredOverrides(
      values?.[sectionKey]?.overrides?.filter((override) => override.action !== 'remove') || [],
    );
  }, [values, sectionKey]);

  const handleUserLookup = async (val) => {
    const fetchedUsers = await fetchUsersLookup(val);
    const selectedUserIds = new Set(filteredOverrides.map((override) => override.user?._id));
    const filteredUsers = fetchedUsers.filter((user) => !selectedUserIds.has(user._id));
    setUsers(filteredUsers);
    return filteredUsers;
  };

  const handleUserChange = (index, value, arrayHelpers) => {
    const updatedOverrides = [...filteredOverrides];
    const existingUser = updatedOverrides[index];

    if (existingUser && existingUser.user?.id !== value.id) {
      if (!existingUser.isNew) {
        setRemovedUsers((prev) => [...prev, { ...existingUser, action: 'remove', userLimit: 0 }]);
      }
      updatedOverrides[index] = {
        ...existingUser,
        user: value,
        action: existingUser.isNew ? 'add' : 'update',
      };

      arrayHelpers.replace(index, updatedOverrides[index]);
      setFilteredOverrides(updatedOverrides);
    }
  };

  const handleRemove = (index, arrayHelpers) => {
    const updatedOverrides = [...filteredOverrides];
    const removedUser = updatedOverrides.splice(index, 1)[0];

    if (!removedUser.isNew) {
      setRemovedUsers((prev) => [...prev, { ...removedUser, action: 'remove', userLimit: 0 }]);
    }

    arrayHelpers.remove(index);
    setFilteredOverrides(updatedOverrides.filter((override) => override.action !== 'remove'));
  };

  const handleClose = () => {
    onClose();
  };

  const title = useMemo(() => {
    if (sectionKey === 'data') {
      return 'Add Custom User Limits - Data and Email';
    }
    if (sectionKey === 'aiCoach') {
      return 'Add Custom User Limits - AI Coach';
    }
    return 'Add Custom User Limits - AI Co-Workers';
  }, [sectionKey]);

  const handleSave = async () => {
    try {
      setIsLoading(true);
      const currentOverrides = filteredOverrides
        .filter(
          (override) => override.user && override.userLimit >= 0 && override.action !== 'remove',
        )
        .map((override) => ({
          ...override,
          userLimit: Number(override.userLimit) || 0,
          action: override.action || 'add',
        }));

      const allOverrides = [
        ...currentOverrides,
        ...removedUsers.map((user) => ({
          ...user,
          userLimit: Number(user.userLimit) || 0,
        })),
      ];

      const transformedOverrides = allOverrides.map((override) => ({
        id: override.user._id,
        customLimit: override.userLimit,
        action: override.action === 'update' ? 'add' : override.action,
      }));

      const newData = {
        type: sectionKey,
        customLimits: transformedOverrides,
      };
      await updateTenantCustomSpendingLimit(tenantId, newData);
      toast.success('User spending limit has been updated');
      setFilteredOverrides((prev) =>
        prev.filter(
          (override) => override.user && override.userLimit >= 0 && override.action !== 'remove',
        ),
      );

      onClose(allOverrides);
    } catch (error) {
      toast.error('User spending limit has not been updated');
    } finally {
      setIsLoading(false);
    }
  };

  const validationSchema = Yup.object().shape({
    overrides: Yup.array().of(
      Yup.object().shape({
        userLimit: Yup.number()
          .positive('User limit must be positive')
          .min(0, 'User limit cannot be negative')
          .required('User limit is required'),
      }),
    ),
  });

  const handleUserLimitChange = (value, index) => {
    const parsedValue = value === '' ? undefined : Number(value);
    setFilteredOverrides((prevOverrides) => {
      const updatedOverrides = prevOverrides.map((override, i) =>
        i === index ? { ...override, userLimit: parsedValue } : override,
      );

      validationSchema
        .validateAt(`overrides[${index}].userLimit`, { overrides: updatedOverrides })
        .then(() => {
          setErrors((prevErrors) => {
            const newErrors = { ...prevErrors };
            if (newErrors?.overrides?.[index]) {
              delete newErrors.overrides[index].userLimit;
            }
            return newErrors;
          });
        })
        .catch((err) => {
          setErrors((prevErrors) => ({
            ...prevErrors,
            overrides: {
              ...prevErrors.overrides,
              [index]: {
                ...(prevErrors.overrides?.[index] || {}),
                userLimit: err.message,
              },
            },
          }));
        });
      return updatedOverrides;
    });
  };

  const hasErrors = useMemo(() => {
    return filteredOverrides.some((_, index) => errors?.overrides?.[index]?.userLimit);
  }, [errors, filteredOverrides]);

  return (
    <FieldArray
      enableReinitialize={true}
      name={`${sectionKey}.overrides`}
      validateOnChange={true}
      render={(arrayHelpers) => (
        <Modal
          PaperProps={{
            sx: {
              borderRadius: '10px',
            },
          }}
          titleStyle={{
            mt: 2,
          }}
          open={Boolean(open)}
          onClose={handleClose}
          draggable={false}
          title={title}
          showCloseIcon
          maxWidth="sm"
          renderActions={() => (
            <Stack
              direction="row"
              justifyContent="space-between"
              width="100%"
              sx={{ px: 2, pb: 2 }}
            >
              <Button
                color="secondary"
                onClick={() => {
                  arrayHelpers.push({
                    user: '',
                    userLimit: 0,
                    action: 'add',
                    isNew: true,
                  });
                }}
                sx={{
                  transition: 'all 0.3s ease',
                }}
                startIcon={<AddIcon sx={{ marginRight: '6px', color: '#1976D2' }} />}
              >
                Add another user
              </Button>
              <Button
                variant="contained"
                color="secondary"
                onClick={handleSave}
                disabled={hasErrors}
                loading={isLoading}
              >
                Save
              </Button>
            </Stack>
          )}
        >
          <Box
            sx={{
              mt: 2,
              display: 'flex',
              flexDirection: 'column',
              transition: 'all 0.3s ease',
            }}
          >
            {filteredOverrides.map((override, index) => (
              <Stack
                direction="row"
                spacing={2}
                key={index}
                alignItems="flex-start"
                sx={{
                  '&:hover .remove-button': {
                    opacity: 1,
                  },
                }}
              >
                <Stack sx={{ flex: 1, maxWidth: '44%' }}>
                  {index === 0 && (
                    <Typography variant="body2" color="textPrimary">
                      User
                    </Typography>
                  )}
                  <Form.Field.AutoComplete
                    disableClearable
                    remoteMethod={handleUserLookup}
                    name={`${sectionKey}.overrides[${index}].user`}
                    fullWidth
                    variant="outlined"
                    size="small"
                    optLabel="name"
                    optValue="id"
                    options={users}
                    onChange={(value) => handleUserChange(index, value, arrayHelpers)}
                    value={override.user}
                  />
                </Stack>
                <Stack sx={{ flex: 1, maxWidth: '44%' }}>
                  {index === 0 && (
                    <Typography variant="body2" color="textPrimary">
                      User Limit (Per Month)
                    </Typography>
                  )}
                  <Form.Field.Input
                    name={`${sectionKey}.overrides[${index}].userLimit`}
                    type="number"
                    fullWidth
                    variant="outlined"
                    size="small"
                    InputProps={{
                      startAdornment: (
                        <Box mr={0.5}>
                          <Typography color="textSecondary">$</Typography>
                        </Box>
                      ),
                    }}
                    inputProps={{
                      style: {
                        textAlign: 'left',
                      },
                    }}
                    error={Boolean(errors?.overrides?.[index]?.userLimit)}
                    helperText={errors?.overrides?.[index]?.userLimit || ''}
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: Boolean(errors?.overrides?.[index]?.userLimit)
                            ? 'red'
                            : 'rgba(0, 0, 0, 0.23)',
                        },
                        '&:hover fieldset': {
                          borderColor: Boolean(errors?.overrides?.[index]?.userLimit)
                            ? 'red !important'
                            : '#1976D2',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: Boolean(errors?.overrides?.[index]?.userLimit)
                            ? 'red !important'
                            : '#1976D2',
                        },
                      },
                      '& .MuiFormHelperText-root': {
                        color: 'red',
                      },
                    }}
                    onChange={(value) => {
                      handleUserLimitChange(value, index);
                    }}
                    placeholder="20"
                  />
                </Stack>
                {values[sectionKey].overrides.length > 1 && (
                  <Stack sx={{ marginTop: index === 0 ? '30px !important' : '15px !important' }}>
                    <Button
                      className="remove-button"
                      onClick={() => handleRemove(index, arrayHelpers)}
                      iconButton
                      sx={{
                        opacity: 0,
                        transition: 'opacity 0.3s ease',
                      }}
                    >
                      <img src={images.app.deleteIcon} alt="delete" />
                    </Button>
                  </Stack>
                )}
              </Stack>
            ))}
          </Box>
        </Modal>
      )}
    />
  );
};

export default AddNewUserDialog;
