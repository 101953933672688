import api from 'src/api';
import toast from 'src/utils/toast';
import { convertObjectToQuerystring } from 'src/utils/helper';

export const getEnrichCreditAllocation = () => {
  return api('enrich/creditallocation/summary', null, 'get');
};

export const updateDefEnrichCreditAllocation = (data) => {
  return api(`enrich/assigncredit`, data, 'post');
};

export const createEnrichCreditAllocation = (userId, data) => {
  return api(`enrich/assigncredit/${userId}`, data, 'post');
};

// export const updateEnrichCreditAllocation = (userId, data) => {
//   return api(`enrich/assigncredit/${userId}`, data, 'post');
// };

// export const deleteEnrichCreditAllocation = (userId, data) => {
//   return api(`enrich/assigncredit/${userId}`, data, 'post');
// };

export const fetchEnrichWaterfall = () => {
  return api('waterfall', null, 'get');
};

export const putEnrichWaterfall = (id, data) => {
  return api(`/waterfall/${id}`, data, 'put');
};

export const resetEnrichWaterfall = (id) => {
  return api(`/waterfall/restore/${id}`, {}, 'put');
};

export const putEnrichWaterfallService = (id, data) => {
  return api(`/waterfall/service/${id}`, data, 'put');
};

export const postEnrichWaterfall = (data) => {
  return api(`/waterfall`, data, 'post');
};

export const getEnrichConfig = () => {
  return api('enrich/config', null, 'get');
};

export const putEnrichConfig = (id, data) => {
  return api(`enrich/config/${id}`, data, 'put');
};

export const fetchEnrichSummary = (query) => {
  let filter = '';
  if (!_.isEmpty(query)) {
    filter = `?${convertObjectToQuerystring(query)}`;
  }
  return api(`enrich/summary${filter}`, null, 'get');
};

export const fetchEnrichUserSummary = (query) => {
  let filter = '';
  if (!_.isEmpty(query)) {
    filter = `?${convertObjectToQuerystring(query)}`;
  }
  return api(`enrich/usersummary${filter}`, null, 'get');
};

export const fetchEnrichCreditSummary = (query) => {
  let filter = '';
  if (!_.isEmpty(query)) {
    filter = `?${convertObjectToQuerystring(query)}`;
  }
  return api(`enrich/creditsummary${filter}`, null, 'get');
};

export const fetchEnrichCreditUsage = (query) => {
  let filter = '';
  if (!_.isEmpty(query)) {
    filter = `?${convertObjectToQuerystring(query)}`;
  }
  return api(`enrich/creditusage${filter}`, null, 'get');
};

export const fetchLookups = () => {
  return api('/lookup', null, 'get');
};

/* Users Api's */
export const fetchTimezone = () => {
  return api('/timezones', null, 'get');
};
export const fetchCountry = () => {
  return api('/countries', null, 'get');
};

export const getUserTabs = () => {
  return api('/user?lname%5Bcontains%5D=gup', null, 'get');
};

export const fetchUsers = (filters) => {
  let filter = '';
  if (!_.isEmpty(filters)) {
    filter = `?${convertObjectToQuerystring(filters)}`;
  }
  return api(`/user${filter}`, null, 'get');
};

export const saveUserData = async (data) => {
  const userData = await api('/user', data, 'post');
  return userData;
};
export const fetchUserById = async (id) => {
  return await api(`/user/${id}`, null, 'get');
};

export const updateUserStatus = (rowData) => {
  const payload = {
    status: rowData.status === 'active' ? 'inactive' : 'active',
  };
  return api(`/user/${rowData.id}`, payload, 'put');
};

export const updateUserData = (rowData) => {
  return api(`/user/${rowData.id}`, rowData.data, 'put');
};

export const userBulkUpdate = (payload) => {
  return api(`/user/bulk`, payload, 'put');
};
export const teamBulkUpdate = (payload) => {
  return api(`/team/bulk`, payload, 'put');
};
export const templateBulkUpdate = (payload, loadType) => {
  return api(`/template/bulk?type_eq=${loadType}`, payload, 'put');
};
export const officeBulkUpdate = (payload) => {
  return api(`/office/bulk`, payload, 'put');
};
export const userBulkDelete = (payload) => {
  return api(`/user/bulk`, payload, 'delete');
};

/* Teams Api's */

export const fetchUsersLookup = async (search, total = 20) => {
  let res = [];
  const filters = {
    _from: 0,
    _size: total,
    _search: search,
    status_eq: 'active',
  };
  let filter = `?${convertObjectToQuerystring(filters)}`;
  try {
    const response = await api(`/user${filter}`, null, 'get');
    res = response.users
      .map((i, key) => ({ id: i.id, name: `${i.fname} ${i.lname}`, status: i.status }))
      .sort((a, b) => {
        if (a.status === 'active') {
          return -1;
        }
        if (b.status === 'active') {
          return 1;
        }
        return a.active < b.active ? -1 : 1;
      });
    return res;
  } catch (error) {
    return res;
  }
};

export const fetchTeamsLookup = async (search, total = 100) => {
  let res = [];
  const filters = {
    _from: 0,
    _size: total,
    _search: search,
    status_eq: 'active',
  };
  let filter = `?${convertObjectToQuerystring(filters)}`;
  try {
    const response = await api(`/team${filter}`, null, 'get');
    res = response.teams
      .map((i, key) => ({
        id: i.id,
        value: i.name,
        status: i.status,
        name: i.name,
        users: i.teamMembers && i.teamMembers.length ? i.teamMembers.map((user) => user.id) : [],
      }))
      .sort((a, b) => {
        if (a.status === 'active') {
          return -1;
        }
        if (b.status === 'active') {
          return 1;
        }
        return a.active < b.active ? -1 : 1;
      });
    return res;
  } catch (error) {
    return res;
  }
};
export const fetchAccountsLookup = async (search, total = 100) => {
  let res = [];
  const filters = {
    _from: 0,
    _size: total,
    _name: search,
  };
  let filter = `?${convertObjectToQuerystring(filters)}`;
  try {
    const response = await api(`/account${filter}`, null, 'get');
    res = response.accounts;
    return res;
  } catch (error) {
    return error;
  }
};

export const AddCRMLookup = async (data) => {
  try {
    const response = await api(`/crmsync/crmCompanyAdd`, data, 'post');
    return response;
  } catch (error) {
    if (error?.error?.message) toast.error(error?.error?.message);
    return error;
  }
};

export const fetchCRMAccountsLookup = async (crmType, search = '') => {
  let res = [];
  const filters = {
    service: crmType,
    name: search,
  };
  let filter = `?${convertObjectToQuerystring(filters)}`;
  try {
    const response = await api(`/crmsync/crmCompanySearch${filter}`, null, 'get');
    res = response?.companies || [];
    return res;
  } catch (error) {
    return [];
  }
};

export const fetchTimezoneLookup = async (search) => {
  let res = [];
  const filters = {
    _search: search,
  };
  let filter = `?${convertObjectToQuerystring(filters)}`;
  try {
    const response = await api(`/timezones${filter}`, null, 'get');
    res = response.timezones.map((i, key) => ({ id: i.id, value: i.timeZone }));
    return res;
  } catch (error) {
    return res;
  }
};

export const fetchCountryLookup = async (search) => {
  let res = [];
  const filters = {
    _search: search,
  };
  let filter = `?${convertObjectToQuerystring(filters)}`;
  try {
    const response = await api(`/countries${filter}`, null, 'get');
    res = response.countries.map((i, key) => ({ id: i.id, value: i.country }));
    return res;
  } catch (error) {
    return res;
  }
};
export const fetchOfficesLookup = async (search, total) => {
  let res = [];
  const filters = {
    _from: 0,
    _size: total,
    _search: search,
    status_eq: 'active',
  };
  let filter = `?${convertObjectToQuerystring(filters)}`;
  try {
    const response = await api(`/office${filter}`, null, 'get');
    res = response.offices
      .map((i, key) => ({ id: i.id, value: i.name, status: i.status }))
      .sort((a, b) => {
        if (a.status === 'active') {
          return -1;
        }
        if (b.status === 'active') {
          return 1;
        }
        return a.active < b.active ? -1 : 1;
      });
    return res;
  } catch (error) {
    return res;
  }
};

export const fetchParentTeamsLookup = async (search) => {
  let res = [];
  const filters = {
    _search: search,
    isParent: true,
    status_eq: 'active',
  };
  let filter = `${convertObjectToQuerystring(filters)}`;
  try {
    const response = await api(`/team?${filter}`, null, 'get');
    res = response.teams.map((i, key) => ({ id: i.id, value: i.name, status: i.status }));
    return res;
  } catch (error) {
    return res;
  }
};

export const fetchTeams = (filters) => {
  let filter = '';
  if (!_.isEmpty(filters)) {
    filter = `?${convertObjectToQuerystring(filters)}`;
  }
  return api(`/team${filter}`, null, 'get');
};
export const fetchAllTeams = (total) => {
  return api(`/team?status_eq=${'active'}&&_size=${total}`, null, 'get');
};

export const fetchTeam = (teamId) => {
  return api(`/team/${teamId}`, null, 'get');
};

export const fetchAllUsers = (total = 1000) => {
  return api(`/user?status_eq=${'active'}&&_size=${total}`, null, 'get');
};

export const saveTeamData = (data) => {
  return api('/team', data, 'post');
};

export const saveAccountData = (data) => {
  return api('/account', data, 'post');
};

export const updateTeam = (id, rowData) => {
  return api(`/team/${id}`, rowData, 'put');
};

export const updateTeamStatus = (rowData) => {
  const payload = {
    status: rowData.status === 'active' ? '' : 'active',
  };
  return api(`/team/${rowData.id}`, payload, 'put');
};

/* Templates Api's */
export const fetchTemplate = (filters) => {
  let filter = '';
  if (!_.isEmpty(filters)) {
    filter = `?${convertObjectToQuerystring(filters)}`;
  }
  return api(`/template${filter}`, null, 'get');
};

export const fetchTemplateDetails = (templateId) => {
  return api(`/template/${templateId}`, null, 'get');
};

export const fetchAllTeamlates = (total, type) => {
  return api(`/template?status_eq=${'active'}&_size=${total}&type_eq=${type}`, null, 'get');
};

export const saveTemplate = (data) => {
  return api('/template', data, 'post');
};

export const updateTemplate = (id, rowData) => {
  return api(`/template/${id}`, rowData, 'put');
};
export const fetchTemplatePopulated = (id) => {
  return api(`/template/${id}/populate`, null, 'get');
};

export const updateTemplatesStatus = (rowData) => {
  const payload = {
    status: rowData.status === 'active' ? 'inactive' : 'active',
  };
  return api(`/template/${rowData.id}`, payload, 'put');
};

export const fetchTemplatesData = async (start, pagesize, path, type = 'email') => {
  let res;
  if (path === '/templates') {
    type = 'email';
  } else if (path === 'linkedin-template') {
    type = 'linkedin';
  } else if (path === undefined) {
    type = 'email';
  }

  if (start !== undefined && pagesize !== undefined) {
    res = api(`template?_from=${start}&_size=${pagesize}&type_eq=${type}`, null, 'get');
  }
  return res;
};

/* Offices Api's */

export const fetchOffices = (filters) => {
  let filter = '';
  if (!_.isEmpty(filters)) {
    filter = `?${convertObjectToQuerystring(filters)}`;
  }
  return api(`/office${filter}`, null, 'get');
};
export const fetchAllOffices = (total) => {
  return api(`/office?status_eq=${'active'}&_size=${total}`, null, 'get');
};

export const saveOfficeData = (data) => {
  return api('/office', data, 'post');
};

export const updateOfficesData = (id, rowData) => {
  return api(`/office/${id}`, rowData, 'put');
};

export const updateOfficeStatus = (rowData) => {
  const payload = {
    status: rowData.status === 'active' ? 'inactive' : 'active',
  };
  return api(`/office/${rowData.id}`, payload, 'put');
};

export const fetchOfficesData = async (start, pagesize) => {
  let res;
  if (start !== undefined && pagesize !== undefined) {
    res = api(`office?_from=${start}&_size=${pagesize}`, null, 'get');
  }
  return res;
};

export const fetchLookupListsLookup = async (search) => {
  let res = [];
  const filters = {
    _from: 0,
    _size: 10,
    _search: search,
  };
  let filter = `?${convertObjectToQuerystring(filters)}`;
  filter = '';
  try {
    const response = await api(`/lookuplist${filter}`, null, 'get');
    res = response.lookupLists;
    return res;
  } catch (error) {
    return res;
  }
};

/* Integration Api */

export const fetchIntegrations = () => {
  return api(`/tenantintegration`);
};

export const saveIntegrations = async (data) => {
  if (data.status === '') {
    data.status = 'active';
  } else {
    data.status = data.status ? 'active' : 'inactive';
  }
  const checkapi = await api(`tenantintegration?name=${data?.name}`, null, 'get');
  if (checkapi?.total > 0) {
    return api(`tenantintegration/${data?.id}`, data, 'put');
  } else {
    delete data.id;
    return api(`tenantintegration`, data, 'post');
  }
};

export const saveRescheduleEmail = (date) => {
  return api(`task/reschedule`, { date }, 'post');
};

export const validateIntegration = (data) => {
  return api(`/tenantintegration/validatetenantintegration`, data, 'post');
};

export const disconnectIntegration = (id, status) => {
  let newStatus =
    status === 'active'
      ? { status: 'inactive', integration: { apiKey: '' } }
      : { status: 'active', integration: { apiKey: '' } };
  // newStatus = newStatus.integration.apikey = '';
  return api(`tenantintegration/${id}`, newStatus, 'put');
};

/* Tenant Settings Api */

export const fetchTenantSettings = () => {
  return api(`/tenantsetting`);
};

export const fetchTenantSpendingLimit = (tenantId) => {
  return api(`/tenant/${tenantId}/spendingLimit`, null, 'get');
};

export const fetchTenantSpendingUsage = () => {
  return api(`/credits/usage`, null, 'get');
};

export const updateTenantSpendingLimit = (tenantId, data) => {
  return api(`/tenant/${tenantId}/spendingLimit`, data, 'put');
};
export const updateTenantCustomSpendingLimit = (tenantId, data) => {
  return api(`/tenant/${tenantId}/spendingLimit/custom`, data, 'put');
};

export const updateTenantSettings = (data, id = '') => {
  if (data.holidays && data.holidays.length > 0) {
    return api(`/tenantsetting/${id}`, data, 'put');
  } else if (data.blockedDomains && data.blockedDomains.length > 0) {
    return api(`/tenantsetting/${id}`, data, 'put');
  } else {
    let tenantId = data?.id || id;
    delete data?.id;
    return api(`/tenantsetting/${tenantId}`, data, 'put');
  }
};

export const fetchChargifyLink = () => {
  return api(`/tenant/getChargifyLink`);
};

export const fetchRoleplay = (filters) => {
  let filter = `?${convertObjectToQuerystring(filters)}`;
  return api(`/roleplay/findroleplay${filter}`);
};

export const fetchSingleRoleplay = (id) => {
  return api(`/roleplay/${id}`);
};
export const updateRoleplay = (data, id) => {
  return api(`/roleplay/${id}`, data, 'put');
};

export const updateTechnique = (data, id) => {
  return api(`roleplay/technique/${id}`, data, 'put');
};
export const createTechnique = (data) => {
  return api(`roleplay/technique`, data, 'post');
};
export const updateObjection = (data, id) => {
  return api(`roleplay/objection/${id}`, data, 'put');
};
export const createObjection = (data) => {
  return api(`roleplay/objection`, data, 'post');
};

export const fetchSingleSession = (id) => {
  return api(`/lms/session/${id}`);
};

export const testRoleplay = (sid, rid) => {
  return api(`/roleplay/embedurl?session=${sid}&roleplay=${rid}`);
};
