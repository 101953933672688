import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import { Grid, Box } from '@mui/material';
import { Button, Form } from 'src/components/shared';
import Typography from '@mui/material/Typography';
import { getCandidatesLookup, getAssignToLookup } from 'src/modules/users/api/usersApi';
import { fetchTeams, fetchOffices } from 'src/modules/auth/api/authApis';
import {
  fetchTeamsLookup,
  fetchOfficesLookup,
  fetchTimezoneLookup,
} from 'src/modules/admin/api/adminApi';

function AccountForm({ editUser, status, formData, officeTotal, teamsTotal, ...props }) {
  const [loading, setLoading] = useState(false);
  const [defaultCandidate, setDefaultCandidate] = useState({});

  useEffect(() => {
    if (editUser && editUser?.id) {
      setDefaultCandidate(editUser);
    }
  }, [editUser]);

  const handleSubmit = async (values, form) => {
    try {
      setLoading(true);
      const data = { ...values };
      data.office = data.office.id ?? [];
      data.timezone = data.timezone.value;
      data.team = data.team.id ?? [];
      if (editUser && editUser?.id) {
        const updatedRow = { id: editUser?.id, data: data };
        await props.putUser(updatedRow);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <Form
      initialValues={{
        team: defaultCandidate?.team
          ? {
              id: defaultCandidate?.team && defaultCandidate?.team[0]?.id,
              value: defaultCandidate?.team && defaultCandidate?.team[0]?.name,
            }
          : {},
        office: defaultCandidate?.office
          ? {
              id: defaultCandidate?.office && defaultCandidate?.office[0]?.id,
              value: defaultCandidate?.office && defaultCandidate?.office[0]?.name,
            }
          : {},
        timezone: defaultCandidate?.id ? { value: defaultCandidate?.timezone } : {},
      }}
      validationSchema={Yup.object().shape({
        timezone: Yup.object().test('timezone', 'Please choose a timezone..', function (item) {
          return item?.value && item?.value !== '';
        }),
      })}
      enableReinitialize={true}
      onSubmit={handleSubmit}
      validateOnBlur={true}
      validateOnChange={false}
    >
      {(props) => {
        return (
          <form
            onSubmit={(e) => {
              e.preventDefault();
              props.submitForm();
              return false;
            }}
            style={{ height: '100%' }}
            noValidate
          >
            <Box display="flex" flexDirection="column" height="100%">
              <Box>
                <Typography variant="h4" gutterBottom>
                  Account
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Form.Field.AutoComplete
                      options={[]}
                      fullWidth
                      name="office"
                      label="Office"
                      variant="outlined"
                      remoteMethod={(val) => {
                        return fetchOfficesLookup(val, 100);
                      }}
                      optValue="id"
                      optLabel="value"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Form.Field.AutoComplete
                      options={[]}
                      fullWidth
                      name="timezone"
                      label="Timezone"
                      placeholder="Timezone"
                      variant="outlined"
                      remoteMethod={(val) => {
                        return fetchTimezoneLookup(val, 100);
                      }}
                      optValue="value"
                      optLabel="value"
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <Form.Field.AutoComplete
                      options={[]}
                      fullWidth
                      name="team"
                      label="Team"
                      variant="outlined"
                      remoteMethod={(val) => {
                        return fetchTeamsLookup(val, 100);
                      }}
                      optValue="id"
                      optLabel="value"
                      multiple={false}
                    />
                  </Grid>
                </Grid>
              </Box>

              <Box mt={2} display="flex" justifyContent="flex-end">
                <Button
                  variant="contained"
                  color="secondary"
                  type="submit"
                  loading={loading}
                  disabled={loading}
                >
                  Update
                </Button>
              </Box>
            </Box>
          </form>
        );
      }}
    </Form>
  );
}

export default AccountForm;
