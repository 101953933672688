import { Box, Card, CardContent, Skeleton, Stack, Typography } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { Button, Form } from 'src/components/shared';
import { useSelector, useDispatch } from 'react-redux';
import { getTeams, getUsers } from 'src/modules/auth/api/authApis';
import useRequest from 'src/components/hooks/useRequest';
import images from 'src/config/images';
import { palette } from 'src/config/theme';
import { updateTenant } from 'src/modules/app/api/appApis';
import toast from 'src/utils/toast';
import { setTenantConfig } from 'src/modules/app/actions/appActions';

const commonStyles = {
  formContainer: { width: '70%', mt: 2 },
  inputProps: {
    size: 'small',
    fullWidth: true,
    sx: {
      margin: 0,
      '& .MuiOutlinedInput-input': { padding: '15px 16px' },
      '& .react-tel-input .form-control': { height: '53px !important' },
      '& .react-tel-input .selected-flag': { height: '53px !important' },
    },
    variant: 'outlined',
  },
};

const FormField = ({
  label,
  name,
  placeholder,
  component: Component = Form.Field.Input,
  multiline,
  maxRows,
  onChange,
}) => (
  <Box
    display="flex"
    flexDirection="row"
    gap={2}
    alignItems="center"
    sx={commonStyles.formContainer}
  >
    <Typography variant="body4" color={palette.text.black1} sx={{ width: '50%' }}>
      {label}
    </Typography>
    <Component
      name={name}
      placeholder={placeholder}
      hideAsterisk
      {...commonStyles.inputProps}
      multiline={multiline}
      maxRows={maxRows}
      onChange={onChange}
    />
  </Box>
);

const TeamInfo = ({ teamDetails, companyUsers }) => {
  const teamInfo = useMemo(
    () => [
      { name: 'Teams', members: teamDetails?.total?.value || 0 },
      { name: 'Users', members: companyUsers?.total?.value || 0 },
    ],
    [teamDetails, companyUsers],
  );

  return (
    <Box display="flex" alignItems="center" gap={1}>
      {teamInfo.map(({ name, members }) => (
        <Stack direction="row" alignItems="center" gap={1} key={name}>
          <Typography variant="body4">{name}:</Typography>
          <Typography variant="body4" color={palette.text.black1}>
            {members}
          </Typography>
        </Stack>
      ))}
    </Box>
  );
};

const SkeletonLoader = () => (
  <Box sx={commonStyles.formContainer}>
    <Skeleton variant="text" width="50%" height={30} />
    <Skeleton variant="rectangular" height={40} sx={{ marginTop: 1 }} />
  </Box>
);

const CompanyProfile = () => {
  const dispatch = useDispatch();
  const tenant = useSelector((state) => state.app.tenant);
  const [isLoading, setIsLoading] = useState(null);
  const [teamDetails] = useRequest(getTeams);
  const [companyUsers] = useRequest(getUsers);
  const [formValues, setFormValues] = useState(null);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  useEffect(() => {
    if (tenant) {
      setIsLoading(!tenant || Object.keys(tenant).length === 0);
      setFormValues({
        companyWebURL: tenant?.companyWebURL || tenant?.domain || '',
        companyLinkedInProfile: tenant?.companyLinkedInProfile || '',
        companyOverview: tenant?.companyOverview || '',
        industry: tenant?.industry || '',
        companyType: tenant?.companyType || '',
        companySpeciality: tenant?.companySpecialities || '',
        location: tenant?.location || '',
        technologies: tenant?.technologies || '',
        followers: tenant?.followers || '',
      });
    }
  }, [tenant]);

  const handleChange = (keyName, event) => {
    setFormValues((prev) => {
      const existingValue = prev[keyName] || '';
      const newValue = event?.target?.value?.trim() || '';

      return {
        ...prev,
        [keyName]: newValue ? `${existingValue} ${newValue}`.trim() : existingValue,
      };
    });

    setIsButtonDisabled(false);
  };

  const handleSubmit = async (event, values) => {
    event.preventDefault();
    setIsLoading(true);
    try {
      const data = { ...values, domain: values?.companyWebURL };
      const res = await updateTenant(tenant?.id, data);
      dispatch(setTenantConfig(res?.tenant[0]));
      setFormValues({
        companyWebURL: res?.tenant[0]?.companyWebURL || res?.tenant[0].domain || '',
        companyLinkedInProfile: res?.tenant[0].companyLinkedInProfile || '',
        companyOverview: res?.tenant[0]?.companyOverview || '',
        industry: res?.tenant[0]?.industry || '',
        companyType: res?.tenant[0]?.companyType || '',
        companySpeciality: res?.tenant[0]?.companySpecialities || '',
        location: res?.tenant[0]?.location || '',
        technologies: res?.tenant[0]?.technologies || '',
        followers: res?.tenant[0]?.followers || '',
      });
      toast.success('Company profile successfully updated');
    } catch (err) {
      console.error('Update error:', err);
      toast.error('Something went wrong, unable to update');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box sx={{ p: '12px 0' }}>
      <Card sx={{ borderRadius: '8px', border: '1px solid #CCCBCA' }}>
        <CardContent sx={{ padding: '20px !important' }}>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
            <Box display="flex" alignItems="center" gap={2}>
              <img src={images.icons.CompanyIcon} alt="CompanyIcon" />
              <Typography variant="body3" color={palette.text.black1}>
                Company Name
              </Typography>
            </Box>
            <TeamInfo teamDetails={teamDetails} companyUsers={companyUsers} />
          </Stack>
          {isLoading || !formValues ? (
            <>
              <SkeletonLoader />
              <SkeletonLoader />
              <SkeletonLoader />
            </>
          ) : (
            <Form key={JSON.stringify(formValues)} initialValues={formValues}>
              {(values) => (
                <>
                  <form noValidate onSubmit={(event) => handleSubmit(event, values?.values)}>
                    <FormField
                      label="Company Web URL"
                      name="companyWebURL"
                      placeholder="Company Web URL"
                      onChange={(event) => handleChange('companyWebURL', event)}
                    />
                    <FormField
                      label="Company LinkedIn Profile"
                      name="companyLinkedInProfile"
                      placeholder="Company LinkedIn Profile"
                      onChange={(event) => handleChange('companyLinkedInProfile', event)}
                    />
                    <Box
                      display="flex"
                      flexDirection="row"
                      gap={2}
                      alignItems="center"
                      sx={commonStyles.formContainer}
                    >
                      <Typography variant="body4" color={palette.text.black1} sx={{ width: '50%' }}>
                        Company Overview
                      </Typography>
                      <Form.Field.Input
                        fullWidth
                        variant="outlined"
                        name="companyOverview"
                        placeholder="Company Overview"
                        multiline={true}
                        maxRows={3}
                        minRows={3}
                        onChange={(event) => handleChange('companyOverview', event)}
                      />
                    </Box>
                    <FormField
                      label="Industry"
                      name="industry"
                      placeholder="Industry"
                      onChange={(event) => handleChange('industry', event)}
                    />
                    <FormField
                      label="Company Type"
                      name="companyType"
                      placeholder="Company Type"
                      onChange={(event) => handleChange('companyType', event)}
                    />
                    <FormField
                      label="Company Specialities"
                      name="companySpeciality"
                      placeholder="Company Specialities"
                      onChange={(event) => handleChange('companySpeciality', event)}
                    />
                    <FormField
                      label="Location"
                      name="location"
                      placeholder="Location"
                      onChange={(event) => handleChange('location', event)}
                    />
                    <FormField
                      label="Technologies"
                      name="technologies"
                      placeholder="Technologies"
                      onChange={(event) => handleChange('technologies', event)}
                    />
                    <FormField
                      label="Followers"
                      name="followers"
                      placeholder="Followers"
                      onChange={(event) => handleChange('followers', event)}
                    />
                    <Box display="flex" alignItems="center" justifyContent="flex-end">
                      <Button
                        type="submit"
                        variant="contained"
                        color="secondary"
                        disabled={isButtonDisabled}
                        sx={{ mt: 2, mr: 4 }}
                      >
                        Update
                      </Button>
                    </Box>
                  </form>
                </>
              )}
            </Form>
          )}
        </CardContent>
      </Card>
    </Box>
  );
};

export default CompanyProfile;
