import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { calculateDaysDifference, getDateDifferencePercentage } from 'src/utils/dateUtils';
import { formatCurrency } from 'src/utils/helper';

const subscriptionPlans = [
  {
    label: 'Premium',
    value: 'premium',
    plans: ['One-Membership-GBP-Yearly', 'One-Membership-Monthly-GBP-Monthly'],
  },
  {
    label: 'Freemium',
    value: 'freemium',
    plans: ['One-Membership-Freemium-Monthly-GBP-Monthly'],
  },
  {
    label: 'Free Trial',
    value: 'trial',
    plans: ['One-Membership-Trial-Plan-GBP-14-days', 'One-Membership-Trial-Plan-GBP-7-days'],
  },
];

const useSubscription = () => {
  const user = useSelector((state) => state.auth.user);

  const activeSubscriptionPlan = useMemo(() => {
    if (!user?.tenants?.length || !user?.tenants[0]?.subscription) {
      return {};
    }

    const subscriptionData = user.tenants[0].subscription;

    let activeSubscriptionData = {};

    Object.keys(subscriptionData).some((planKey) => {
      const status = subscriptionData[planKey]?.status;
      if (subscriptionData[planKey] && (status === 'active' || status === 'in_trial')) {
        const activePlan = subscriptionPlans.find((plan) => plan.plans.includes(planKey));
        if (activePlan && !activeSubscriptionData.title) {
          activeSubscriptionData = {
            ...subscriptionData[planKey],
            title: activePlan.label,
            name: activePlan.value,
          };
        }
      }
    });

    return activeSubscriptionData;
  }, [user]);

  const isFreeTrialSubscriptionPlan = activeSubscriptionPlan.name?.toLowerCase() === 'trial';
  const isFreemiumSubscriptionPlan =
    activeSubscriptionPlan.name?.toLowerCase() === 'freemium' && false;
  const isPremiumSubscriptionPlan = activeSubscriptionPlan.name?.toLowerCase() === 'premium';

  const showUpgradePlan = isFreeTrialSubscriptionPlan || isFreemiumSubscriptionPlan;

  const leftSubscriptionDays = calculateDaysDifference(
    activeSubscriptionPlan?.next_billing_at || 0,
  );

  const subscriptionTrackingTime = getDateDifferencePercentage(
    activeSubscriptionPlan?.trial_start,
    activeSubscriptionPlan?.trial_end,
  );

  const availableCredit = user?.tenants?.length ? formatCurrency(user?.tenants[0]?.credits) : 0;

  return {
    activeSubscriptionPlan,
    isFreeTrialSubscriptionPlan,
    isFreemiumSubscriptionPlan,
    isPremiumSubscriptionPlan,
    showUpgradePlan,
    leftSubscriptionDays,
    availableCredit,
    subscriptionTrackingTime,
  };
};

export default useSubscription;
