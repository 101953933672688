import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Typography, Box } from '@mui/material';
import { Form } from 'src/components/shared';
import { capitalizeFirstLetter } from 'src/utils/helper';
import { Formik } from 'formik';
import { useStyles } from '../Integrations/styles';

export function useColumns(data, drawerToggle, props, updateTeamStatus) {
  const classes = useStyles();
  const [columns, setColumns] = useState([]);

  useEffect(() => {
    setColumns(
      [
        {
          label: 'Team name',
          name: 'name',
          options: {
            filter: true,
            sort: true,
            draggable: false,
            setCellHeaderProps: (value) => ({
              style: { minWidth: 180 },
            }),
            setCellProps: () => ({
              style: { minWidth: 180 },
            }),
            customBodyRenderLite: (dataIndex) => {
              const rData = data[dataIndex];
              let teamId;
              if (rData?._id) {
                teamId = rData?._id;
              } else {
                teamId = rData?.id;
              }
              return (
                <Link
                  to={`/admin/team/${teamId}`}
                  className="text-truncate td-anchor-content-ellipsis"
                  onClick={() => {
                    drawerToggle({ edit: rData, open: true });
                  }}
                >
                  <Typography variant="body2" color="secondary">
                    {`${capitalizeFirstLetter(rData?.name)}`}
                  </Typography>
                </Link>
              );
            },
          },
        },
        {
          label: 'Team leader',
          name: 'teamLead',
          options: {
            filter: true,
            sort: true,
            draggable: true,
            setCellHeaderProps: (value) => ({
              style: { minWidth: 50 },
            }),
            setCellProps: () => ({
              style: { minWidth: 50 },
            }),
            customBodyRenderLite: (dataIndex) => {
              const rData = data[dataIndex];
              return (
                <div>
                  {capitalizeFirstLetter(rData?.teamLead?.fname)}{' '}
                  {capitalizeFirstLetter(rData?.teamLead?.lname)}
                </div>
              );
            },
          },
        },
        {
          label: 'Users',
          name: 'user',
          options: {
            filter: true,
            sort: true,
            draggable: true,
            setCellHeaderProps: (value) => ({
              style: { minWidth: 90 },
            }),
            setCellProps: () => ({
              style: { minWidth: 90 },
            }),
            customBodyRenderLite: (dataIndex) => {
              const rData = data[dataIndex];
              return <div>{rData?.teamMembers?.length}</div>;
            },
          },
        },
        {
          label: 'Status',
          name: 'status',
          options: {
            filter: true,
            sort: false,
            draggable: true,
            setCellHeaderProps: (value) => ({
              style: { minWidth: 40 },
            }),
            setCellProps: () => ({
              style: { minWidth: 40 },
            }),
            customBodyRenderLite: (dataIndex) => {
              const rData = data[dataIndex];
              let teamId;
              if (rData?._id) {
                teamId = rData?._id;
              } else {
                teamId = rData?.id;
              }
              return (
                <Box
                  className={`${
                    rData?.status === 'active'
                      ? classes.gridContentRowDataActive
                      : classes.gridContentRowDataDeActive
                  }`}
                >
                  {rData?.status === 'pending' ? (
                    <Typography
                      color={
                        // rData?.status === 'active'
                        //   ? '#2E7D32'
                        rData?.status === 'pending' ? '#000000DE' : '#D32F2F'
                      }
                    >
                      Pending
                    </Typography>
                  ) : (
                    <Formik
                      enableReinitialize
                      initialValues={{ status: rData?.status === 'active' }}
                    >
                      {({ values, setFieldValue, handleSubmit }) => {
                        return (
                          <Form.Field.Switch
                            variant="outlined"
                            name="status"
                            onChange={(event, checked) => {
                              updateTeamStatus({
                                id: teamId,
                                status: !event ? 'active' : 'inactive',
                              });
                            }}
                            sx={{ mt: 0 }}
                          />
                        );
                      }}
                    </Formik>
                  )}
                </Box>
              );
            },
          },
        },
      ].filter(Boolean),
    );
  }, [data]);

  return columns;
}
