import React, { useEffect, useState } from 'react';
import { Box, Card, CardContent, Divider, Stack, Typography } from '@mui/material';
import { fetchFile } from 'src/modules/app/api/appApis';
import { Spacer, Button } from 'src/components/shared';
import File from './File';

import { FilesGrid } from './styles';

function Attachments({ attachments, messageId }) {
  const [files, setFiles] = useState([]);

  const downloadAll = async () => {
    try {
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        setTimeout(() => {
          window.open(file.url, '_blank');
        }, 100);
      }
    } catch (error) {}
  };

  return (
    <>
      <Divider />
      <Spacer x={1} y={1} />
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography color="textSecondary" variant="body2" sx={{ color: 'rgba(0,0,0,0.5)' }}>
          {attachments.length} attachments
        </Typography>
        {/* <Button onClick={downloadAll} color="secondary" size="small" startIcon={<DownloadIcon />}>
          Download all
        </Button> */}
      </Stack>

      <FilesGrid>
        {attachments.map((file, index) => (
          <File file={file} key={`file-${index}`} messageId={messageId} />
        ))}
      </FilesGrid>
    </>
  );
}

export default Attachments;
