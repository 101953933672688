import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { Typography, Grid, Box, Tooltip } from '@mui/material';
import { Button, Form, Spacer } from 'src/components/shared';
import { fetchUsersLookup } from 'src/modules/users/api/usersApi';
import InfoIcon from '@mui/icons-material/Info';

function CompanyTeamForm({
  teamLead,
  drawerToggle,
  editUser,
  formData,
  loading,
  total,
  userTotal,
  isTeamView,
  addUsers,
  existingUsers,
  ...props
}) {
  const [defaultCandidate, setDefaultCandidate] = useState({});

  useEffect(() => {
    setDefaultCandidate({
      ...defaultCandidate,
      teamMembers:
        (existingUsers &&
          existingUsers.map((item) => {
            return {
              name: `${item.fname} ${item.lname}`,
              id: item.id,
            };
          })) ||
        [],
    });
  }, [editUser]);

  const handleSubmit = async (values, form) => {
    const data = { ...values };
    if (isTeamView) {
      data.teamLead = values.teamLead.id;
      data.status = values.status ? 'active' : 'inactive';
      data.teamMembers = values.teamMembers.map((item) => item.id);
      await props.saveTeams(data);
      drawerToggle({ edit: null, open: false });
    } else {
      addUsers(data);
    }
  };

  const TitleBar = ({ title, tooltip }) => {
    return (
      <Box display="flex" alignItems="center">
        <Typography variant="h4">{title}</Typography>
        {tooltip && tooltip !== '' ? (
          <>
            <Spacer y={1} x={1} />

            <Tooltip title={tooltip}>
              <InfoIcon style={{ fill: 'rgba(0, 0, 0, 0.26)', width: 22 }} />
            </Tooltip>
          </>
        ) : null}
      </Box>
    );
  };

  return (
    <Form
      initialValues={{
        teamMembers: defaultCandidate?.teamMembers || [],
        teamLead: defaultCandidate?.teamLead?.id
          ? {
              id: defaultCandidate && defaultCandidate?.teamLead?.id,
              name: `${defaultCandidate?.teamLead?.fname} ${defaultCandidate?.teamLead?.lname}`,
            }
          : {},
        name: defaultCandidate.name || '',
        status: defaultCandidate.status === 'active' ? true : false,
      }}
      validationSchema={Yup.object().shape({
        name: isTeamView ? Yup.string().required('Please enter team name.') : Yup.string(),
      })}
      enableReinitialize={true}
      onSubmit={handleSubmit}
      validateOnBlur={true}
      validateOnChange={false}
    >
      {(props) => {
        console.log('🚀 ~ props:', props);
        return (
          <form
            onSubmit={(e) => {
              e.preventDefault();
              props.submitForm();
              return false;
            }}
            style={{ height: '100%' }}
            noValidate
          >
            <Box
              display="flex"
              flex={1}
              flexDirection="column"
              justifyContent="space-between"
              height="100%"
            >
              <Box container spacing={2}>
                {isTeamView && (
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Form.Field.Input
                        fullWidth
                        rows={4}
                        variant="outlined"
                        name="name"
                        label="Team name"
                      />
                    </Grid>
                  </Grid>
                )}

                {isTeamView && (
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Form.Field.AutoComplete
                        options={[]}
                        fullWidth
                        name="teamLead"
                        label="Team Lead"
                        placeholder="Team Lead"
                        variant="outlined"
                        remoteMethod={(val) => {
                          return fetchUsersLookup(val, false, false, {
                            permission_neq: 'user',
                            _size: 100,
                          });
                        }}
                        optLabel="name"
                        optValue="id"
                      />
                    </Grid>
                  </Grid>
                )}

                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Form.Field.AutoComplete
                      options={[]}
                      fullWidth
                      multiple
                      checkboxes={true}
                      name="teamMembers"
                      label="Add users"
                      placeholder="Add users"
                      variant="outlined"
                      remoteMethod={async (val) => {
                        const users = await fetchUsersLookup(val, false, false, {
                          // _sort: 'fname:asc',
                          _size: 100,
                        });

                        return users
                          .map((user) => ({
                            ...user,
                            disabled: user.team.length > 0,
                          }))
                          // .sort((a, b) => a.disabled - b.disabled);
                      }}
                      optLabel="name"
                      optValue="id"
                      getOptionDisabled={(option) => option.disabled}
                    />
                  </Grid>
                  {isTeamView && (
                    <Grid item xs={12} ml={2}>
                      <Form.Field.Switch
                        rows={4}
                        variant="outlined"
                        name="status"
                        label={
                          <Box display="flex" alignItems="center">
                            {`Status - ${props.values.status ? 'Active' : 'Inactive'}`}
                            <TitleBar tooltip="Active/Inactive" />
                          </Box>
                        }
                        sx={{ marginTop: -1 }}
                      />
                    </Grid>
                  )}
                </Grid>
              </Box>

              <Box display="flex" justifyContent="flex-end">
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={() => drawerToggle({ edit: null, open: false })}
                  loading={loading?.teams}
                  disabled={loading?.teams}
                >
                  Cancel
                </Button>
                <Spacer x={2} y={2} />
                <Button
                  variant="contained"
                  color="secondary"
                  type="submit"
                  loading={loading?.teams}
                  disabled={loading?.teams}
                  style={{
                    width: '80px',
                  }}
                >
                  {!defaultCandidate.id ? 'Save' : 'Update'}
                </Button>
              </Box>
            </Box>
          </form>
        );
      }}
    </Form>
  );
}
export default CompanyTeamForm;
